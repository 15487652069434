import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconSizeEnum } from '@app/shared/models/icon-size-enum';
import { TooltipPlacementEnum } from '@app/shared/models/tooltip-placement-enum';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent {
  @Input()
  icon: string;

  @Input()
  placement = TooltipPlacementEnum.Top;

  @Input()
  buttonLabelSize: number;

  @Input()
  label: string;

  @Input()
  tooltip?: string;

  @Output()
  buttonClicked = new EventEmitter<Event>();

  @Output()
  mouseOver = new EventEmitter();

  @Output()
  mouseLeave = new EventEmitter();

  @Input()
  iconSize = IconSizeEnum.Medium;

  @Input()
  showLoader = false;

  getFontSize(): number {
    switch (this.iconSize) {
      case IconSizeEnum.Small:
        return 8;
      case IconSizeEnum.Medium:
        return 16;
      case IconSizeEnum.Large:
        return 32;
    }
    return 16;
  }

  onClick(event: Event) {
    if (!this.showLoader) {
      this.buttonClicked.emit(event);
    }
  }
}
