import { FilterField } from './filter-field';

export class TypeaheadFilterField extends FilterField<any, any> {
  data: any[];
  inputProperty: string;
  inputDescription: string;
  minSearchCharacters: number;
  showInputOnResult = true;
  showDescriptionOnResult = true;
  showInputOnValue = true;
  showDescriptionOnValue = false;
  showDescription = true;

  getDefaultValue(): any {
    return null;
  }

  parseValue(value: any) {
    // FIXME: This is a Typescript Type bailout, and indicates that we're accessing the
    // properties of this object in a dynamic way, going against type safety.
    const obj: { [key: string]: any } = {};
    obj[this.inputProperty] = value;
    return obj;
  }

  getValue(control: any): any {
    return control && control[this.inputProperty]
      ? control[this.inputProperty]
      : null;
  }

  setData(data: any[]) {
    this.data = data;
    return this;
  }

  setInputProperty(inputProperty: string) {
    this.inputProperty = inputProperty;
    return this;
  }

  setInputDescription(inputDescription: string) {
    this.inputDescription = inputDescription;
    return this;
  }

  isValid(value: any): boolean {
    return true;
  }

  setMinSearchCharacters(minSearchCharacters: number) {
    this.minSearchCharacters = minSearchCharacters;
    return this;
  }

  setShowInputOnResult(value: boolean) {
    this.showInputOnResult = value;
    return this;
  }

  setShowDescriptionOnResult(value: boolean) {
    this.showDescriptionOnResult = value;
    return this;
  }

  setShowInputOnValue(value: boolean) {
    this.showInputOnValue = value;
    return this;
  }

  setShowDescriptionOnValue(value: boolean) {
    this.showDescriptionOnValue = value;
    return this;
  }

  setShowDescription(value: boolean) {
    this.showDescription = value;
    return this;
  }
}
