import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ControlContainer,
  UntypedFormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { FormErrors } from '@app/core/models/form';

@Component({
  selector: 'app-textarea-input',
  templateUrl: './textarea-input.component.html',
  styleUrls: ['./textarea-input.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class TextareaInputComponent implements OnInit, AfterViewInit {
  @ViewChild('textareaInput', { read: ElementRef })
  textAreaElement: ElementRef;

  @Input()
  parentForm: UntypedFormGroup;

  @Input()
  inputName: string;

  @Input()
  labelName: string;

  @Input()
  appendButton: boolean;

  @Input()
  hideLabel: boolean;

  @Input()
  hideError = false;

  @Input()
  iconButton: string;

  @Input()
  maxLength: number;

  @Input()
  height: number;

  @Output()
  iconButtonClick = new EventEmitter<any>();

  @Input()
  errors: FormErrors<unknown>;

  @Input()
  flagAsRequired: boolean;

  @Input()
  showSuccess: boolean;

  @Input()
  showLabelSuccess = true;

  @Input()
  readonly = false;

  @Input()
  rows = 5;

  @Input()
  disableHeightResize = false;

  @Input()
  showCharacterCount = false;

  @Input()
  infoMessage?: string;

  get errorMessage(): string {
    return this.errors
      ? this.parentForm?.errors?.[this.inputName] ?? null
      : null;
  }

  constructor() {}

  ngAfterViewInit(): void {
    if (this.height) {
      this.textAreaElement.nativeElement.style.height = `${this.height}px`;
    }
  }

  ngOnInit() {}

  get value() {
    return this.parentForm.get(this.inputName);
  }

  onIconButtonClick() {
    this.iconButtonClick.emit();
  }
}
