import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const defaultConfig = {
  toolbar: ['bold', 'italic', 'bulletedList', 'numberedList'],
};

@Component({
  selector: 'app-rich-text-input',
  templateUrl: './rich-text-input.component.html',
  styleUrls: ['./rich-text-input.component.scss'],
})
export class RichTextInputComponent {
  public editor = ClassicEditor;

  get config() {
    return {
      ...defaultConfig,
      placeholder: this.placeholder,
    };
  }

  get control(): AbstractControl {
    return this.parentForm.get(this.inputName);
  }

  @Input()
  parentForm: UntypedFormGroup;

  @Input()
  hideLabel = false;

  @Input()
  inputName: string;

  @Input()
  labelName: string;

  @Input()
  placeholder: string;
}
