import {
  Component,
  Input,
  EventEmitter,
  Output,
  TemplateRef,
  OnChanges,
} from '@angular/core';
import { IconSizeEnum } from '@app/shared/models/icon-size-enum';

@Component({
  selector: 'app-text-display',
  templateUrl: './text-display.component.html',
  styleUrls: ['./text-display.component.scss'],
})
export class TextDisplayComponent implements OnChanges {
  @Input()
  labelName: string;

  @Input()
  value: string | string[];

  @Input()
  hideLabel: boolean;

  @Input()
  showSuccess: boolean;

  @Input()
  showLabelSuccess = true;

  @Input()
  showTextSuccess = true;

  @Input()
  showWarning = false;

  @Input()
  showError = false;

  @Input()
  description: string;

  @Input()
  showIcon = false;

  @Input()
  showTooltip = false;

  @Input()
  tooltipText: string | TemplateRef<any>;

  @Input()
  descriptionTooltip: string | TemplateRef<any>;

  @Input()
  applyFormStyling = true;

  @Input()
  smallText = false;

  @Input()
  iconSize = IconSizeEnum.Medium;

  @Input()
  actionDescription: string;

  @Input()
  hasDisplayAction = false;

  @Input()
  canCopy: boolean;

  @Input()
  showButton: boolean;

  @Input()
  buttonIcon: string;

  @Input()
  buttonTooltip: string;

  @Output()
  actionClicked = new EventEmitter();

  @Output()
  iconClicked = new EventEmitter();

  @Output()
  buttonClicked = new EventEmitter();

  @Input()
  truncateText = true;

  isArrayInput: boolean;

  ngOnChanges() {
    if (Array.isArray(this.value)) {
      this.isArrayInput = true;
    } else {
      this.isArrayInput = false;
    }
  }

  iconClick() {
    this.iconClicked.emit();
  }

  getCopyItem(): string {
    const item = this.value?.toString();

    return item;
  }

  _copyLabel: string;
  @Input()
  toolTipPlacement = 'left';

  @Input()
  set copyLabel(value: string) {
    this._copyLabel = value;
  }

  get copyLabel() {
    if (this._copyLabel) {
      return this._copyLabel;
    }
    return this.labelName;
  }

  clickButton() {
    this.buttonClicked.emit();
  }
}
