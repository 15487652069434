<div class="form-group">
  <label
    [class.success]="showSuccess && showLabelSuccess"
    *ngIf="!hideLabel"
    [for]="inputName"
    class="input-label"
    >{{ labelName }} <span *ngIf="flagAsRequired">*</span>
  </label>
  <div class="input-group mb-3">
    <textarea
      #textareaInput
      [formControlName]="inputName"
      class="form-control"
      [ngStyle]="{ resize: disableHeightResize ? 'none' : '' }"
      [rows]="rows"
      [maxlength]="maxLength"
      [disabled]="readonly"
    ></textarea>
    <div
      *ngIf="appendButton"
      class="input-group-append"
      (click)="onIconButtonClick()"
    >
      <span class="btn-icon">
        <app-icon class="icon" [name]="iconButton"></app-icon>
      </span>
    </div>
  </div>
  <p *ngIf="showCharacterCount" class='text-count float-end'>{{textareaInput.value.length}}{{maxLength > 0 ? ' / '+  maxLength : ''}}</p>
  <app-info-label *ngIf="infoMessage" [message]="infoMessage"></app-info-label>
  <app-error-label
    *ngIf="(errorMessage || value.invalid) && value.dirty && !hideError"
    [message]="errorMessage ? errorMessage : 'Invalid ' + labelName"
  ></app-error-label>
</div>
