<app-text-display
  [labelName]="label"
  [value]="value"
  [description]="description"
  [showSuccess]="showSuccess"
  [canCopy]="canCopy"
  [actionDescription]="actionDescription"
  [hasDisplayAction]="hasDisplayAction"
  [showTooltip]="hasToolTip"
  [tooltipText]="tooltip"
  [descriptionTooltip]="descriptionTooltip"
  [showWarning]="showWarning"
  [showError]="showError"
  (actionClicked)="onActionClicked()"
></app-text-display>
