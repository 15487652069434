import {
  getDefaultGlPeriod,
  GlPeriod,
  parseValueToGlPeriod,
} from '@app/shared/form-inputs/gl-period-input/gl-period';
import { FilterField } from './filter-field';

export class GlPeriodFilterField extends FilterField<GlPeriod, number> {
  getDefaultValue() {
    return getDefaultGlPeriod();
  }

  parseValue(value: string): GlPeriod {
    if (value) {
      return parseValueToGlPeriod(value);
    } else {
      return this.getDefaultValue();
    }
  }

  getValue(glPeriod: GlPeriod): number {
    if (glPeriod) {
      return glPeriod.year * 100 + glPeriod.month;
    }
    return null;
  }

  isValid(_: string): boolean {
    return true;
  }
}
