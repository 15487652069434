<label class="input-label"
  >{{ label }}
  <app-icon
    class="danger"
    [name]="icon"
    *ngIf="showToolTip"
    [ngbTooltip]="tooltip"></app-icon
></label>
<form [formGroup]="searchForm">
  <app-typeahead-label-input
    [parentForm]="searchForm"
    [inputName]="'search'"
    [inputProperty]="inputProperty"
    [inputDescription]="inputDescription"
    [placeholder]="'Search'"
    [data$]="data$"
    [label]="value"
    [canCopy]="true"
    [copyLabel]="label"
    (filterChanged)="onSearchFilterChanged($event)"
    (filterSelected)="
      onSearchFilterSelected($event)
    "></app-typeahead-label-input>
  <div class="mt-3">
    <app-info-label
      *ngIf="description"
      [message]="description"></app-info-label>
  </div>
</form>
