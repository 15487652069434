import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'address',
})
export class AddressPipe implements PipeTransform {
  transform(value: string): string[] {
    if (!value) {
      return [];
    }

    if (value.match(',')) {
      return value.split(',');
    }

    if (value.match('\r\n')) {
      return value.split('\r\n');
    }

    if (value.match('\n')) {
      return value.split('\n');
    }

    return [value];
  }
}
