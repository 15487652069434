import { TemplateRef } from '@angular/core';
import { PagedApiData } from '@app/core/models/PagedApiData';
import { Field } from '@app/shared/generic-data/generic-data';
import { Button, IconButton } from '@app/shared/interfaces/button';
import { DateRangeFields } from '@app/shared/models/date-range-field';
import { SelectionType, SortPropDir } from '@swimlane/ngx-datatable';
import { Observable, Subject, Subscription } from 'rxjs';
import { TableColumnExtension } from '@app/shared/dashboard-table/models/table-column-extension';
import { defaultLimit } from '@app/shared/helpers/pagination-helpers';
import { SelectOption } from '@app/core/models/selector-option';
import { ExcelTableColumn } from '@app/shared/models/excel-table-column';
import { FooterItem } from '../models/footer-item';
import { TableAction } from '../models/table-action';
import { NullRowStyler } from './null.row-styler';

export abstract class TableDefinition<T> {
  title = '';
  limit = defaultLimit;
  showColumnTotal: boolean;
  showTableTotal = true;
  columnTotalTitle: string;
  columnTotalValue: number;
  checkboxSelectedRows: T[];
  rows$: Observable<PagedApiData<T>>;
  columns: TableColumnExtension[] = [];
  actions: TableAction<T>[] = [];
  showSearch = true;
  showFilter = true;
  isLoading = false;
  subscriptions: Subscription[] = [];
  search = '';
  rowStyler = new NullRowStyler();
  showFooter: boolean;
  footerItems: Observable<FooterItem[]>;
  showDateRangeFilter: boolean;
  retrieveDateRangeValuesAcrossPills: boolean;
  showDateRangeResetButton: boolean;
  dateRangeFilterLabel: string;
  dateRangeValues: DateRangeFields;
  actionText = '';
  alternateActionText = '';
  totalCountTitle = '';
  hasMetadata = false;
  metadata$: Observable<Field[]>;
  displayMetadataLabels = true;
  allowExpandingRows = false;
  rowDetailHeight = 155;
  expandedRowTemplate?: TemplateRef<any>;
  expandRowDetail$: Subject<T> = new Subject<T>();
  expandedRow: T;
  tableActions: (Button | IconButton)[] = [];
  headerTemplate?: TemplateRef<any>;
  leftHeaderTemplate?: TemplateRef<any>;

  actionDropDownTitle: string;
  dropDownOptions: Button[] = [];
  selectionType: SelectionType = SelectionType.single;
  showSelectFilter: boolean;
  selectFilterLabel: string;
  selectOptions: SelectOption[] = [];
  selectFilterValue: string;
  altAddDefaultSelectFilterOption = false;
  excelTableColumns?: ExcelTableColumn[];

  initialize(): void {
    this.registerSubscriptions();
    this.loadInitialData();
  }

  destroy(): void {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
    this.dateRangeValues = null;
    this.selectFilterValue = null;
  }

  simpleSearch(searchString: string) {
    throw new Error('Not implemented');
  }

  advancedSearch(): void {
    throw new Error('Not implemented');
  }

  abstract sort(sort: SortPropDir): void;

  abstract select(item: T): void;

  abstract loadMore({ offset, limit }: { offset: number; limit: number }): void;

  abstract registerSubscriptions(): void;

  abstract loadInitialData(): void;

  loadData(loading: boolean) {
    this.isLoading = loading;
  }

  setColumns(columns: TableColumnExtension[]) {
    this.columns = [...columns];
  }

  expandRow(row: T) {
    this.expandedRow = row;
  }

  get isVisible() {
    return true;
  }

  get isEnabled() {
    return true;
  }

  selectFilterValueChanged(value: string | number) {
    throw new Error('Not implemented');
  }

  onSelectDateRangeFilterValueChanged(value: DateRangeFields): void {}

  getExcelTableColumns(): ExcelTableColumn[] {
    const columns = this.columns.filter(
      (column) =>
        !column.prop.toString().toLowerCase().includes('action') &&
        !column.prop.toString().toLowerCase().includes('expand') &&
        !column.prop.toString().toLowerCase().includes('checkbox'),
    );

    return columns.map((column) => ({
      name: column.name,
      prop: column.prop.toString(),
      pipe: column.pipe,
    }));
  }

  setCheckboxSelectedRows(rows: T[]) {
    this.checkboxSelectedRows = rows;
  }
}
