import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  @Input()
  title: string;

  @Input()
  hideHeader = false;

  @Input()
  footer: TemplateRef<any>;

  @Input()
  showClose = true;

  @Input()
  isNestedModal = false;

  @Output()
  innerModalClose = new EventEmitter<any>();

  @Output()
  modalClose = new EventEmitter<any>();

  constructor(public activeModal: NgbActiveModal) {}

  closeInnerModal() {
    this.innerModalClose.emit();
  }

  closeModal() {
    this.modalClose.emit();
    this.activeModal.dismiss();
  }
}
