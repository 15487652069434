import {
  FilterParameter,
  FilterParameterOperator,
} from '../interfaces/filter-parameter';
import { SearchOperand, SearchRequest } from '../interfaces/search-request';
import { FilterParameterBuilder } from './filter-parameter.builder';

export class SearchRequestBuilder<T> {
  private readonly searchRequest: SearchRequest;

  constructor() {
    this.searchRequest = {
      filterParameters: [],
      operand: SearchOperand.AND,
    };
  }

  addFilterParameter(
    filterParameter: FilterParameter,
  ): SearchRequestBuilder<T> {
    if (filterParameter) {
      this.searchRequest.filterParameters.push(filterParameter);
    }

    return this;
  }

  addFilterParameters(
    filterParameters: FilterParameter[],
  ): SearchRequestBuilder<T> {
    this.searchRequest.filterParameters.push(...filterParameters);
    return this;
  }

  combine(searchRequest: SearchRequest): SearchRequestBuilder<T> {
    if (searchRequest && searchRequest.filterParameters) {
      return this.addFilterParameters(searchRequest.filterParameters);
    }
    return this;
  }

  setOperand(operand: SearchOperand): SearchRequestBuilder<T> {
    this.searchRequest.operand = operand;
    return this;
  }
  addNewNonGenericFilterParameter(
    columnName: string,
    value: any,
    filterParameterOperator: FilterParameterOperator,
  ): SearchRequestBuilder<T> {
    const filterParameter = new FilterParameterBuilder<T>()
      .setNonGenericColumnName(columnName)
      .setValue(value)
      .setFilterOperator(filterParameterOperator)
      .build();
    this.searchRequest.filterParameters.push(filterParameter);
    return this;
  }
  addNewFilterParameter(
    columnName: keyof T,
    value: any,
    filterParameterOperator: FilterParameterOperator,
  ): SearchRequestBuilder<T> {
    const filterParameter = new FilterParameterBuilder<T>()
      .setColumnName(columnName)
      .setValue(value)
      .setFilterOperator(filterParameterOperator)
      .build();
    this.searchRequest.filterParameters.push(filterParameter);
    return this;
  }

  build(): SearchRequest {
    return this.searchRequest;
  }
}
