<div *ngIf="data?.length" class="form-group">
  <label
    *ngIf="showLabel"
    class="input-label"
  >{{ labelName }}</label>
  <div class="row">
    <div class="col-12">
      <div *ngFor="let item of data; let i = index" class="chip d-inline-block">
        <span>{{ item }}</span>
        <div class="d-inline-block">
          <div class="d-flex align-items-center h-100">
            <app-icon-button
              (buttonClicked)="clickButton(i)"
              [icon]="buttonIcon"
              class="ms-1"
            >
            </app-icon-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
