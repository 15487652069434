<label class="input-label">{{ label }}</label>
<form [formGroup]="inputForm">
  <app-adv-select-input
    [parentForm]="inputForm"
    [inputName]="'option'"
    [items$]="selectOptions"
    [noValueText]="noValueText"
    (itemSelected$)="onSelectedValueChanged($event)"
  >
  </app-adv-select-input>
  <div class="mt-2">
    <app-info-label
      *ngIf="description"
      [message]="description"
    ></app-info-label>
  </div>
</form>
