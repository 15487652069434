import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-open-state-indicator',
  templateUrl: './open-state-indicator.component.html',
  styleUrls: ['./open-state-indicator.component.scss'],
})
export class OpenStateIndicatorComponent {
  @Input()
  isOpen = false;
}
