<div [class.form-group]="appendLabel">
  <label
    *ngIf="appendLabel"
    class="input-label"
  >{{ labelName }} <span *ngIf="flagAsRequired">*</span>
  </label>
  <div [class.input-group]="true" ngbDropdown class="d-inline-block search-dropdown" container="body">
    <button
      class="btn btn-outline-primary"
      id="dropdownSearch"
      ngbDropdownToggle
      [disabled]="disable"
      (click)="focusOnInput()"
    >
      {{ getDisplayValue() }}
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownSearch">
      <form>
        <div class="form-group search-box">
          <input
            #input
            (keyup)="onFilterChanged($event)"
            type="text"
            class="form-control search-box-field"
            placeholder="Start typing"
          />
        </div>
        <div class="dropdown-divider"></div>
        <div class="form-group result-box">
          <a
            class="result-box-item"
            *ngFor="let item of filteredItems$ | async"
            ngbDropdownItem
            (click)="onItemSelected(item.value, item.description)"
          >
            {{ item.description }}
          </a>
        </div>
      </form>
    </div>
  </div>

</div>
