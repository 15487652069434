import { formatAsMidnightUtc } from '@app/shared/form-inputs/date-input-format';
import { FilterField } from './filter-field';

export class DateFilterField extends FilterField<Date | string, string> {
  getDefaultValue() {
    return new Date().toISOString();
  }

  parseValue(value: any) {
    return new Date(value).toISOString();
  }

  getValue(value: string | Date): string {
    return formatAsMidnightUtc(new Date(value));
  }

  isValid(_: string): boolean {
    return true;
  }
}
