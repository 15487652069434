<div class="component" *ngIf="selectedDefinition">
  <div class="component-header">
    <nav class="navbar">
      <span>
        <button
          *ngIf="showActionButton"
          class="btn btn-primary"
          (click)="onActionButtonClicked()">
          {{ selectedDefinition.actionText }}
        </button>

        <button
          *ngIf="showAlternateActionButton"
          class="btn btn-primary"
          (click)="onAlternateActionButtonClicked()">
          {{ selectedDefinition.alternateActionText }}
        </button>
      </span>
      <span *ngIf="showPills" class="btn-group btn-group-toggle">
        <button
          [hidden]="!pill.isVisible"
          class="btn btn-md"
          (click)="onPillClick(pill)"
          [class.btn-secondary]="pill !== selectedDefinition"
          [class.btn-primary]="pill === selectedDefinition"
          *ngFor="let pill of tableDefinitions">
          {{ pill.title ? pill.title : '' }}
        </button>
      </span>
      <span *ngIf="selectedDefinition.showDateRangeFilter && !showPills">
        <app-date-range-picker
          [labelName]="selectedDefinition.dateRangeFilterLabel"
          (dateChanged)="onDateFilterChanged($event)"></app-date-range-picker>
      </span>
      <span
        class="title"
        *ngIf="
          !showPills &&
          !showActionButton &&
          !showAlternateActionButton &&
          selectedDefinition.title
        ">
        <h2>{{ selectedDefinition.title }}</h2>
      </span>
      <div>
        <ng-container
          *ngTemplateOutlet="
            selectedDefinition.leftHeaderTemplate
          "></ng-container>
      </div>

      <div class="form-inline ms-auto">
        <ng-container
          *ngTemplateOutlet="selectedDefinition.headerTemplate"></ng-container>

        <div
          class="buttons"
          *ngIf="selectedDefinition.dropDownOptions.length > 0">
          <div ngbDropdown>
            <button class="btn btn-secondary" ngbDropdownToggle>
              {{ selectedDefinition.actionDropDownTitle }}
            </button>
            <div class="ontop" ngbDropdownMenu>
              <ng-container
                *ngFor="let option of selectedDefinition.dropDownOptions">
                <div>
                  <button ngbDropdownItem (click)="option.fn()">
                    {{ option.name }}
                  </button>
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <span
          *ngIf="selectedDefinition.showColumnTotal; else totalCount"
          class="row">
          <span class="total-count me-5">
            <small
              ><b>{{ selectedDefinition.columnTotalTitle }}</b></small
            ><br /><b>{{
              selectedDefinition.columnTotalValue | displayAsCurrency
            }}</b>
          </span>
        </span>

        <ng-template #totalCount>
          <span
            *ngIf="selectedDefinition.showTableTotal"
            class="total-count me-2"
            >Total
            {{
              (selectedDefinition.totalCountTitle
                ? selectedDefinition.totalCountTitle
                : selectedDefinition.title
              ) | lowercase
            }}
            : {{ (selectedDefinition.rows$ | async)?.total }}</span
          >
        </ng-template>

        <div class="form-group search-form">
          <input
            class="form-control me-sm-2"
            type="text"
            [placeholder]="getSearchText()"
            aria-label="Search"
            *ngIf="showSearch && searchEnabled && selectedDefinition.showSearch"
            [(ngModel)]="selectedDefinition.search"
            (ngModelChange)="doSearch(selectedDefinition.search)" />
          <app-icon
            class="input-addon"
            name="search"
            *ngIf="
              showSearch &&
              searchEnabled &&
              !selectedDefinition.search &&
              selectedDefinition.showSearch
            "></app-icon>
          <app-icon
            class="input-addon"
            name="cross-circle"
            *ngIf="
              showSearch &&
              searchEnabled &&
              selectedDefinition.search &&
              selectedDefinition.showSearch
            "
            (click)="doResetSearch()"></app-icon>
        </div>
      </div>

      <app-icon-button
        class="ms-1"
        [icon]="'advanced-search'"
        [label]="'Filter'"
        *ngIf="showSearch && filterEnabled && selectedDefinition.showFilter"
        (click)="showAdvancedSearch()"></app-icon-button>
      <app-icon-button
        class="ms-1"
        [label]="'Upload'"
        [icon]="'upload'"
        *ngIf="showUpload"
        (click)="onUploadClicked()"></app-icon-button>
      <app-icon-button
        *ngIf="excelConversionEnabled"
        class="ms-1"
        [label]="'Excel'"
        [icon]="'save'"
        (click)="convertTableToExcel()"></app-icon-button>
      <div
        *ngIf="visibleActionButtons && visibleActionButtons.length > 0"
        class="actions">
        <app-icon-button
          *ngFor="let button of visibleActionButtons"
          class="d-block ms-2"
          [label]="button.name"
          [icon]="button.icon"
          [tooltip]="button.tooltip"
          (buttonClicked)="button.fn()"
          [showLoader]="button.showLoader">
        </app-icon-button>
      </div>
    </nav>
    <div class="row row-filter">
      <div
        class="col-6"
        *ngIf="selectedDefinition.showDateRangeFilter && showPills">
        <div>
          <app-date-range-picker
            (dateChanged)="onDateFilterChanged($event)"
            (resetClicked)="resetDateRange()"
            [labelName]="selectedDefinition.dateRangeFilterLabel"
            [showResetButton]="selectedDefinition.showDateRangeResetButton"
            [parentForm]="parentForm"
            [inputName]="dateRangeInputName"></app-date-range-picker>
        </div>
      </div>
      <div
        class="col-6 select-filter"
        *ngIf="selectedDefinition.showSelectFilter && showPills">
        <form [formGroup]="parentForm">
          <app-select-input
            [parentForm]="this.parentForm"
            [inputName]="'selectFilter'"
            [labelName]="selectedDefinition.selectFilterLabel"
            [addAllAsDefaultOption]="altAddDefaultSelectFilterValue()"
            [appendLabel]="true"
            [selectOptions]="
              selectedDefinition.selectOptions
            "></app-select-input>
        </form>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      selectedDefinition.hasMetadata &&
      (selectedDefinition.metadata$ | async).length > 0
    "
    class="mb-2">
    <app-generic-field-display
      *ngFor="let field of selectedDefinition.metadata$ | async"
      [displayLabel]="selectedDefinition.displayMetadataLabels"
      [field]="field">
    </app-generic-field-display>
  </div>
  <div class="component-body">
    <app-table
      [stretchColumns]="stretchColumns"
      [headerHeight]="headerHeight"
      [rowHeight]="rowHeight"
      [filteredItems]="(selectedDefinition.rows$ | async)?.searchData"
      [shrinkHeight]="shrinkHeight"
      [isLoading]="selectedDefinition.isLoading"
      [rows]="(selectedDefinition.rows$ | async)?.data"
      [columns]="selectedDefinition.columns"
      [rowStyler]="selectedDefinition.rowStyler"
      [totalPages]="(selectedDefinition.rows$ | async)?.totalPages"
      [totalRows]="(selectedDefinition.rows$ | async)?.total"
      (sort)="onSort($event)"
      (selected)="activated($event)"
      (loadMore)="doLoadMore($event)"
      [nonClickableColumns]="nonClickableColumns"
      [nonStyledColumns]="nonStyledColumns"
      [horizontalScrollbar]="horizontalScrollbar"
      [verticalScrollbar]="verticalScrollbar"
      [clearTableSelection]="clearTableSelection"
      [showFooter]="selectedDefinition.showFooter"
      [footerItems]="selectedDefinition.footerItems | async"
      [allowExpandingRows]="selectedDefinition.allowExpandingRows"
      [expandedRowTemplate]="selectedDefinition.expandedRowTemplate"
      [rowDetailHeight]="selectedDefinition.rowDetailHeight"
      [expandRowDetails$]="selectedDefinition.expandRowDetail$"
      [expandedRow]="selectedDefinition.expandedRow"
      (requestRowExpand)="selectedDefinition.expandRow($event)"
      [limit]="selectedDefinition.limit"
      [selectionType]="selectedDefinition.selectionType"
      (checkBoxSelected)="checkBoxSelected($event)">
    </app-table>
  </div>
</div>
