// Credit:
// https://medium.com/swlh/create-a-resizable-and-draggable-angular-component-in-the-easiest-way-bb67031866cb
import { DOCUMENT } from '@angular/common';
import {
  Directive,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appResize]',
})
export class ResizeDirective implements OnInit, OnDestroy {
  parentHtmlElement: HTMLElement;
  controlHtmlElement: HTMLElement;
  resizeHtmlElement: HTMLElement;
  window: Window;

  @Input()
  parentElement: ElementRef;

  @Input()
  resizeElement: HTMLElement;

  @Input()
  minHeight = 310;

  @Input()
  minWidth = 500;

  subscriptions: Subscription[] = [];

  constructor(
    private controlElement: ElementRef,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.window = this.document.defaultView;
    this.controlHtmlElement = this.controlElement.nativeElement as HTMLElement;
  }

  ngOnInit(): void {
    this.parentHtmlElement = this.parentElement.nativeElement as HTMLElement;
    this.resizeHtmlElement = this.resizeElement as HTMLElement;
    this.handleResizing();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s?.unsubscribe());
  }

  setSize(x: number, y: number, right: number, bottom: number) {
    const newWidth = right - x;
    const newHeight = bottom - y;
    if (newWidth > this.minWidth) {
      this.resizeHtmlElement.style.width = `${newWidth}px`;
    }

    if (newHeight > this.minHeight) {
      this.resizeHtmlElement.style.height = `${newHeight}px`;
    }
  }

  handleResizing() {
    const { left, top, right, bottom } =
      this.resizeHtmlElement.getBoundingClientRect();
    const dragStart$ = fromEvent<MouseEvent>(
      this.controlHtmlElement,
      'mousedown',
    );
    const dragEnd$ = fromEvent<MouseEvent>(this.document, 'mouseup');
    const mouseMove$ = fromEvent<MouseEvent>(this.document, 'mousemove').pipe(
      takeUntil(dragEnd$),
    );

    let dragSub: Subscription;

    const dragStartSub = dragStart$.subscribe((event: MouseEvent) => {
      this.parentHtmlElement.classList.add('moving');
      this.parentHtmlElement.style.display = 'block';

      dragSub = mouseMove$.subscribe((dragEvent: MouseEvent) => {
        event.preventDefault();

        this.setSize(dragEvent.clientX, dragEvent.clientY, right, bottom);
      });
    });

    const dragEndSub = dragEnd$.subscribe(() => {
      this.parentHtmlElement.classList.remove('moving');
      if (dragSub) {
        dragSub.unsubscribe();
      }
    });

    this.subscriptions.push.apply(this.subscriptions, [
      dragStartSub,
      dragSub,
      dragEndSub,
    ]);
  }
}
