import { Component, Input } from '@angular/core';
import { DropDown } from '@app/shared/interfaces/dropdown';
import { ButtonWithInformationLabels } from '@app/shared/models/button-with-information-labels';
import { Button, IconButton } from '../../interfaces/button';

@Component({
  selector: 'app-overview-section',
  templateUrl: './overview-section.component.html',
  styleUrls: ['./overview-section.component.scss'],
})
export class OverviewSectionComponent {
  @Input()
  title: string;

  @Input()
  isLoading: boolean;

  @Input()
  actionButtons: (Button | IconButton)[] = [];

  @Input()
  buttons: (Button | DropDown)[] = [];

  @Input()
  dropdownButtons: Button[] = [];

  @Input()
  informationLabels: string[];

  @Input()
  buttonsWithLabels: ButtonWithInformationLabels[];

  @Input()
  showHeaderOverdue: boolean;

  @Input()
  showReopenRequestHeader: boolean;

  @Input()
  showWarningHeader: boolean;

  @Input()
  showErrorHeader: boolean;
}
