<div class="form-group">
  <label [class.success]="showSuccess" [for]="inputName" class="input-label"
    >{{ labelName }} <span *ngIf="flagAsRequired">*</span></label
  >
  <div class="input-group mb-3">
    <input
      #typeahead
      type="text"
      class="form-control"
      placement="bottom"
      [formGroup]="parentForm"
      [formControlName]="inputName"
      [placeholder]="placeholder"
      container="body"
      [formControlName]="inputName"
      [ngbTypeahead]="handleTypeahead"
      [editable]="editable"
      [inputFormatter]="formatInput"
      [resultFormatter]="formatResult"
      (selectItem)="onItemSelected($event)"
      (focus)="onFocus()"
    />
  </div>
  <ng-container
    *ngIf="(errorMessage || inputValue.invalid) && inputValue.dirty; else label"
  >
    <app-error-label
      [message]="errorMessage ? errorMessage : 'Invalid ' + labelName"
    ></app-error-label>
  </ng-container>
  <ng-template #label>
    <app-info-label
      *ngIf="description && showDescription"
      [message]="description"
    ></app-info-label>
  </ng-template>
</div>
