import { Pipe, PipeTransform } from '@angular/core';
import { displayReadableRegions, Regions } from '@app/core/models/regions.enum';

@Pipe({
  name: 'convertToRegionName',
})
export class RegionPipe implements PipeTransform {
  transform(value: number): string {
    return displayReadableRegions(value as Regions);
  }
}
