<label *ngIf="appendLabel" class="input-label"
>{{ _labelName }}
</label>
<div class="checkbox-container col-12" *ngFor="let item of values">
  <label
    *ngIf="labelPosition === ' before'  && appendLabel"
    class="input-label label-before"
  >{{ item.label }}</label
  >
  <input
    [checked]=""
    type="radio"
    [name]="inputName"
    [id]="inputName + item.value"
    [formControlName]="inputName"
    class="custom-control-input"
    [value]="item.value"
  />
  <label class="custom-control-label" [for]="inputName + item.value"></label>
  <label
    *ngIf="labelPosition === 'after'  && appendLabel"
    class="input-label label-after"
  >{{ item.label }}</label>
  <app-currency-input
    *ngIf="item.value===radioValue.value && item.inputType==='currency'"
    [parentForm]="parentForm"
    [inputName]="item.textInputName"
    [description]="item.tooltip">
  </app-currency-input>
  <app-text-input
    *ngIf="item.value===radioValue.value && item.inputType==='text'"
    [parentForm]="parentForm"
    [inputName]="item.textInputName"
    [description]="item.tooltip">
  </app-text-input>
</div>
