import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@env/environment';

@Pipe({
  name: 'standardDateTime',
})
export class StandardDateTimePipe extends DatePipe implements PipeTransform {
  transform(dateString: string): any {
    return dateString
      ? super.transform(dateString, environment.dateFormat + ' HH:mm:ss')
      : '';
  }
}
