import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NgControl,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-multi-item-list-input',
  templateUrl: './multi-item-list-input.component.html',
  styleUrls: ['./multi-item-list-input.component.scss'],
})
export class MultiItemListInputComponent
  implements OnInit, ControlValueAccessor
{
  data: any[] = [];
  disabled = false;

  get internalValue(): AbstractControl {
    return this.internalForm.get(this.internalControlName);
  }

  internalForm: UntypedFormGroup;
  internalControlName = 'internalControl';

  newItem: string;

  @Input()
  inputName: string;

  @Input()
  flagAsRequired: boolean;

  @Input()
  labelName: string;

  @Input()
  appendLabel: boolean;

  @Input()
  showInputText: boolean;

  constructor(
    @Self()
    @Optional()
    public ngControl: NgControl,
    private formBuilder: UntypedFormBuilder,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    const control = this.ngControl.control;
    this.internalForm = this.formBuilder.group({});
    const controlValidator = control.validator;
    if (controlValidator) {
      this.internalForm.addControl(
        this.internalControlName,
        this.formBuilder.control('', [control.validator, Validators.required]),
      );
    } else {
      this.internalForm.addControl(
        this.internalControlName,
        this.formBuilder.control('', [Validators.required]),
      );
    }
  }

  removeItem(index: number) {
    let value: any[] = [];
    value = value.concat(this.data);
    value.splice(index, 1);
    this.ngControl.control.patchValue(value);
  }

  addItem(newValue: any) {
    let value: any[] = [];
    value = value.concat(this.data);
    value.push(newValue);
    this.ngControl.control.patchValue(value);
  }

  writeValue(value: any[]): void {
    this.data = value;
  }

  addInternalItem() {
    if (this.internalForm.valid) {
      this.addItem(this.internalForm.get(this.internalControlName).value);
      this.internalForm.reset();
    }
  }

  private onChange = (data: any[]) => {};
  private onTouched = () => {};

  registerOnChange(fn: (data: any[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
