import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ControlContainer,
  FormGroupDirective,
  UntypedFormGroup,
} from '@angular/forms';
import { SelectOption } from '@app/core/models/selector-option';
import { IconSizeEnum } from '@app/shared/models/icon-size-enum';

const defaultOption: SelectOption = {
  value: '',
  description: 'Please select',
};

const altDefaultOption: SelectOption = {
  value: 'All',
  description: 'All',
};

@Component({
  selector: 'app-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class SelectInputComponent {
  @Input()
  parentForm: UntypedFormGroup;

  @Input()
  buttonName: string;

  @Input()
  showButton: boolean;

  @Input()
  inputName: string;

  @Input()
  labelName: string;

  @Input()
  appendLabel: boolean;

  @Input()
  addDefaultOption = false;

  @Input()
  addAllAsDefaultOption = false;

  @Input()
  showSuccess = false;

  @Input()
  useFormForButton = true;

  _selectOptions: SelectOption[];
  @Input()
  set selectOptions(value: SelectOption[]) {
    const defaultOptions: SelectOption[] = this.addAllAsDefaultOption
      ? [altDefaultOption]
      : this.addDefaultOption
        ? [defaultOption]
        : [];

    this._selectOptions = value
      ? [...defaultOptions, ...value]
      : defaultOptions;
  }

  @Input()
  validationText: string;

  @Output()
  selectedValueChanged = new EventEmitter<string | number>();

  @Output()
  buttonPressed = new EventEmitter();

  @Input()
  ellipsisStart = 0;

  @Input()
  displayDescriptionLabel = false;

  @Input()
  displayValueLabel = false;

  @Input()
  flagAsRequired: boolean;

  @Input()
  additionalDescription?: string;

  get description() {
    const formValue = this.parentForm.get(this.inputName).value;
    if (this._selectOptions) {
      const option = this._selectOptions.find(
        (item) => item.value === formValue,
      );
      if (option) {
        let description = option.description;
        if (this.additionalDescription) {
          description += ' - ' + this.additionalDescription;
        }
        return description;
      }
    }
    return null;
  }

  get value() {
    return this.parentForm.get(this.inputName);
  }

  @Input()
  iconSize = IconSizeEnum.Large;

  @Input()
  canCopy: boolean;

  _copyLabel: string;

  @Input()
  set copyLabel(value: string) {
    this._copyLabel = value;
  }

  get copyLabel() {
    if (this._copyLabel) {
      return this._copyLabel;
    }
    return this.labelName;
  }

  getCopyItem(): string {
    const formValue = this.parentForm.get(this.inputName).value;

    return formValue;
  }

  valueSelected(value: string | number) {
    this.selectedValueChanged.emit(value);
  }

  clickButton() {
    this.buttonPressed.emit();
  }
}
