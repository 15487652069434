import { Component, Input } from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  UntypedFormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { RadioTextButtonField } from '@app/shared/models/radio-text-button-fields';

@Component({
  selector: 'app-radio-text-input',
  templateUrl: './radio-text-input.component.html',
  styleUrls: ['./radio-text-input.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class RadioTextInputComponent {
  @Input()
  parentForm: UntypedFormGroup;
  @Input()
  inputName: string;
  @Input()
  labelPosition: string;
  @Input()
  appendLabel: boolean;

  @Input()
  values: RadioTextButtonField[];

  @Input()
  set labelName(labelName: string) {
    if (labelName) {
      this._labelName = labelName;
    }
  }

  _labelName: string = null;

  get radioValue(): AbstractControl {
    return this.parentForm.get(this.inputName);
  }

  constructor() {}
}
