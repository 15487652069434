import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  UntypedFormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { IconSizeEnum } from '@app/shared/models/icon-size-enum';

@Component({
  selector: 'app-currency-input',
  templateUrl: './currency-input.component.html',
  styleUrls: ['./currency-input.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class CurrencyInputComponent {
  @Input()
  parentForm: UntypedFormGroup;

  @Input()
  inputName: string;

  @Input()
  labelName: string;

  @Input()
  appendButton: boolean;

  @Input()
  hideLabel: boolean;

  @Input()
  iconButton: string;

  @Input()
  description: string;

  @Input()
  flagAsRequired: boolean;

  @Input()
  editable: boolean;

  @Input()
  showSuccess: boolean;

  @Output()
  iconButtonClick = new EventEmitter<any>();

  @Output()
  inputFocused = new EventEmitter<string>();

  @Output()
  inputBlurred = new EventEmitter<string>();

  @Input()
  iconSize = IconSizeEnum.Large;

  @Input()
  canCopy: boolean;

  _copyLabel: string;

  @Input()
  set copyLabel(value: string) {
    this._copyLabel = value;
  }

  get copyLabel() {
    if (this._copyLabel) {
      return this._copyLabel;
    }
    return this.labelName;
  }

  getCopyItem(): string {
    const formValue = this.parentForm.get(this.inputName).value;

    return formValue;
  }

  get value(): AbstractControl {
    return this.parentForm.get(this.inputName);
  }

  onIconButtonClick() {
    this.iconButtonClick.emit();
  }

  onFocus() {
    this.inputFocused.emit(this.inputName);
  }

  onBlur() {
    this.inputBlurred.emit(this.inputName);
  }

  constructor() {}
}
