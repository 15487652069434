<div>
  <table class="table">
    <thead>
    <tr>
      <th>{{categoryName}}</th>
      <th>{{valueName}}</th>
    </tr>
    </thead>
    <tbody>
    <tr
      *ngFor="let item of tableRows"
    >
      <td>
        <label
          class="input-label"
        >
          {{item.label}}
        </label>
      </td>
      <td>
        <div class="input-group ">
          <div class="input-group-prepend">
            <span class="input-group-text">R</span>
          </div>
          <input
            appNumbersOnly
            type="text"
            [formControlName]="item.formField"
            class="form-control"
          />
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</div>
