<div [formGroup]="parentForm">
  <ckeditor
    [editor]="editor"
    [config]="config"
    [formGroup]="parentForm"
    [formControlName]="inputName"
  ></ckeditor>
  <div class="mt-3">
    <app-error-label
      *ngIf="control.invalid && control.touched"
      [message]="'Invalid ' + labelName || ''"
    ></app-error-label>
  </div>
</div>
