import { CurrencyPipe, PercentPipe } from '@angular/common';
import { Component, Input, PipeTransform } from '@angular/core';
import { NoOpPipe } from '@app/shared/pipes/noop/no-op.pipe';
import { StandardDatePipe } from '@app/shared/pipes/standard-date.pipe';
import { TableColumn } from '@swimlane/ngx-datatable';
import { AppConfigService } from '@app/core/services/app-config/app-config.service';
import { hasStyling } from '../../fancy-field-helpers';
import { FancyFieldStyles } from '../../fancy-field-styles.enum';
import { Field, RequestFieldType, Table } from '../../generic-data';

@Component({
  selector: 'app-generic-field-display',
  templateUrl: './generic-field-display.component.html',
  styleUrls: ['./generic-field-display.component.scss'],
})
export class GenericFieldDisplayComponent {
  _field: Field;
  columns: TableColumn[];
  rows: any[];
  rowCount = 0;
  tableCellRendererMap: { [K in RequestFieldType]: PipeTransform } = {
    [RequestFieldType.Text]: new NoOpPipe(),
    [RequestFieldType.Date]: new StandardDatePipe(this.appConfig.config.locale),
    [RequestFieldType.Table]: new NoOpPipe(),
    [RequestFieldType.FancyTable]: new NoOpPipe(),
    [RequestFieldType.Currency]: new CurrencyPipe(this.appConfig.config.locale),
    [RequestFieldType.Percentage]: new PercentPipe(
      this.appConfig.config.locale,
    ),
    [RequestFieldType.TextArray]: new NoOpPipe(),
    [RequestFieldType.Actions]: new NoOpPipe(),
    [RequestFieldType.LongText]: new NoOpPipe(),
    [RequestFieldType.Tooltip]: new NoOpPipe(),
  };

  @Input()
  displayLabel = true;

  @Input()
  set field(genericField: Field) {
    this._field = genericField;

    if (!this.field || this.field.type !== RequestFieldType.Table) {
      return;
    }

    const { columns } = this.field.value as Table;
    this.columns = columns.map(({ name, type }) => ({
      name,
      prop: name,
      pipe: this.tableCellRendererMap[type],
    }));

    const { rows } = this.field.value as Table;
    this.rows = rows.map(({ fields }) =>
      fields.reduce(
        (acc, { name, value }: Field) => ({
          ...acc,
          [name]: value,
        }),
        {},
      ),
    );
    this.rowCount = rows.length;
  }

  get field(): Field {
    return this._field;
  }

  requestFieldType = RequestFieldType;
  fancyFieldStyles = FancyFieldStyles;

  constructor(private appConfig: AppConfigService) {}
  hasStyling(column: Field, style: string) {
    return hasStyling(column, style);
  }
}
