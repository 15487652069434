<div cdkDropList class="list" (cdkDropListDropped)="drop($event)" *ngIf="getHasItems()">
  <div class="box" *ngFor="let item of data; let i = index" cdkDrag>
    <div class="custom-placeholder" *cdkDragPlaceholder></div>
    <span>{{item}}</span>
    <div class="d-inline-block" *ngIf="showButtons">
      <div class="d-flex align-items-center h-100">
        <app-icon-button
          (buttonClicked)="clickButton(i)"
          [icon]="buttonIcon"
          class="ms-1 warning"
        >
        </app-icon-button>
      </div>
    </div>
  </div>
</div>
