import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SortPropDir } from '@swimlane/ngx-datatable';
import { SearchRequest } from '../../interfaces/search-request';

@Component({
  selector: 'app-search-list',
  templateUrl: './search-list.component.html',
  styleUrls: ['./search-list.component.scss'],
})
export class SearchListComponent {
  @Input()
  columns: any[];

  @Input()
  rows: any[];

  @Input()
  searchData: SearchRequest = null;

  @Input()
  isLoading = false;

  @Output()
  selected = new EventEmitter();

  @Output()
  sort = new EventEmitter<SortPropDir>();

  selectedItem(event: any) {
    this.selected.emit(event);
  }

  sortColumn(sort: SortPropDir) {
    this.sort.emit(sort);
  }
}
