import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'booleanPending',
})
export class BooleanPendingPipe implements PipeTransform {
  transform(value: boolean): string {
    return value ? 'Pending' : '';
  }
}
