<div class="container-fluid">
  <div class="row">
    <div class="col">
      <button (click)="backClicked()" class="btn btn-primary">
        <app-icon name="caret-left"></app-icon>
        Back
      </button>

      <h4 *ngIf="title" class="d-inline-block title">{{ title }}</h4>
    </div>
  </div>
</div>
