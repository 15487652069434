import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'standardTime',
})
export class StandardTimePipe extends DatePipe implements PipeTransform {
  transform(dateString: string): any {
    return dateString ? super.transform(dateString, 'HH:mm:ss') : '';
  }
}
