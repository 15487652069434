import { ErrorHandler, Injectable } from '@angular/core';
import { AppInsightsService } from './app-insights.service';
import { AppConfigService } from './app-config/app-config.service';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
  constructor(
    private appInsights: AppInsightsService,
    private appConfig: AppConfigService,
  ) {
    super();
  }

  handleError(error: Error) {
    if (this.appConfig.config.applicationInsights.logExceptions) {
      this.appInsights.logException(error);
    }
    console.error(error);
  }
}
