import { Injectable } from '@angular/core';
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { formatInputDate, getInputDate } from '../date-input-format';

@Injectable()
export class DateParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (!value) {
      return null;
    }
    return getInputDate(new Date(value));
  }
  format(date: NgbDateStruct): string {
    let result: string = null;
    if (date) {
      result = formatInputDate(date);
    }
    return result;
  }
}
