<p class="input-info">
  {{ message }}
  <app-icon
    *ngIf="tooltip"
    [ngbTooltip]="tooltip"
    placement="right"
    container="body"
    name="help-circle"
    class="d-inline ps-1"></app-icon>
</p>
