<div class="form-group">
  <label
    [class.success]="showSuccess"
    *ngIf="!hideLabel"
    [for]="inputName"
    class="input-label"
    >{{ labelName }} <span *ngIf="flagAsRequired">*</span>
  </label>
  <div class="input-group mb-3">
    <div class="input-group-prepend">
      <span class="input-group-text">R</span>
    </div>
    <input
      appNumbersOnly
      type="text"
      [formControlName]="inputName"
      class="form-control"
      (focus)="onFocus()"
      (blur)="onBlur()"
    />
    <app-copy-button
      *ngIf="canCopy"
      [copyItem]="getCopyItem()"
      [label]="copyLabel"
      [iconSize]="iconSize"
    >
    </app-copy-button>
    <div
      *ngIf="appendButton"
      class="input-group-append"
      (click)="onIconButtonClick()"
    >
      <span class="btn-icon" [class.inactive-icon]="!editable">
        <app-icon class="icon" [name]="iconButton"></app-icon>
      </span>
    </div>
  </div>
  <app-error-label
    *ngIf="value.invalid && value.touched"
    [message]="'This field is invalid'"
  ></app-error-label>
  <app-info-label *ngIf="description" [message]="description"></app-info-label>
</div>
