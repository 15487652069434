<div class="row contact-form">
  <label *ngIf="!hideLabel" class="input-label col-12"
    >{{ labelName }} <span *ngIf="flagAsRequired">*</span>
  </label>
  <div class="col-6 pe-1">
    <app-select-input
      [appendLabel]="false"
      [inputName]="countryCodeControl"
      [parentForm]="parentForm"
      [showSuccess]="showSuccess"
      [selectOptions]="regions">
    </app-select-input>
  </div>
  <div class="col-6 ps-0">
    <app-phone-number-input
      (iconButtonClick)="onContactIconButtonClick()"
      [appendButton]="appendContactButton"
      [flagAsRequired]="true"
      [hideLabel]="true"
      [iconButton]="contactIconButton"
      [inputName]="numberControl"
      [parentForm]="parentForm"
      [showPrefix]="true"
      [prefix]="getPrefix()"
      [showSuccess]="showSuccess">
    </app-phone-number-input>
  </div>
</div>
