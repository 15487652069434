import { PhoneNumberUtil } from 'google-libphonenumber';
import { CountryCodeDetails } from '@app/shared/models/country-code-details';
import countryCodes from '../../../assets/CountryCodeNames.json';

export const getPhoneNumberRegions = () => {
  const phoneNumberUtil = PhoneNumberUtil.getInstance();
  const regions = phoneNumberUtil.getSupportedRegions();
  const countries: CountryCodeDetails[] = countryCodes;
  return regions
    .map((region) => {
      const country = countries.find((country) => country.code === region);
      return {
        code: `+${phoneNumberUtil.getCountryCodeForRegion(region)}`,
        description: `${country?.name ?? region} +${phoneNumberUtil.getCountryCodeForRegion(region)}`,
      };
    })
    .sort((a, b) => (a.description < b.description ? -1 : 1));
};
