import { RadioButtonField } from '@app/shared/models/radio-button-fields';
import { lowerCase, startCase } from 'lodash';
import { FormTableFields } from '@app/shared/models/form-table-fields';
import { SelectOption } from '../models/selector-option';

const isStringKey = (key: number | string) => isNaN(+key);

type KeyValue = {
  key: string;
  value: string | number;
};

export const objectToOptions = (
  obj: any,
  shouldSort: boolean = false,
  sortDescending: boolean = false,
): SelectOption[] => {
  const options = Object.keys(obj)
    .filter(isStringKey)
    .map((key) => ({
      value: obj[key],
      description: startCase(key),
    }));

  return shouldSort
    ? sortDescending
      ? options.sort((a, b) => (a.description > b.description ? -1 : 1))
      : options.sort((a, b) => (a.description > b.description ? 1 : -1))
    : options;
};
export const objectToOptionsWithDisplay = (
  obj: any,
  displayCallback: (key: number) => string,
  shouldSort: boolean = false,
  sortDescending: boolean = false,
): SelectOption[] => {
  const options = Object.keys(obj)
    .filter(isStringKey)
    .map((key) => ({
      value: obj[key],
      description: displayCallback(obj[key]),
    }));

  return shouldSort
    ? sortDescending
      ? options.sort((a, b) => (a.description > b.description ? -1 : 1))
      : options.sort((a, b) => (a.description > b.description ? 1 : -1))
    : options;
};

export const objectToRadioOptions = (obj: any): RadioButtonField[] =>
  Object.keys(obj)
    .filter(isStringKey)
    .map((key) => ({
      value: obj[key],
      label: startCase(key),
    }));

export const objectToFormTableFields = (obj: any): FormTableFields[] =>
  Object.keys(obj)
    .filter(isStringKey)
    .map((key) => ({
      formField: lowerCase(key),
      label: startCase(key),
      flagAsRequired: false,
    }));

export const arrayToOptions = <T>(
  inputArray: T[],
  valuesFn: (element: T) => KeyValue,
): SelectOption[] =>
  inputArray.map((arrValue) => {
    const values = valuesFn(arrValue);
    return {
      value: values.value,
      description: startCase(values.key),
    };
  });
