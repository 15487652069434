<div class="form-group">
  <label
    *ngIf="!hideLabel"
    [class.success]="showSuccess"
    [for]="inputName"
    class="input-label"
    >{{ labelName }} <span *ngIf="flagAsRequired">*</span></label
  >
  <div class="input-group mb-3" *ngIf="time.hour !== -1 && time.minute !== -1">
    <ngb-timepicker
      [(ngModel)]="time"
      (ngModelChange)="change()"
      [disabled]="!editable"
      [spinners]="false"
    ></ngb-timepicker>
  </div>
  <app-error-label
    *ngIf="value.invalid && value?.touched"
    [message]="'Invalid ' + labelName"
  ></app-error-label>
</div>
