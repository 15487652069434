export enum FilterParameterOperator {
  Contains = 'contains',
  Equals = 'equals',
  StartsWith = 'startswith',
  EndsWith = 'endswith',
  NotEqual = 'notequal',
  GreaterThan = 'greaterthan',
  GreaterThanOrEqual = 'greaterthanorequal',
  LessThan = 'lessthan',
  LessThanOrEqual = 'lessthanorequal',
  Like = 'like',
}

export interface FilterParameter {
  columnName: string;
  value: any;
  filterOperator: FilterParameterOperator;
}

export interface ShortenedFilterParameter {
  col: string;
  val: any;
  fO: FilterParameterOperator;
}

export const mapToShortenedFilterParameter = (
  filterParameter: FilterParameter,
): ShortenedFilterParameter => ({
  col: filterParameter.columnName,
  val: filterParameter.value,
  fO: filterParameter.filterOperator,
});
