import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthenticationState } from './authentication.state';

const getAuthenticationFeatureState =
  createFeatureSelector<AuthenticationState>('authentication');

export const getIsLoading = createSelector(
  getAuthenticationFeatureState,
  (state) => state.isLoading,
);

export const getIsAuthenticated = createSelector(
  getAuthenticationFeatureState,
  (state) => state.isAuthenticated,
);

export const getIsTimedOut = createSelector(
  getAuthenticationFeatureState,
  (state) => state.isTimedOut,
);

export const getAuthentication = createSelector(
  getAuthenticationFeatureState,
  (state) => state.authentication,
);

export const getUserDetails = createSelector(
  getAuthenticationFeatureState,
  (state) => state.userDetails,
);

export const authenticationQuery = {
  getIsLoading,
  getIsAuthenticated,
  getIsTimedOut,
  getAuthentication,
};
