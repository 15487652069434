<div class="modal-header" *ngIf="!hideHeader">
  <h6 class="mt-1">{{ title }}</h6>

  <button
    type="button"
    class="btn-close"
    *ngIf="showClose"
    (click)="isNestedModal ? closeInnerModal() : closeModal()"
    aria-label="Close"></button>
</div>

<div class="modal-body" [class.inner-modal-body]="isNestedModal">
  <ng-content></ng-content>
</div>

<ng-container *ngIf="footer">
  <div class="modal-footer">
    <ng-container *ngTemplateOutlet="footer"></ng-container>
  </div>
</ng-container>
