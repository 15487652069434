import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyWithComma',
})
export class CurrencyWithCommaPipe implements PipeTransform {
  transform(value: number = 0, addSymbol: boolean = true): string {
    if (!value) {
      return;
    }
    return addSymbol
      ? 'R ' + this.formatCurrency(value.toFixed(2))
      : this.formatCurrency(value.toFixed(2));
  }

  private formatNumber(n: string) {
    // format number 1000000 to 1,234,567
    return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  private formatCurrency(input: string) {
    if (!input) {
      return;
    }

    if (input.indexOf('.') >= 0) {
      const positionOfDecimal = input.indexOf('.');
      let leftSideOfDecimal = input.substring(0, positionOfDecimal);
      let rightSideOfDecimal = input.substring(positionOfDecimal);

      leftSideOfDecimal = this.formatNumber(leftSideOfDecimal);

      rightSideOfDecimal = this.formatNumber(rightSideOfDecimal);

      rightSideOfDecimal = rightSideOfDecimal.substring(0, 2);

      input = leftSideOfDecimal + '.' + rightSideOfDecimal;
    } else {
      input = this.formatNumber(input);
    }

    return input;
  }
}
