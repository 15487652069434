import { createAction, props } from '@ngrx/store';
import { getActionName } from '@app/core/state/state-helpers';
import { PagedApiData } from '@app/core/models/PagedApiData';
import { WhatsappMessageHistory } from '@app/communications/models/whatsapp-message-history';
import { UserMessagingMetadata } from '@app/communications/models/user-messaging-metadata';
import { SortPropDir } from '@swimlane/ngx-datatable';
import {
  ComplexSearchRequest,
  SearchRequest,
} from '@app/shared/search/interfaces/search-request';
import { MessageTypeEnum } from '@app/communications/models/message-type.enum';
import { MessageHistory } from '@app/communications/models/message-history';
import { WhatsappConversationDetails } from '@app/communications/models/whatsapp-conversation-details';
import { Message } from '@app/communications/models/message';
import { SendWhatsAppMessageRequest } from '@app/communications/models/send-whatsapp-message-request';
import { SendClaimMessageRequest } from '@app/communications/models/send-claim-message-request';
import { ClaimContactDetails } from '@app/communications/models/claim-contact-details';
import { AllowedWhatsAppFileTypes } from '@app/communications/models/allowed-whatsapp-file-types';
import { TemplatedMessage } from '@app/communications/models/templated-message';
import { WhatsappTemplate } from '@app/communications/models/whatsapp-template.enum';
import { SystemContact } from '@app/communications/models/system-contact';
import { SendPensionsBeneficiaryMessageRequest } from '@app/communications/models/send-pensions-beneficiary-message-request';
import { PensionsBeneficiaryContactDetails } from '@app/communications/models/pensions-beneficiary-contact-details';
import { SendSmsRequest } from '@app/communications/models/send-sms-request';
import { EducationAssistanceBeneficiaryContactDetails } from '@app/communications/models/education-assistance-beneficiary-contact-details';
import { SendEducationAssistanceMessageRequest } from '@app/communications/models/send-education-assistance-message-request';
import { UnreadWhatsappConversationsNotification } from '@app/communications/models/unread-whatsapp-conversations.notification';

const actionPrefix = '[Communications]';

export const loadUserMessagingMetadata = createAction(
  getActionName('Load user messaging metadata', actionPrefix),
);

export const loadUserMessagingMetadataSuccess = createAction(
  getActionName('Load user messaging metadata success', actionPrefix),
  props<{ response: UserMessagingMetadata }>(),
);

export const loadUserMessagingMetadataFailure = createAction(
  getActionName('Load user messaging metadata failure', actionPrefix),
  props<{ error: string }>(),
);
export const loadUserMessageHistory = createAction(
  getActionName('Load user message history', actionPrefix),
  props<{
    metadata: UserMessagingMetadata;
    limit: number;
    offset: number;
    searchData: SearchRequest;
    complexSearchData: ComplexSearchRequest;
    sort: SortPropDir;
  }>(),
);

export const loadUserMessageHistorySuccess = createAction(
  getActionName('Load user message history success', actionPrefix),
  props<{ response: PagedApiData<WhatsappMessageHistory> }>(),
);

export const loadUserMessageHistoryFailure = createAction(
  getActionName('Load user message history failure', actionPrefix),
  props<{ error: string }>(),
);

export const clearUserMessageHistory = createAction(
  getActionName('Clear user message history', actionPrefix),
);

export const loadConversationDetails = createAction(
  getActionName('Load conversation details', actionPrefix),
  props<{ conversationId: number }>(),
);

export const loadConversationDetailsSuccess = createAction(
  getActionName('Load conversation details success', actionPrefix),
  props<{ conversation: WhatsappConversationDetails }>(),
);

export const loadConversationDetailsFailure = createAction(
  getActionName('Load conversation details failure', actionPrefix),
  props<{ error: string }>(),
);

export const loadMessageHistory = createAction(
  getActionName('Load message history', actionPrefix),
  props<{
    messageType: MessageTypeEnum[];
    senders: string[];
    receivers: string[];
    limit: number;
    offset: number;
    searchData: SearchRequest;
    complexSearchData: ComplexSearchRequest;
    sort: SortPropDir;
  }>(),
);

export const loadMessageHistorySuccess = createAction(
  getActionName('Load message history success', actionPrefix),
  props<{ response: PagedApiData<MessageHistory> }>(),
);

export const loadMessageHistoryFailure = createAction(
  getActionName('Load message history failure', actionPrefix),
  props<{ error: string }>(),
);

export const clearMessageHistory = createAction(
  getActionName('Clear message history', actionPrefix),
);

export const loadWhatsappConversationMessages = createAction(
  getActionName('Load WhatsApp conversation messages', actionPrefix),
  props<{ conversationId: number; limit: number; offset: number }>(),
);

export const loadWhatsappConversationMessagesSuccess = createAction(
  getActionName('Load WhatsApp conversation messages success', actionPrefix),
  props<{ conversationId: number; response: PagedApiData<Message> }>(),
);

export const loadWhatsappConversationMessagesFailure = createAction(
  getActionName('Load WhatsApp conversation messages failure', actionPrefix),
  props<{ error: string }>(),
);

export const clearWhatsappConversationMessages = createAction(
  getActionName('Clear WhatsApp conversation messages', actionPrefix),
);

export const loadClaimSmsMessageTemplatePreview = createAction(
  getActionName('Load Claim Sms message template preview', actionPrefix),
  props<{ messageTemplate: MessageTypeEnum; claimCode: string }>(),
);

export const loadClaimSmsMessageTemplatePreviewSuccess = createAction(
  getActionName(
    'Load Claim Sms message template preview  success',
    actionPrefix,
  ),
  props<{ template: string }>(),
);

export const loadClaimSmsMessageTemplatePreviewFailure = createAction(
  getActionName(
    'Load Claim Sms message template preview failure',
    actionPrefix,
  ),
  props<{ error: string }>(),
);

export const clearClaimSmsMessageTemplatePreview = createAction(
  getActionName('Clear Claim Sms message template preview', actionPrefix),
);

export const markWhatsappConversationAsRead = createAction(
  getActionName('Mark WhatsApp conversation as read', actionPrefix),
  props<{ conversationId: number }>(),
);

export const markWhatsappConversationAsReadSuccess = createAction(
  getActionName('Mark WhatsApp conversation as read success', actionPrefix),
  props<{ conversationId: number }>(),
);

export const markWhatsappConversationAsReadFailure = createAction(
  getActionName('Mark WhatsApp conversation as read failure', actionPrefix),
  props<{ error: string }>(),
);
export const sendWhatsAppMessage = createAction(
  getActionName('Send WhatsApp message', actionPrefix),
  props<{ request: SendWhatsAppMessageRequest }>(),
);

export const sendWhatsAppMessageSuccess = createAction(
  getActionName('Send WhatsApp message success', actionPrefix),
  props<{ message: Message }>(),
);

export const sendWhatsAppMessageFailure = createAction(
  getActionName('Send WhatsApp message failure', actionPrefix),
  props<{ error: string }>(),
);

export const loadLatestMessagesForConversation = createAction(
  getActionName('Load latest messages for conversation', actionPrefix),
  props<{ conversationId: number }>(),
);

export const loadLatestMessagesForConversationSuccess = createAction(
  getActionName('Load latest messages for conversation success', actionPrefix),
  props<{ conversationId: number; messages: Message[] }>(),
);

export const loadLatestMessagesForConversationFailure = createAction(
  getActionName('Load latest messages for conversation failure', actionPrefix),
  props<{ error: string }>(),
);

export const loadClaimContactDetails = createAction(
  getActionName('Load claim contact detail form detail', actionPrefix),
  props<{ claimCode: string }>(),
);
export const loadClaimContactDetailsDataSuccess = createAction(
  getActionName('Load claim contact detail form detail success', actionPrefix),
  props<{ response: ClaimContactDetails[] }>(),
);
export const loadClaimContactDetailsDataFailure = createAction(
  getActionName('Load claim contact detail form detail failure', actionPrefix),
  props<{ error: string }>(),
);
export const startClaimWhatsAppConversation = createAction(
  getActionName('Start claim WhatsApp conversations', actionPrefix),
  props<{ request: SendClaimMessageRequest }>(),
);
export const startClaimWhatsAppConversationSuccess = createAction(
  getActionName('Start claim WhatsApp conversations success', actionPrefix),
);
export const startClaimWhatsAppConversationFailure = createAction(
  getActionName('Start claim WhatsApp conversations failure', actionPrefix),
  props<{ error: string }>(),
);

export const startClaimSmsConversation = createAction(
  getActionName('Start claim Sms conversations', actionPrefix),
  props<{ request: SendClaimMessageRequest }>(),
);
export const startClaimSmsConversationSuccess = createAction(
  getActionName('Start claim Sms conversations success', actionPrefix),
);
export const startClaimSmsConversationFailure = createAction(
  getActionName('Start claim Sms conversations failure', actionPrefix),
  props<{ error: string }>(),
);

export const sendWhatsAppOptIn = createAction(
  getActionName('Send WhatsApp Opt In', actionPrefix),
  props<{ request: SendWhatsAppMessageRequest }>(),
);
export const sendWhatsAppOptInSuccess = createAction(
  getActionName('Send WhatsApp Opt In success', actionPrefix),
);
export const sendWhatsAppOptInFailure = createAction(
  getActionName('Send WhatsApp Opt In failure', actionPrefix),
  props<{ error: string }>(),
);

export const sendSms = createAction(
  getActionName('Send sms', actionPrefix),
  props<{ request: SendSmsRequest }>(),
);

export const sendSmsSuccess = createAction(
  getActionName('Send sms success', actionPrefix),
);

export const sendSmsFailure = createAction(
  getActionName('Send sms failure', actionPrefix),
  props<{ error: string }>(),
);
export const downloadMediaFile = createAction(
  getActionName('Download media file', actionPrefix),
  props<{ id: number }>(),
);
export const downloadMediaFileSuccess = createAction(
  getActionName('Download media file success', actionPrefix),
  props<{ id: number }>(),
);
export const downloadMediaFileFailure = createAction(
  getActionName('Download media file failure', actionPrefix),
  props<{ error: string; id: number }>(),
);

export const getWhatsAppAllowedFileTypes = createAction(
  getActionName('Get WhatsApp allowed file types', actionPrefix),
);

export const getWhatsAppAllowedFileTypesSuccess = createAction(
  getActionName('Get WhatsApp allowed file types success', actionPrefix),
  props<{ response: AllowedWhatsAppFileTypes }>(),
);

export const getWhatsAppAllowedFileTypesFailure = createAction(
  getActionName('Get WhatsApp allowed file types failure', actionPrefix),
  props<{ error: string }>(),
);

export const getTemplatedMessage = createAction(
  getActionName('Get templated message', actionPrefix),
  props<{ template: WhatsappTemplate; contactId: number }>(),
);

export const getTemplatedMessageSuccess = createAction(
  getActionName('Get templated message success', actionPrefix),
  props<{ response: TemplatedMessage }>(),
);

export const getTemplatedMessageFailure = createAction(
  getActionName('Get templated message failure', actionPrefix),
  props<{ error: string }>(),
);

export const resetTemplatedMessage = createAction(
  getActionName('Reset templated message', actionPrefix),
);

export const sendTemplateMessage = createAction(
  getActionName('Send template message', actionPrefix),
  props<{ conversationId: number; templateId: WhatsappTemplate }>(),
);

export const sendTemplateMessageSuccess = createAction(
  getActionName('Send template message success', actionPrefix),
  props<{ conversationId: number }>(),
);

export const sendTemplateMessageFailure = createAction(
  getActionName('Send template message failure', actionPrefix),
  props<{ error: string }>(),
);

export const loadSystemContactDetails = createAction(
  getActionName('Load system contact-details', actionPrefix),
);

export const loadSystemContactDetailsSuccess = createAction(
  getActionName('Load system contact-details success', actionPrefix),
  props<{ response: SystemContact[] }>(),
);

export const loadSystemContactDetailsFailure = createAction(
  getActionName('Load system contact-details failure', actionPrefix),
  props<{ error: string }>(),
);

export const startPensionsBeneficiaryWhatsAppConversation = createAction(
  getActionName(
    'Start pensions beneficiary WhatsApp conversations',
    actionPrefix,
  ),
  props<{ request: SendPensionsBeneficiaryMessageRequest }>(),
);
export const startPensionsBeneficiaryWhatsAppConversationSuccess = createAction(
  getActionName(
    'Start pensions beneficiary WhatsApp conversations success',
    actionPrefix,
  ),
);
export const startPensionsBeneficiaryWhatsAppConversationFailure = createAction(
  getActionName(
    'Start pensions beneficiary WhatsApp conversations failure',
    actionPrefix,
  ),
  props<{ error: string }>(),
);

export const startPensionsBeneficiarySmsConversation = createAction(
  getActionName('Start pensions beneficiary Sms conversations', actionPrefix),
  props<{ request: SendPensionsBeneficiaryMessageRequest }>(),
);
export const startPensionsBeneficiarySmsConversationSuccess = createAction(
  getActionName(
    'Start pensions beneficiary Sms conversations success',
    actionPrefix,
  ),
);
export const startPensionsBeneficiarySmsConversationFailure = createAction(
  getActionName(
    'Start pensions beneficiary Sms conversations failure',
    actionPrefix,
  ),
  props<{ error: string }>(),
);
export const loadPensionsBeneficiaryContactDetails = createAction(
  getActionName(
    'Load pensions beneficiary contact detail detail',
    actionPrefix,
  ),
  props<{ claimId: number }>(),
);
export const loadPensionsBeneficiaryContactDetailsDataSuccess = createAction(
  getActionName(
    'Load pensions beneficiary contact detail success',
    actionPrefix,
  ),
  props<{ response: PensionsBeneficiaryContactDetails[] }>(),
);
export const loadPensionsBeneficiaryContactDetailsDataFailure = createAction(
  getActionName(
    'Load pensions beneficiary contact detail failure',
    actionPrefix,
  ),
  props<{ error: string }>(),
);
export const loadPensionsSmsMessageTemplatePreview = createAction(
  getActionName('Load Pensions Sms message template preview', actionPrefix),
  props<{ messageTemplate: MessageTypeEnum; claimId: number }>(),
);

export const loadPensionsSmsMessageTemplatePreviewSuccess = createAction(
  getActionName(
    'Load Pensions Sms message template preview  success',
    actionPrefix,
  ),
  props<{ template: string }>(),
);

export const loadPensionsSmsMessageTemplatePreviewFailure = createAction(
  getActionName(
    'Load Pensions Sms message template preview failure',
    actionPrefix,
  ),
  props<{ error: string }>(),
);

export const clearPensionsSmsMessageTemplatePreview = createAction(
  getActionName('Clear Pensions Sms message template preview', actionPrefix),
);

export const loadSMSMessageHistory = createAction(
  getActionName('Load sms message history', actionPrefix),
  props<{
    messageType: MessageTypeEnum[];
    senders: string[];
    receivers: string[];
    limit: number;
    offset: number;
    searchData: SearchRequest;
    complexSearchData: ComplexSearchRequest;
    sort: SortPropDir;
  }>(),
);

export const loadSMSMessageHistorySuccess = createAction(
  getActionName('Load sms message history success', actionPrefix),
  props<{ response: PagedApiData<MessageHistory> }>(),
);

export const loadSMSMessageHistoryFailure = createAction(
  getActionName('Load sms message history failure', actionPrefix),
  props<{ error: string }>(),
);

export const clearSMSMessageHistory = createAction(
  getActionName('Clear sms message history', actionPrefix),
);

export const loadEducationAssistanceContactDetails = createAction(
  getActionName('Load Education Assistance contact details', actionPrefix),
  props<{ beneficiaryId: number }>(),
);
export const loadEducationAssistanceContactDetailsSuccess = createAction(
  getActionName(
    'Load Education Assistance beneficiary contact detail success',
    actionPrefix,
  ),
  props<{ response: EducationAssistanceBeneficiaryContactDetails[] }>(),
);
export const loadEducationAssistanceContactDetailsFailure = createAction(
  getActionName(
    'Load Education Assistance beneficiary contact detail failure',
    actionPrefix,
  ),
  props<{ error: string }>(),
);

export const startEducationAssistanceWhatsAppConversation = createAction(
  getActionName('Start EAP beneficiary WhatsApp conversation', actionPrefix),
  props<{ request: SendEducationAssistanceMessageRequest }>(),
);
export const startEducationAssistanceWhatsAppConversationSuccess = createAction(
  getActionName(
    'Start EAP beneficiary WhatsApp conversation success',
    actionPrefix,
  ),
);
export const startEducationAssistanceWhatsAppConversationFailure = createAction(
  getActionName(
    'Start EAP beneficiary WhatsApp conversation failure',
    actionPrefix,
  ),
  props<{ error: string }>(),
);

export const startWhatsAppUnreadNotifications = createAction(
  getActionName('Create WhatsApp unread notifications', actionPrefix),
);
export const startWhatsAppUnreadNotificationsSuccess = createAction(
  getActionName('Create WhatsApp unread notifications success', actionPrefix),
);
export const startWhatsAppUnreadNotificationsFailure = createAction(
  getActionName('Create WhatsApp unread notifications failure', actionPrefix),
  props<{ error: string }>(),
);
export const updateWhatsAppUnreadNotifications = createAction(
  getActionName('Update WhatsApp unread notifications', actionPrefix),
  props<{ notification: UnreadWhatsappConversationsNotification }>(),
);
export const startCommunicationsNotifications = createAction(
  getActionName('Start communications notifications', actionPrefix),
);
export const starCommunicationsNotificationsSuccess = createAction(
  getActionName('Start communications notifications success', actionPrefix),
);
export const startCommunicationsNotificationsFailure = createAction(
  getActionName('Start communications notifications failure', actionPrefix),
  props<{ error: string }>(),
);
