import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  UntypedFormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { FormErrors } from '@app/core/models/form';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil,
} from 'rxjs/operators';
import { AutoUnsubscription } from '@app/shared/helpers/observable-helpers';

@Component({
  selector: 'app-typeahead-input',
  templateUrl: './typeahead-input.component.html',
  styleUrls: ['./typeahead-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class TypeaheadInputComponent
  extends AutoUnsubscription
  implements OnChanges
{
  @ViewChild('typeahead', { static: true })
  input: ElementRef;

  @Input()
  parentForm: UntypedFormGroup;

  @Input()
  inputName: string;

  @Input()
  labelName: string;

  @Input()
  inputProperty: string;

  @Input()
  inputDescription: string;

  @Input()
  iconButton: string;

  @Input()
  appendButton: boolean;

  @Input()
  flagAsRequired: boolean;

  @Output()
  iconButtonClick = new EventEmitter<string>();

  @Input()
  data: any[] = [];

  @Input()
  editable: boolean;

  @Input()
  placeholder = '';

  @Input()
  errors: FormErrors<unknown>;

  @Input()
  minCharsToSearch = 2;

  @Input()
  set focus(val: boolean) {
    if (val) {
      this.input.nativeElement.focus();
    }
  }

  get errorMessage(): string {
    return this.errors ? this.parentForm.errors[this.inputName] : null;
  }

  @Input()
  showSuccess = false;

  @Input()
  showInputOnResult = true;

  @Input()
  showDescriptionOnResult = true;

  @Input()
  showInputOnValue = true;

  @Input()
  showDescriptionOnValue = false;

  @Input()
  showDescription = true;

  @Output()
  itemSelected = new EventEmitter<string>();

  @Output()
  inputFocused = new EventEmitter<string>();

  @Output()
  inputBlurred = new EventEmitter<string>();

  constructor(private ref: ChangeDetectorRef) {
    super();
  }

  get inputValue(): AbstractControl {
    return this.parentForm.get(this.inputName);
  }

  get description() {
    if (this.inputDescription) {
      const formValue = this.parentForm.get(this.inputName).value;

      return formValue
        ? this.parentForm.get(this.inputName).value[this.inputDescription]
        : null;
    }
    return null;
  }

  handleTypeahead = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term.length < this.minCharsToSearch
          ? []
          : this.data
              .filter(
                (item) =>
                  this.formatResult(item)
                    .toLowerCase()
                    .indexOf(term.toLowerCase()) > -1,
              )
              .slice(0, 10),
      ),
    );

  formatInput = (item: any) => {
    let display = '';
    if (item) {
      if (item.hasOwnProperty(this.inputProperty) && this.showInputOnValue) {
        display += item[this.inputProperty];
      }
      if (
        item.hasOwnProperty(this.inputDescription) &&
        this.showDescriptionOnValue
      ) {
        display += this.showInputOnValue
          ? `  - ${item[this.inputDescription]}`
          : `${item[this.inputDescription]}`;
      }
    }

    return display;
  };

  formatResult = (item: any) => {
    let display = '';
    if (item && this.inputProperty && item.hasOwnProperty(this.inputProperty)) {
      if (this.showInputOnResult) {
        display += item[this.inputProperty];
      }

      if (this.inputDescription && item.hasOwnProperty(this.inputDescription)) {
        if (this.showDescriptionOnResult) {
          display += this.showInputOnResult
            ? `  - ${item[this.inputDescription]}`
            : `${item[this.inputDescription]}`;
        }
      }
    }

    return display;
  };

  onIconButtonClick() {
    this.iconButtonClick.emit(this.parentForm.controls[this.inputName].value);
  }

  onFocus() {
    this.inputFocused.emit(this.inputName);
  }

  onItemSelected(event: NgbTypeaheadSelectItemEvent) {
    this.itemSelected.emit(event.item[this.inputProperty]);
  }

  selectOneItem(event: any) {
    const value = event.target.value;

    if (!event.target.value || event.target.value.trim() === '') {
      this.inputBlurred.emit(this.inputName);
      return;
    }

    const itemByValue = this.data.find(
      (item) => item[this.inputProperty] === event,
    );

    if (itemByValue) {
      this.itemSelected.emit(event);
      this.parentForm.get(this.inputName).setValue(event);
      return;
    }

    const itemByStartingFormat = this.data.find(
      (item) =>
        this.formatResult(item).toLowerCase().indexOf(value.toLowerCase()) > -1,
    );

    if (itemByStartingFormat) {
      this.itemSelected.emit(itemByStartingFormat[this.inputProperty]);
      this.parentForm.get(this.inputName).setValue(itemByStartingFormat);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.registerRedraw();
  }

  private registerRedraw() {
    // Redraw the typeahead whenever the underlying form control value changes
    const control = this.parentForm.get(this.inputName);
    if (control) {
      control.valueChanges
        .pipe(takeUntil(this.notifier))
        .subscribe((value) => this.ref.markForCheck());
    }
  }
}
