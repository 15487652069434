import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@env/environment';

@Pipe({
  name: 'standardDate',
})
export class StandardDatePipe extends DatePipe implements PipeTransform {
  public transform(value: any): any {
    const nullFieldText = '-';
    return value
      ? super.transform(value, environment.dateFormat)
      : nullFieldText;
  }
}
