import { FilterField } from './filter-field';

export class TextFilterField extends FilterField<string, string> {
  minSearchCharacters: number;

  getDefaultValue(): string {
    return '';
  }

  isValid(value: string) {
    if (value) {
      return (
        value.toString().length >=
        (this.minSearchCharacters ? this.minSearchCharacters : 3)
      );
    }
  }

  getValue(value: any): string {
    return value ? value : null;
  }

  setMinSearchCharacters(minSearchCharacters: number) {
    this.minSearchCharacters = minSearchCharacters;
    return this;
  }
}
