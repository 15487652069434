import { always, applySpec, F, identity } from 'ramda';
import { ApiCall } from './api-call';

export interface ApiData<T> extends ApiCall {
  data: T;
}

const apiDataSpec: (x: any) => ApiData<any> = applySpec({
  data: identity,
  isLoading: F,
  error: always(null),
});

export const createApiDataInitialState = <T>(data: T = null): ApiData<T> =>
  apiDataSpec(data);
