import { AuthenticationService } from '../services/authentication.service';

export class AuthDirective {
  protected hasPermission(
    requiredPermission: any,
    authenticationService: AuthenticationService,
  ): boolean {
    if (requiredPermission == null) {
      return;
    }

    return authenticationService.hasPermission(requiredPermission);
  }
}
