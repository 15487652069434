import { FilterParameterBuilder } from '@app/shared/search/builders/filter-parameter.builder';
import {
  FilterParameter,
  FilterParameterOperator,
  mapToShortenedFilterParameter,
  ShortenedFilterParameter,
} from './filter-parameter';

export interface SearchRequest {
  operand: SearchOperand;
  filterParameters: FilterParameter[];
}

export interface ShortenedSearchRequest {
  op: SearchOperand;
  fP: ShortenedFilterParameter[];
}

export interface ComplexSearchRequest {
  filters: SearchRequest[];
  optionalFilters?: SearchRequest[];
}

export interface ShortenedComplexSearchRequest {
  filters: ShortenedSearchRequest[];
  optionalFilters?: ShortenedSearchRequest[];
}

export enum SearchOperand {
  OR = 'OR',
  AND = 'AND',
}

export const initialSearchRequest: SearchRequest = {
  filterParameters: null,
  operand: SearchOperand.OR,
};

export const initialComplexSearchRequest: ComplexSearchRequest = {
  filters: [initialSearchRequest],
  optionalFilters: [initialSearchRequest],
};

export const initialShortenedSearchRequest: ShortenedSearchRequest = {
  fP: null,
  op: SearchOperand.OR,
};

export const initialShortenedComplexSearchRequest: ShortenedComplexSearchRequest =
  {
    filters: [initialShortenedSearchRequest],
    optionalFilters: [initialShortenedSearchRequest],
  };

export const mapToShortenedComplexRequest = (
  searchRequest: ComplexSearchRequest,
): ShortenedComplexSearchRequest => ({
  filters: searchRequest.filters.map((searchRequest) =>
    mapToShortenedSearchRequest(searchRequest),
  ),
  optionalFilters: searchRequest.optionalFilters?.map((searchRequest) =>
    mapToShortenedSearchRequest(searchRequest),
  ),
});

export const mapToShortenedSearchRequest = (
  searchRequest: SearchRequest,
): ShortenedSearchRequest => ({
  op: searchRequest.operand,
  fP: searchRequest.filterParameters?.map((filterParameter) =>
    mapToShortenedFilterParameter(filterParameter),
  ),
});

export const alterFilterOperand = (
  searchRequest: SearchRequest,
  filterName: string,
  columnName: string,
  operator: FilterParameterOperator,
) => {
  if (searchRequest) {
    const filterIndex = searchRequest.filterParameters.findIndex(
      (fp) => fp.columnName === filterName,
    );

    if (filterIndex > -1) {
      const prevFilter = searchRequest.filterParameters[filterIndex];

      const newFilterParameter = new FilterParameterBuilder()
        .setFilterOperator(operator)
        .setNonGenericColumnName(columnName)
        .setValue(prevFilter.value)
        .build();

      searchRequest.filterParameters.splice(filterIndex, 1);

      searchRequest.filterParameters.push(newFilterParameter);
    }
  }
};
