<div [class]="'d-inline-block'">
  <div [class]="'d-flex align-items-center h-100'">
    <app-icon-button
      [tooltip]="getTooltip()"
      [icon]="icon"
      [iconSize]="iconSize"
      [placement]="placement"
      (buttonClicked)="copyToClipboard($event)"
      (mouseOver)="highlightIcon()"
      (mouseLeave)="initialiseIcon()"
     >
    </app-icon-button>
  </div>
</div>
