import { Component, ViewChild } from '@angular/core';
import { ModalComponent } from '@app/shared/modal/modal.component';

export enum UpdateModalResult {
  Refresh,
}

@Component({
  selector: 'app-update-modal',
  templateUrl: './update-modal.component.html',
  styleUrls: ['./update-modal.component.scss'],
})
export class UpdateModalComponent {
  @ViewChild('updateModal', { read: ModalComponent, static: true })
  updateModal: ModalComponent;

  refresh() {
    this.updateModal.activeModal.close(UpdateModalResult.Refresh);
  }
}
