import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SelectOption } from '@app/core/models/selector-option';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-adv-select-input',
  templateUrl: './advanced-select-input.component.html',
  styleUrls: ['./advanced-select-input.component.scss'],
})
export class AdvancedSelectInputComponent implements OnChanges, OnDestroy {
  private destroy$ = new Subject<boolean>();
  private _filterChanged$ = new Subject<string>();

  @Input()
  noValueText = 'Not selected';
  @Input()
  useDescriptionForDisplay = false;
  @Input()
  parentForm: UntypedFormGroup;

  @Input()
  inputName: string;

  @Input()
  externalFilter: boolean;

  @Input()
  items$: Observable<SelectOption[]>;
  @Input()
  labelName: string;

  @Input()
  appendLabel: boolean;

  @Input()
  flagAsRequired: boolean;

  @Input()
  disable: false;

  @Output()
  itemSelected$ = new EventEmitter<string>();

  @Output()
  filterChanged$ = new EventEmitter<string>();

  @ViewChild('input') inputElement: ElementRef;
  private selectedDescription: string;

  get value() {
    const value = this.parentForm.get(this.inputName).value;
    return value;
  }

  set value(value: any) {
    this.parentForm.get(this.inputName).setValue(value);
  }

  filteredItems$: Observable<SelectOption[]>;

  constructor() {}

  ngOnChanges() {
    if (this.items$) {
      this.items$.pipe(takeUntil(this.destroy$)).subscribe((items) => {
        const selectedItem = items.find((item) => item.value === this.value);
        if (selectedItem) {
          this.selectedDescription = selectedItem.description;
        }
      });
      this.filteredItems$ = this.items$;
      this._filterChanged$.pipe(takeUntil(this.destroy$)).subscribe((text) => {
        if (!this.externalFilter) {
          if (!text) {
            this.filteredItems$ = this.items$;
          } else {
            this.filteredItems$ = this.items$.pipe(
              map((items) =>
                items.filter((item) => {
                  const val = item.value.toString();
                  const description = item.description;
                  return (
                    val.toLowerCase().includes(text.toLowerCase()) ||
                    description.toLowerCase().includes(text.toLowerCase())
                  );
                }),
              ),
            );
          }
        }

        this.filterChanged$.emit(text);
      });
    }
  }

  onItemSelected(item: string, description: string): void {
    this.value = item;
    this.selectedDescription = description;
    this.itemSelected$.emit(item);
  }

  onFilterChanged(event: any): void {
    if (!event.target) {
      return;
    }
    this._filterChanged$.next(event.target.value);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  focusOnInput() {
    setTimeout(() => {
      this.inputElement.nativeElement.focus();
    }, 0);
  }

  getDisplayValue() {
    const description = this.useDescriptionForDisplay
      ? this.selectedDescription
      : this.value;
    return description ? description : this.noValueText;
  }
}
