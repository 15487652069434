import { Component, Input } from '@angular/core';
import { hasStyling } from '../../fancy-field-helpers';
import { FancyFieldStyles } from '../../fancy-field-styles.enum';
import { FancyRowStyles } from '../../fancy-row-styles.enum';
import {
  Column,
  Field,
  RequestFieldType,
  Row,
  Table,
} from '../../generic-data';

@Component({
  selector: 'app-fancy-table',
  templateUrl: './fancy-table.component.html',
  styleUrls: ['./fancy-table.component.scss'],
})
export class FancyTableComponent {
  rows: Row[];
  columns: Column[];

  requestFieldType = RequestFieldType;
  fancyRowStyles = FancyRowStyles;
  fancyFieldStyles = FancyFieldStyles;

  _field: Field;
  @Input()
  set field(field: Field) {
    this._field = field;

    const table: Table = this._field.value as Table;
    this.rows = table.rows;
    this.columns = table.columns;
  }

  hasStyling(column: Field, style: string) {
    return hasStyling(column, style);
  }
}
