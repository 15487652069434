import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TimeField } from '@app/shared/models/time-field';

@Component({
  selector: 'app-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss'],
})
export class TimeInputComponent {
  @Input()
  parentForm: UntypedFormGroup;

  @Input()
  inputName: string;

  @Input()
  labelName: string;

  @Input()
  set defaultTime(value: TimeField) {
    if (value != null) {
      this.time.hour = value.hour + 2;
      this.time.minute = value.minute;
    }
  }

  @Input()
  editable = true;

  @Input()
  flagAsRequired: boolean;

  @Input()
  hideLabel: boolean;

  @Input()
  prependButton: boolean;

  @Input()
  buttonIcon: string;

  @Input()
  showSuccess = false;

  @Output()
  inputFocused = new EventEmitter<string>();

  @Output()
  inputBlurred = new EventEmitter<string>();

  @Output()
  iconButtonClick = new EventEmitter();

  time = { hour: -1, minute: -1 };

  get value() {
    return this.parentForm.get(this.inputName);
  }

  change() {
    this.parentForm.controls[this.inputName].setValue(this.time);
    this.parentForm.controls[this.inputName].markAsDirty();
  }

  onFocus() {
    this.inputFocused.emit(this.inputName);
  }

  onBlur() {
    this.inputBlurred.emit(this.inputName);
  }
}
