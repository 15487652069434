import {
  FilterParameter,
  FilterParameterOperator,
} from '../interfaces/filter-parameter';
export class FilterParameterBuilder<T> {
  private readonly filterParameter: FilterParameter;

  constructor() {
    this.filterParameter = {
      columnName: '',
      value: null,
      filterOperator: FilterParameterOperator.Contains,
    };
  }

  setNonGenericColumnName(columnName: string): FilterParameterBuilder<any> {
    this.filterParameter.columnName = columnName;
    return this;
  }

  setColumnName<K extends keyof T>(columnName: K): FilterParameterBuilder<T> {
    this.filterParameter.columnName = columnName.toString();
    return this;
  }

  setValue(value: string | number | Date): FilterParameterBuilder<T> {
    this.filterParameter.value = value;
    return this;
  }

  setFilterOperator(
    filterOperator: FilterParameterOperator,
  ): FilterParameterBuilder<T> {
    this.filterParameter.filterOperator = filterOperator;
    return this;
  }

  build(): FilterParameter {
    return this.filterParameter;
  }
}
