import { Pipe, PipeTransform } from '@angular/core';
import { claimCodeDisplayFormatter } from '../helpers/claim-code-formatter';

const nullFieldText = '-';

@Pipe({
  name: 'claimCode',
})
export class ClaimCodePipe implements PipeTransform {
  public transform(value: string): any {
    return value ? claimCodeDisplayFormatter(value) : nullFieldText;
  }
}
