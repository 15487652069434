import { Directive, ElementRef, HostListener } from '@angular/core';
import { FormDirectiveService } from '@app/shared/services/form-directive.service';

@Directive({
  selector: '[appIDNumber]',
  providers: [FormDirectiveService],
})
export class IDNumberDirective {
  private allowedKeys = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'ArrowLeft',
    'ArrowRight',
    'Delete',
  ];
  regExpString = '^^[0-9]{1,13}$';

  constructor(
    private formDirectiveService: FormDirectiveService,
    private el: ElementRef,
  ) {
    formDirectiveService.allowedKeys = this.allowedKeys;
    formDirectiveService.regexPattern = this.regExpString;
  }

  @HostListener('keydown', ['$event'])
  keyDown(event: KeyboardEvent) {
    this.formDirectiveService.checkKeysAndRegex(this.el, event);
  }
}
