import { Component, Input, TemplateRef } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-checkbox-input',
  templateUrl: './checkbox-input.component.html',
  styleUrls: ['./checkbox-input.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class CheckboxInputComponent {
  @Input()
  inputName: string;

  @Input()
  labelName: string;

  @Input()
  hideLabel = false;

  @Input()
  tooltip: string | TemplateRef<any>;
}
