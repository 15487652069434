import { Pipe, PipeTransform } from '@angular/core';
import {
  displayReadableLoadingAuthorisationStatus,
  LoadingAuthorisationStatusEnum,
} from '@app/underwriting/assessments/models/loading-authorisation-status-enum';

@Pipe({
  name: 'convertToStatusName',
})
export class LoadingAuthorisationStatusPipe implements PipeTransform {
  transform(value: number): string {
    return displayReadableLoadingAuthorisationStatus(
      value as LoadingAuthorisationStatusEnum,
    );
  }
}
