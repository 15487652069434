import {
  ComplexSearchRequest,
  SearchRequest,
} from '../interfaces/search-request';

export class ComplexSearchRequestBuilder<T> {
  private readonly complexSearchRequest: ComplexSearchRequest;

  constructor() {
    this.complexSearchRequest = {
      filters: [],
      optionalFilters: [],
    };
  }

  addSearchRequest(
    searchRequest: SearchRequest,
  ): ComplexSearchRequestBuilder<T> {
    if (searchRequest) {
      this.complexSearchRequest.filters.push(searchRequest);
    }
    return this;
  }

  addSearchRequests(
    searchRequests: SearchRequest[],
  ): ComplexSearchRequestBuilder<T> {
    this.complexSearchRequest.filters.push(...searchRequests);
    return this;
  }

  combine(
    complexSearchRequest: ComplexSearchRequest,
  ): ComplexSearchRequestBuilder<T> {
    if (complexSearchRequest) {
      this.addSearchRequests(complexSearchRequest.filters);
      this.addOptionalSearchRequests(complexSearchRequest.optionalFilters);
    }
    return this;
  }

  addOptionalSearchRequest(
    searchRequest: SearchRequest,
  ): ComplexSearchRequestBuilder<T> {
    if (searchRequest) {
      this.complexSearchRequest.optionalFilters.push(searchRequest);
    }
    return this;
  }

  addOptionalSearchRequests(
    searchRequests: SearchRequest[],
  ): ComplexSearchRequestBuilder<T> {
    this.complexSearchRequest.optionalFilters.push(...searchRequests);
    return this;
  }

  build(): ComplexSearchRequest {
    return this.complexSearchRequest;
  }
}
