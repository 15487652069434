import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  UntypedFormGroup,
  FormGroupDirective,
} from '@angular/forms';

@Component({
  selector: 'app-id-number-input',
  templateUrl: './id-number-input.component.html',
  styleUrls: ['./id-number-input.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class IdNumberInputComponent {
  @Input()
  parentForm: UntypedFormGroup;

  @Input()
  inputName: string;

  @Input()
  labelName: string;

  @Input()
  appendButton: boolean;

  @Input()
  hideLabel: boolean;

  @Input()
  iconButton: string;

  @Input()
  description: string;

  @Input()
  flagAsRequired: boolean;

  @Input()
  editable: boolean;

  @Output()
  iconButtonClick = new EventEmitter<any>();

  @Output()
  inputFocused = new EventEmitter<string>();

  @Output()
  inputBlurred = new EventEmitter<string>();

  get value(): AbstractControl {
    return this.parentForm.get(this.inputName);
  }

  onIconButtonClick() {
    this.iconButtonClick.emit();
  }

  onFocus() {
    this.inputFocused.emit(this.inputName);
  }

  onBlur() {
    this.inputBlurred.emit(this.inputName);
  }
}
