import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { formatInputDateForModel, getInputDate } from '../date-input-format';

@Injectable()
export class DateModelAdapter extends NgbDateAdapter<string> {
  fromModel(value: string): NgbDateStruct {
    let result: NgbDateStruct = null;
    if (value) {
      result = getInputDate(new Date(value));
    }
    return result;
  }
  toModel(value: NgbDateStruct): string {
    let result: string = null;
    if (value) {
      result = formatInputDateForModel(value);
    }
    return result;
  }
}
