import { Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
@Injectable()
export abstract class AutoUnsubscription implements OnDestroy {
  notifier: Subject<void> = new Subject<void>();

  ngOnDestroy(): void {
    this.notifier.next();
    this.notifier.complete();
  }
}
