import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ControlContainer,
  UntypedFormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { FormErrors } from '@app/core/models/form';
import { convertToParagraph } from '@app/shared/helpers/string-helpers';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, Subject, Subscription } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { IconSizeEnum } from '@app/shared/models/icon-size-enum';
import { formatStandardDate } from '@app/shared/form-inputs/date-input-format';

@Component({
  selector: 'app-async-typeahead-input',
  templateUrl: './async-typeahead-input.component.html',
  styleUrls: ['./async-typeahead-input.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class AsyncTypeaheadInputComponent implements OnInit, OnDestroy {
  @Input()
  parentForm: UntypedFormGroup;

  @ViewChild('typeahead', { static: true })
  input: ElementRef;

  @Input()
  inputName: string;

  @Input()
  labelName: string;

  @Input()
  inputProperty: string;

  @Input()
  inputDescription: string | string[];

  @Input()
  buttonText: string;

  @Input()
  buttonEnabled = true;

  @Input()
  iconButton: string;

  @Input()
  appendButton: boolean;

  @Input()
  appendTextButton: boolean;

  @Input()
  data$: Observable<any[]> = new Observable<any[]>();

  /**
   * @deprecated
   */
  @Input()
  set editable(value: boolean) {
    if (this.parentForm) {
      if (value) {
        this.parentForm.controls[this.inputName].enable();
      } else {
        this.parentForm.controls[this.inputName].disable();
      }
    }
  }

  @Input()
  set focus(val: boolean) {
    if (val) {
      this.input.nativeElement.focus();
    }
  }

  @Input()
  debounceMs = 300;

  @Input()
  minCharsToSearch = 2;

  @Input()
  flagAsRequired: boolean;

  @Input()
  disableAutoSelectOnBlur: boolean;

  @Input()
  destructureSelectedItem: boolean;

  @Input()
  emitValueOnly = true;

  @Input()
  errors: FormErrors<unknown>;

  @Input()
  showSuccess: boolean;

  @Input()
  placeholder = '';

  @Input()
  useDataFormatter = false;

  @Input()
  shouldEmitItemOnSelect = false;
  @Input()
  hideLabel = false;

  get errorMessage(): string {
    return this.errors
      ? this.parentForm?.errors?.[this.inputName] ?? null
      : null;
  }

  @Output()
  buttonClick = new EventEmitter<any>();

  @Output()
  iconButtonClick = new EventEmitter<any>();

  @Output()
  filterChanged = new EventEmitter<string>();

  @Output()
  itemSelected = new EventEmitter<any>();

  private combine: Subscription;
  private _itemSelected$ = new Subject<string>();

  searching: boolean;
  searchFailed: boolean;
  filterValue: string;

  constructor() {}

  get inputValue() {
    return this.parentForm.get(this.inputName);
  }

  get description() {
    if (this.inputValue && this.inputValue.value) {
      return this.getRenderedDescription(this.inputValue.value);
    }
    return null;
  }

  @Input()
  iconSize = IconSizeEnum.Large;

  @Input()
  canCopy: boolean;

  _copyLabel: string;

  @Input()
  set copyLabel(value: string) {
    this._copyLabel = value;
  }

  get copyLabel() {
    if (this._copyLabel) {
      return this._copyLabel;
    }
    return this.labelName;
  }

  getCopyItem(): string {
    const formValue = this.parentForm.get(this.inputName).value;

    return formValue;
  }

  ngOnInit(): void {
    this.combine = this._itemSelected$
      .pipe(withLatestFrom(this.data$))
      .subscribe(([selected, items]) => {
        if (!selected) {
          this.parentForm.get(this.inputName).setValue('');
          return;
        } else {
          const selectedItem = items.find(
            (item) =>
              this.parseResult(item)
                .toLowerCase()
                .indexOf(
                  typeof selected === 'string'
                    ? selected.toLowerCase()
                    : selected,
                ) > -1,
          );

          if (!selectedItem) {
            return;
          }
          if (this.emitValueOnly) {
            this.itemSelected.emit(selected);
          } else {
            this.itemSelected.emit(selectedItem);
          }

          if (this.destructureSelectedItem) {
            this.parentForm
              .get(this.inputName)
              .setValue(selectedItem[this.inputProperty]);
          } else {
            this.parentForm.get(this.inputName).setValue(selectedItem);
          }
        }
      });
  }

  handleTypeahead = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(this.debounceMs),
      distinctUntilChanged(),
      tap(() => (this.searching = true)),
      switchMap((filter) => {
        this.filterValue = filter;

        if (filter.length < this.minCharsToSearch) {
          return of([]);
        }

        this.filterChanged.emit(filter);
        return this.data$.pipe(
          tap(() => (this.searchFailed = false)),
          map((items: any[]) =>
            items.filter(
              (item) =>
                this.parseResult(item)
                  .toLowerCase()
                  .indexOf(filter.toLowerCase()) > -1,
            ),
          ),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }),
        );
      }),
      tap(() => (this.searching = false)),
    );

  formatInput = (item: any) => {
    if (item && item.hasOwnProperty(this.inputProperty)) {
      return item[this.inputProperty];
    }
    return item || '';
  };

  parseResult = (item: any) => {
    let display = '';
    if (item && this.inputProperty && item.hasOwnProperty(this.inputProperty)) {
      display += item[this.inputProperty];
      if (this.inputDescription) {
        display += ' - ' + this.getRenderedDescription(item);
      }
    }
    return display;
  };

  formatResult = (item: any) => {
    let display = '';
    if (item && this.inputProperty && item.hasOwnProperty(this.inputProperty)) {
      display += item[this.inputProperty];
      if (this.inputDescription) {
        display += ' - ' + this.getRenderedDescription(item);
      }
    }

    const description = convertToParagraph(display);

    return description;
  };

  onIconButtonClick() {
    if (this.buttonEnabled) {
      this.iconButtonClick.emit(this.parentForm.controls[this.inputName].value);
    }
  }

  onButtonClick() {
    if (this.buttonEnabled) {
      this.buttonClick.emit(this.parentForm.controls[this.inputName].value);
    }
  }

  onItemSelected(event: NgbTypeaheadSelectItemEvent) {
    const value = this.shouldEmitItemOnSelect
      ? event.item
      : event.item[this.inputProperty];
    this._itemSelected$.next(value);
  }

  setItem(value: any) {
    const controlValue = this.inputValue.value;
    if (
      this.disableAutoSelectOnBlur ||
      !value ||
      !value.target.value ||
      (!!controlValue && typeof controlValue !== 'string')
    ) {
      return;
    }
    this._itemSelected$.next(value.target.value);
  }

  ngOnDestroy(): void {
    if (this.combine) {
      this.combine.unsubscribe();
    }
  }

  getRenderedDescription(object: any) {
    if (this.inputDescription && object) {
      const isString = typeof this.inputDescription === 'string';
      if (isString) {
        return this.getFieldValue(this.inputDescription as string, object);
      } else {
        return this.getDescriptionFromArray(
          this.inputDescription as string[],
          object,
        );
      }
    }
    return null;
  }

  getFieldValue(fieldName: string, object: any) {
    if (object && fieldName) {
      const formValue = object[fieldName];
      if (formValue) {
        return formValue;
      }
    }
    return null;
  }

  getDescriptionFromArray(fields: string[], object: any) {
    if (!fields) {
      return null;
    }

    const description = fields.reduce(
      (concatenatedDescription, field, index) => {
        const value = this.getFieldValue(field, object);
        if (value) {
          if (field.toLowerCase().includes('date')) {
            concatenatedDescription += formatStandardDate(value);
          } else {
            concatenatedDescription += value;
          }
          if (index < fields.length - 1) {
            concatenatedDescription += ' - ';
          }
        }
        return concatenatedDescription;
      },
      '',
    );

    return description ? description : null;
  }
}
