import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { PagedApiData } from '@app/core/models/PagedApiData';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-export-excel-modal',
  templateUrl: './export-excel-modal.component.html',
  styleUrls: ['./export-excel-modal.component.scss'],
})
export class ExportExcelModalComponent implements OnInit {
  @Input()
  apiData: Observable<PagedApiData<any>>;

  @Input()
  maxRows?: number;

  public form: UntypedFormGroup;

  constructor(
    private modal: NgbActiveModal,
    private fb: UntypedFormBuilder,
  ) {
    const rowCount = new UntypedFormControl(0, [Validators.required]);

    this.form = this.fb.group({
      rowCount,
    });
  }

  ngOnInit(): void {}

  submit() {
    this.modal.close(this.form.controls.rowCount.value);
  }
}
