import { Component, Input } from '@angular/core';
import {
  ControlContainer,
  UntypedFormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { FormTableFields } from '@app/shared/models/form-table-fields';

@Component({
  selector: 'app-table-currency-input',
  templateUrl: './table-currency-input.component.html',
  styleUrls: ['./table-currency-input.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class TableCurrencyInputComponent {
  @Input()
  categoryName: string;
  @Input()
  valueName: string;
  @Input()
  tableRows: FormTableFields[];
  @Input()
  parentForm: UntypedFormGroup;

  @Input()
  editable: boolean;

  constructor() {}
}
