import { Component, Input } from '@angular/core';
import { TooltipPlacementEnum } from '../models/tooltip-placement-enum';

@Component({
  selector: 'app-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss'],
})
export class CustomTooltipComponent {
  @Input()
  placement = TooltipPlacementEnum.Top;

  @Input()
  tooltipText: string;

  @Input()
  iconName: string;

  @Input()
  iconColor = 'primary';

  @Input()
  textAlign = 'start';

  iconClass(): string {
    return `fem-icon fem-icon-${this.iconName} text-${this.iconColor}`;
  }
}
