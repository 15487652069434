<div [class.input-container]="!hideLabel">
  <label
    [class.success]="showSuccess && showLabelSuccess"
    [class.warning]="showWarning"
    [class.error]="showError"
    class="input-label"
    *ngIf="!hideLabel"
    >{{ labelName }}</label
  >
  <app-icon
    *ngIf="showIcon"
    name="edit"
    class="ms-2 icon"
    (click)="iconClick()"></app-icon>
  <ng-container *ngIf="!isArrayInput; else multiLine">
    <div
      [class.form-control]="applyFormStyling"
      [class.form-control-plain]="applyFormStyling"
      [class.small-text]="smallText"
      [class.success]="showSuccess && showTextSuccess"
      [class.warning]="showWarning"
      [class.error]="showError">
      <span class="text-display-container">
        <div [class.short-file-name]="truncateText">
          {{ value | nullableField }}
        </div>
        <div class="d-inline-block" *ngIf="showButton">
          <div class="d-flex align-items-center h-100">
            <app-icon-button
              (buttonClicked)="clickButton()"
              [icon]="buttonIcon"
              [iconSize]="iconSize"
              class="ms-1"
              [tooltip]="buttonTooltip">
            </app-icon-button>
          </div>
        </div>
        <app-copy-button
          *ngIf="canCopy"
          [copyItem]="getCopyItem()"
          [label]="copyLabel"
          [iconSize]="iconSize">
        </app-copy-button>
        <app-icon
          *ngIf="showTooltip"
          [ngbTooltip]="tooltipText"
          [placement]="toolTipPlacement"
          container="body"
          name="help-circle"
          class="d-inline ps-1"></app-icon>
      </span>
    </div>
  </ng-container>
  <app-info-label
    *ngIf="description && !hasDisplayAction"
    [message]="description"
    [tooltip]="descriptionTooltip"></app-info-label>
  <span
    class="btn-link"
    style="cursor: pointer; display: block"
    *ngIf="hasDisplayAction && actionDescription"
    (click)="actionClicked.emit()">
    {{ actionDescription }}
  </span>
</div>

<ng-template #multiLine>
  <div
    *ngFor="let val of value"
    [class.form-control]="applyFormStyling"
    [class.form-control-plain]="applyFormStyling">
    {{ val }}
  </div>
</ng-template>
