import { Authentication } from '../models/authentication';
import { User } from '../models/user';
import { UserDetails } from '../models/user-details';

export interface AuthenticationState {
  isLoading: boolean;
  isAuthenticated: boolean;
  isTimedOut: boolean;
  authentication: Authentication;
  user: User;
  userDetails: UserDetails;
  error: string;
}

export const initialState: AuthenticationState = {
  isLoading: true,
  isAuthenticated: false,
  isTimedOut: false,
  authentication: null,
  user: null,
  userDetails: null,
  error: null,
};
