export enum LoadingAuthorisationStatusEnum {
  AwaitingLoadingReferral,
  LoadingAuthorisationRejected,
  AwaitingLoadingAuthorisation,
  LoadingAuthorisationApproved,
  LoadingAuthorisationReversed,
}

export const displayReadableLoadingAuthorisationStatus = (
  value: LoadingAuthorisationStatusEnum,
) => {
  switch (value) {
    case LoadingAuthorisationStatusEnum.AwaitingLoadingReferral:
      return 'Pending Review';
    case LoadingAuthorisationStatusEnum.LoadingAuthorisationRejected:
      return 'Rejected';
    case LoadingAuthorisationStatusEnum.AwaitingLoadingAuthorisation:
      return 'Awaiting Loading Authorisation';
    case LoadingAuthorisationStatusEnum.LoadingAuthorisationApproved:
      return 'Loading Authorisation Approved';
    case LoadingAuthorisationStatusEnum.LoadingAuthorisationReversed:
      return 'Loading Authorisation Reversed';
  }
};
