import { Pipe, PipeTransform } from '@angular/core';
import { ProcurementPrice } from '@app/claims-administration/payment/models/procurements/procurement-price';
import { DisplayAsCurrencyPipe } from '../currency/display-as-currency.pipe';

@Pipe({
  name: 'displayItemDetailPrice',
})
export class DisplayItemDetailPricePipe implements PipeTransform {
  constructor(private currencyPipe: DisplayAsCurrencyPipe) {}

  transform(value: ProcurementPrice, ...args: unknown[]): unknown {
    if (value) {
      let description = this.currencyPipe.transform(value.price);
      if (value.unitDescription) {
        description += ' ' + value.unitDescription;
      }
      return description;
    }

    return null;
  }
}
