import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pending-changes-modal',
  templateUrl: './pending-changes-modal.component.html',
  styleUrls: ['./pending-changes-modal.component.scss'],
})
export class PendingChangesModalComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}
}
