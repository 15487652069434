import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-overview-section-search',
  templateUrl: './overview-section-search.component.html',
  styleUrls: ['./overview-section-search.component.scss'],
})
export class OverviewSectionSearchComponent {
  @Input()
  label: string;

  @Input()
  value: any;

  @Input()
  description: string;

  @Input()
  inputProperty: string;

  @Input()
  inputDescription: string;

  @Input()
  data$ = new Observable<any[]>();

  @Input()
  tooltip: string;

  @Input()
  icon: string;

  @Input()
  showToolTip: boolean;

  @Output()
  searchFilterChanged = new EventEmitter();

  @Output()
  searchFilterSelected = new EventEmitter();

  onSearchFilterChanged(value: any) {
    this.searchFilterChanged.emit(value);
  }

  onSearchFilterSelected(value: any) {
    this.searchFilterSelected.emit(value);
  }

  searchForm: UntypedFormGroup;

  constructor() {
    this.searchForm = new UntypedFormGroup({
      search: new UntypedFormControl(''),
    });
  }
}
