<div class="form-group">
  <label
    [class.success]="showSuccess"
    *ngIf="appendLabel"
    class="input-label select-label"
    >{{ labelName }} <span *ngIf="flagAsRequired">*</span>
  </label>
  <div class="input-group">
    <select
      #selectList
      [name]="inputName"
      [formControlName]="inputName"
      class="form-select"
      [id]="inputName"
      (change)="valueSelected(selectList.value)">
      <option
        *ngFor="let item of _selectOptions"
        [ngValue]="item.defaultValue ? item.defaultValue : item.value"
        [disabled]="item.disabled ?? false">
        <span *ngIf="ellipsisStart">{{
          item.description | textEllipsis: ellipsisStart
        }}</span>
        <span *ngIf="!ellipsisStart">{{
          item.description | textEllipsis
        }}</span>
      </option>
    </select>
    <app-copy-button
      *ngIf="canCopy"
      [copyItem]="getCopyItem()"
      [label]="copyLabel"
      [iconSize]="iconSize">
    </app-copy-button>
    <div *ngIf="showButton" class="ms-2">
      <button
        type="button"
        class="btn btn-primary"
        (click)="clickButton()"
        [disabled]="
          (!parentForm.dirty || !parentForm.valid) && useFormForButton
        ">
        {{ buttonName }}
      </button>
    </div>
  </div>
</div>

<ng-container
  *ngIf="(validationText || value.invalid) && value.touched; else label">
  <app-error-label
    *ngIf="value.invalid && value.touched; else label"
    [message]="
      validationText ? validationText : 'This field is invalid'
    "></app-error-label>
</ng-container>
<ng-template #label>
  <app-info-label
    *ngIf="description && displayDescriptionLabel && !displayValueLabel"
    [message]="description"></app-info-label>
  <app-info-label
    *ngIf="value && !displayDescriptionLabel && displayValueLabel"
    [message]="value.value"></app-info-label>
</ng-template>
