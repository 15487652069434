import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-info-label',
  templateUrl: './info-label.component.html',
  styleUrls: ['./info-label.component.scss'],
})
export class InfoLabelComponent implements OnInit {
  @Input() message: string;

  @Input()
  tooltip: string | TemplateRef<any>;

  constructor() {}

  ngOnInit() {}
}
