import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/http/http.service';
import { ApiError } from '@app/core/models/ErrorResponse';
import { ToastService } from '@app/core/services/toast.service';
import { MsalService } from '@azure/msal-angular';
import { environment } from '@env/environment';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, takeWhile, tap } from 'rxjs/operators';
import { AppConfigService } from '@app/core/services/app-config/app-config.service';
import { HubConnection } from '@microsoft/signalr';
import { Action } from '@ngrx/store';
import { startCommunicationsNotifications } from '@app/communications/state/communications.actions';
import * as authenticationActions from './authentication.actions';

@Injectable()
export class AuthenticationEffects {
  constructor(
    private actions$: Actions,
    private msalService: MsalService,
    private http: HttpService,
    private toastService: ToastService,
    private appConfig: AppConfigService,
  ) {}

  private hubConnection: HubConnection | undefined;

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticationActions.logout),
      switchMap(() => [authenticationActions.trackUserLogout()]),
    ),
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticationActions.loginSuccess),
      switchMap(() => [authenticationActions.loadCurrentUserDetails()]),
    ),
  );

  trackLogin$ = createEffect(() =>
    this.actions$.pipe(
      takeWhile(() => this.appConfig.config.features.trackUserAuthentication),
      ofType(authenticationActions.trackUserLogin),
      switchMap(() =>
        this.http.post(`users/useraccess/logLogin`, null).pipe(
          map(() => authenticationActions.trackUserLoginSuccess()),
          catchError(({ friendlyMessage }: ApiError) => {
            this.toastService.showError(
              `Could not log the user in - ${friendlyMessage}`,
            );
            return of(
              authenticationActions.trackUserLoginError({
                payload: friendlyMessage,
              }),
            );
          }),
        ),
      ),
    ),
  );

  trackLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticationActions.trackUserLogout),
      switchMap(() => {
        if (this.appConfig.config.features.trackUserAuthentication) {
          return this.http.post(`users/useraccess/logLogout`, null).pipe(
            switchMap(() => [
              authenticationActions.trackUserLogoutSuccess(),
              authenticationActions.handleLogout(),
            ]),
            catchError(({ friendlyMessage }: ApiError) => {
              this.toastService.showError(
                `An issue occurred logging the user out - ${friendlyMessage}`,
              );
              return [
                authenticationActions.trackUserLogoutError({
                  payload: friendlyMessage,
                }),
                authenticationActions.handleLogout(),
              ];
            }),
          );
        } else {
          return of(authenticationActions.handleLogout());
        }
      }),
    ),
  );

  handleLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticationActions.handleLogout),
      tap(() => this.msalService.logout()),
      map(() => authenticationActions.logoutFinalized()),
    ),
  );

  retrieveCurrentUserDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticationActions.loadCurrentUserDetails),
      switchMap(() =>
        this.http.get('users/useraccess/current-user-details').pipe(
          switchMap(({ token: user }) => {
            if (!environment.production) {
              console.log('Dev Environment Only', { user });
            }
            return [
              authenticationActions.loadCurrentUserDetailsSuccess({ user }),
              authenticationActions.trackUserLogin(),
            ];
          }),
          catchError(({ friendlyMessage }: ApiError) => {
            this.toastService.showError(
              `Could not retrieve the current user\'s details - ${friendlyMessage}`,
            );
            return of(
              authenticationActions.loadCurrentUserDetailsFailure({
                payload: friendlyMessage,
              }),
            );
          }),
        ),
      ),
    ),
  );

  startNotifications = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticationActions.loadCurrentUserDetailsSuccess),
      switchMap(() => [authenticationActions.startNotifications()]),
    ),
  );

  startNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticationActions.startNotifications),
      switchMap(() => {
        const actions: Action[] = [];
        if (this.appConfig.config.features.notifications.communications) {
          actions.push(startCommunicationsNotifications());
        }

        return actions;
      }),
    ),
  );
}
