import { Injectable } from '@angular/core';
import {
  FilterParameter,
  FilterParameterOperator,
} from '../interfaces/filter-parameter';
import { SearchOperand, SearchRequest } from '../interfaces/search-request';

@Injectable({
  providedIn: 'root',
})
export class SimpleSearchService {
  constructor() {}

  canSearch = (searchData: string) =>
    searchData.length >= 1 || searchData.length === 0;

  doSearch(
    rawSearchData: string = '',
    columnName: string,
    exactMatch = false,
  ): SearchRequest {
    const searchData = rawSearchData.trim();

    if (searchData.length === 0) {
      return null;
    }

    const filterOperator = exactMatch
      ? FilterParameterOperator.Equals
      : FilterParameterOperator.Contains;

    if (this.canSearch(searchData)) {
      const filter: FilterParameter = {
        columnName,
        value: searchData,
        filterOperator: filterOperator,
      };

      const request: SearchRequest = {
        operand: SearchOperand.OR,
        filterParameters: [filter],
      };
      return request;
    }

    return null;
  }
}
