<context-menu #contextMenu>
  <ng-container *ngFor="let action of menuItems">
    <context-menu #subActions>
      <ng-container *ngIf="action.type === 'dropdown'">
        <ng-template
          contextMenuItem
          *ngFor="let subAction of getActionAsDropdown(action).options"
          [visible]="subAction.isVisible"
          [disabled]="!subAction.isEnabled"
          (execute)="subAction.fn()">
          {{ subAction.name }}
        </ng-template>
      </ng-container>
    </context-menu>
    <ng-template
      contextMenuItem
      [subMenu]="action.type === 'dropdown' ? subActions : null"
      [visible]="action.isVisible"
      [disabled]="!action.isEnabled"
      (execute)="action.fn()">
      {{ action.name }}
    </ng-template>
  </ng-container>
</context-menu>
