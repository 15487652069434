import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textEllipsis',
})
export class TextEllipsisPipe implements PipeTransform {
  transform(value: string, stringLength: number = 50): any {
    if (value.length >= stringLength && stringLength > 3) {
      return value.substring(0, stringLength - 3) + '...';
    }
    return value;
  }
}
