import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthIfAnyDirective } from './directives/auth-any-if.directive';
import { AuthIfDirective } from './directives/auth-if.directive';
import { AuthenticationEffects } from './state/authentication.effects';
import { reducer } from './state/authentication.reducers';
import { AuthenticationFacade } from './state/authentication.facade';
@NgModule({
  declarations: [AuthIfDirective, AuthIfAnyDirective],
  imports: [
    CommonModule,
    StoreModule.forFeature('authentication', reducer),
    EffectsModule.forFeature([AuthenticationEffects]),
  ],
  exports: [AuthIfDirective, AuthIfAnyDirective],
  providers: [AuthenticationFacade],
})
export class AuthenticationModule {}
