import { Inject, Injectable, InjectionToken } from '@angular/core';
import { AppConfig } from './app-config';

export const APP_CONFIG: InjectionToken<AppConfig> =
  new InjectionToken<AppConfig>('AppConfig');

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private appConfig?: AppConfig;

  constructor(@Inject(APP_CONFIG) private injectedConfig: any) {
    this.appConfig = this.injectedConfig;
  }

  get config() {
    if (!this.appConfig) {
      throw new Error('AppConfig is not loaded correctly');
    }
    return this.appConfig;
  }
}
