import { Component, Input } from '@angular/core';
import { DropDown } from '@app/shared/interfaces/dropdown';
import { ButtonWithInformationLabels } from '@app/shared/models/button-with-information-labels';
import { Button, IconButton } from '../interfaces/button';

const defaultHeaderHeight = 60;

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input()
  title: string;

  @Input()
  headerHeight = defaultHeaderHeight;

  @Input()
  actionButtons: (Button | IconButton)[] = [];

  @Input()
  buttons: (Button | DropDown)[] = [];

  @Input()
  showHeaderSuccess = false;

  @Input()
  showHeaderIntermediateSuccess = false;

  @Input()
  showHeaderRejection = false;

  @Input()
  showHeaderOverdue = false;

  @Input()
  showReopenRequestHeader = false;

  @Input()
  showWarningHeader = false;

  @Input()
  showErrorHeader = false;

  @Input()
  dropdownOptions: Button[] = [];

  @Input()
  informationLabels: string[];

  @Input()
  buttonsWithLabels: ButtonWithInformationLabels[];
  get visibleActionButtons() {
    if (!this.actionButtons || this.actionButtons.length === 0) {
      return [];
    }

    return this.actionButtons.filter(({ isVisible }) => isVisible);
  }

  get visibleButtons() {
    if (!this.buttons || this.buttons.length === 0) {
      return [];
    }

    return this.buttons.filter(({ isVisible }) => isVisible);
  }

  get visibleDropdownOptions() {
    if (!this.dropdownOptions?.length) {
      return [];
    }

    return this.dropdownOptions.filter(({ isVisible }) => isVisible);
  }

  hasHighlightedDropdownOptions(): boolean {
    return (
      this.visibleDropdownOptions.filter(({ isHighlighted }) => isHighlighted)
        .length > 0
    );
  }

  hasSecondaryHighlightedDropdownOptions(): boolean {
    return (
      this.visibleDropdownOptions.filter(
        ({ isSecondaryHighlighted }) => isSecondaryHighlighted,
      ).length > 0
    );
  }
}
