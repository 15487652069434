import {
  objectToOptions,
  objectToOptionsWithDisplay,
} from '@app/core/helpers/object-to-options';
import { SelectOption } from '@app/core/models/selector-option';
import { FilterField } from './filter-field';

export class SelectFilterField extends FilterField<any, any> {
  data: SelectOption[];
  inputProperty: string;
  inputDescription: string;
  minSearchCharacters: number;

  getDefaultValue(): any {
    return null;
  }

  getValue(control: any): SelectOption {
    return control;
  }

  setData(data: SelectOption[]) {
    this.data = data;
    return this;
  }

  setEnum(enumeration: any) {
    const data = objectToOptions(enumeration);
    this.data = data;
    return this;
  }

  setEnumWithDisplay(
    enumeration: any,
    displayCallback: (key: number) => string,
  ) {
    const data = objectToOptionsWithDisplay(enumeration, displayCallback);
    this.data = data;
    return this;
  }

  setInputProperty() {
    this.inputProperty = 'value';
    return this;
  }

  setInputDescription(inputDescription: string) {
    this.inputDescription = inputDescription;
    return this;
  }

  isValid(value: any): boolean {
    return true;
  }
}
