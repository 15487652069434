<app-modal title="Unsaved Changes">
  <div class="alert alert-warning" role="alert">
    <h4 class="alert-heading">Warning</h4>
    <p>
      You have unsaved changes. Press Cancel to go back and save these changes,
      or 'Continue' to lose these changes.
    </p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-primary"
      (click)="activeModal.close(true)"
    >
      Continue
    </button>
    <button
      type="button"
      class="btn btn-outline-primary"
      (click)="activeModal.close(false)"
    >
      Cancel
    </button>
  </div>
</app-modal>
