<app-circle-loader [loading]="(apiData | async).isLoading">
  <app-modal [title]="'Export Table'">
    <div class="container">
      <form [formGroup]="form" (ngSubmit)="submit()">
        <div class="row">
          <div class="col">
            <app-number-input
              [labelName]="'Number of rows to export'"
              [parentForm]="form"
              [inputName]="'rowCount'"
              [max]="maxRows"
            >
            </app-number-input>
            <app-info-label
              [message]="'Total rows: ' + (apiData | async).total"
            ></app-info-label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button class="btn btn-primary" type="submit" (click)="submit()">
              Export
            </button>
          </div>
        </div>
      </form>
    </div>
  </app-modal>
</app-circle-loader>
