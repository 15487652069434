import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { AuthDirective } from './auth-base.directive';

@Directive({
  selector: '[appAuthIf]',
})
export class AuthIfDirective extends AuthDirective {
  @Input()
  set appAuthIf(val: string) {
    if (this.hasPermission(val, this.authenticationService)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authenticationService: AuthenticationService,
  ) {
    super();
  }
}
