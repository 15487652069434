import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { getPhoneNumberRegions } from '@app/shared/helpers/phone-number-helper';
import { SelectOption } from '@app/core/models/selector-option';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-international-phone-number-input',
  templateUrl: './international-phone-number-input.component.html',
  styleUrls: ['./international-phone-number-input.component.scss'],
})
export class InternationalPhoneNumberInputComponent implements OnInit {
  regions = getPhoneNumberRegions().map(
    (region) =>
      ({
        value: region.code,
        description: region.description,
      }) as SelectOption,
  );

  @Input()
  parentForm: UntypedFormGroup;
  @Input()
  hideLabel: boolean;
  @Input()
  flagAsRequired: boolean;
  @Input()
  labelName: string;
  @Input()
  numberControl: string;
  @Input()
  countryCodeControl: string;
  @Input()
  appendContactButton: boolean;
  @Input()
  contactIconButton: string;
  @Output()
  contactButtonClick = new EventEmitter<any>();
  @Input()
  showSuccess: boolean;
  constructor() {}

  ngOnInit(): void {}

  onContactIconButtonClick() {
    this.contactButtonClick.emit();
  }

  getPrefix() {
    return this.parentForm.controls[this.countryCodeControl].value;
  }
}
