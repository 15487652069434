import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pascalCaseStringSplit',
})
export class PascalCaseStringSplit implements PipeTransform {
  transform(value: string) {
    if (typeof value !== 'string') {
      return value;
    }

    return value.split(/(?=[A-Z])/).join(' ');
  }
}
