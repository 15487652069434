import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Button } from '../interfaces/button';
import { DropDown } from '../interfaces/dropdown';

@Component({
  selector: 'app-button-panel',
  templateUrl: './button-panel.component.html',
  styleUrls: ['./button-panel.component.scss'],
})
export class ButtonPanelComponent {
  @Input()
  buttons: (Button | DropDown)[];

  @Input()
  buttonStyle = 'btn-secondary';

  @Output()
  buttonClicked = new EventEmitter<string>();

  isButton(object: any): object is Button {
    return object.type === 'button';
  }

  isDropDown(object: any): object is DropDown {
    return object.type === 'dropdown';
  }

  onButtonClicked(button: Button) {
    this.buttonClicked.emit(button.id);
    button.fn();
  }
}
