import { FilterField } from './filter-field';

export class NumberFilterField extends FilterField<number, number> {
  getDefaultValue(): number {
    return 0;
  }

  parseValue(value: any): number {
    return +value;
  }

  getValue(value: any): number {
    return value ? Number(value) : null;
  }

  isValid(value: string): boolean {
    return true;
  }
}
