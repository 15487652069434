import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayAsCurrency',
})
export class DisplayAsCurrencyPipe implements PipeTransform {
  currencyPrefix = 'R';
  readonly thousandSeparatorRegex = /\B(?=(\d{3})+(?!\d))/g;
  readonly thousandsSeparator = ' ';
  transform(value: number | string): string {
    const currency = +value;
    return value
      ? `${this.currencyPrefix} ${currency
          .toFixed(2)
          .replace(this.thousandSeparatorRegex, this.thousandsSeparator)}`
      : '-';
  }
}
