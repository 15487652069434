<div [class.form-group]="!hideLabel">
  <label
    [class.success]="showSuccess"
    *ngIf="!hideLabel"
    [for]="inputName"
    class="input-label"
  >
    {{ labelName }} <span *ngIf="flagAsRequired">*</span>
  </label>
  <div class="input-group mb-3">
    <div *ngIf="descriptionPosition === 'before'" class="input-group-prepend">
      <span class="input-group-text">{{ description }}</span>
    </div>
    <input
      appNumberInputRange
      [max]="max"
      [min]="min"
      type="number"
      [formControlName]="inputName"
      class="form-control"
      (focus)="onFocus()"
      (blur)="onBlur()"
    />
    <div *ngIf="descriptionPosition === 'after'" class="input-group-append">
      <span class="input-group-text">{{ description }}</span>
    </div>
    <div
      *ngIf="appendButton"
      class="input-group-append"
      (click)="onIconButtonClick()"
    >
      <span class="btn-icon">
        <app-icon class="icon" [name]="iconButton"></app-icon>
      </span>
    </div>
  </div>
  <app-error-label
    *ngIf="controlInstance.invalid && controlInstance.touched"
    [message]="'This field is invalid'"
  ></app-error-label>
</div>
