export const stringChunker = (
  splitArray: string[],
  chunk: number = 10,
): string[][] => {
  const tempArray: string[][] = [];
  for (let i = 0, j = splitArray.length; i < j; i += chunk) {
    tempArray.push(splitArray.slice(i, i + chunk));
  }
  return tempArray;
};

export const convertToParagraph = (text: string): string => {
  const description = text.split(' ');
  const stringChunks = stringChunker(description);
  let paragraph = '';
  stringChunks.forEach((chunk) => {
    const sentence = chunk.join(' ') + '\r\n';
    paragraph += sentence;
  });
  return paragraph.trim();
};
export const isNumeric = (value: string) => new RegExp('^[0-9]*$').test(value);
