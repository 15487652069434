import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FilterParameter } from '@app/shared/search/interfaces/filter-parameter';
import { takeUntil } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AppConfigService } from '@app/core/services/app-config/app-config.service';
import { CurrencyFilterField } from '@app/shared/filter/inputs/currency.filter-field';
import { SearchRequest } from '../search/interfaces/search-request';
import { AutoUnsubscription } from '../helpers/observable-helpers';
import { DateFilterField } from './inputs/date.filter-field';
import { GenericFilterField } from './inputs/filter-field';
import { GlPeriodFilterField } from './inputs/gl-period.filter-field';
import { NumberFilterField } from './inputs/number.filter-field';
import { SelectFilterField } from './inputs/select.filter-field';
import { TextFilterField } from './inputs/text.filter-field';
import { TypeaheadFilterField } from './inputs/typeahead.filter-field';

export const filterSuffix = 'Filter';
const selectAllFilters = 'selectAll';
const getCheckboxInputName = (inputName: string) =>
  `${inputName}${filterSuffix}`;
const isDateField = (field: GenericFilterField) =>
  field instanceof DateFilterField;
const isGlPeriodField = (field: GenericFilterField) =>
  field instanceof GlPeriodFilterField;
const isNumberField = (field: GenericFilterField) =>
  field instanceof NumberFilterField;
const isTextField = (field: GenericFilterField) =>
  field instanceof TextFilterField;
const isTypeaheadField = (field: GenericFilterField) =>
  field instanceof TypeaheadFilterField;
const isSelectField = (field: GenericFilterField) =>
  field instanceof SelectFilterField;
const isCurrencyField = (field: CurrencyFilterField) =>
  field instanceof CurrencyFilterField;

const createDefaultForValues = (fields: GenericFilterField[]) =>
  fields.reduce(
    (acc, inputField) => ({
      ...acc,
      [inputField.inputName]: inputField.getDefaultValue(),
      [getCheckboxInputName(inputField.inputName)]: false,
    }),
    {
      [selectAllFilters]: false,
    },
  );
const flattenFormValues = (
  inputFields: GenericFilterField[],
  formValues: Record<string, string>,
) => {
  if (inputFields && formValues) {
    return inputFields.reduce(
      (accumulator: any, currentField: GenericFilterField) => {
        accumulator[currentField.inputName] = currentField.getValue(
          formValues[currentField.inputName],
        );

        return accumulator;
      },
      { ...formValues },
    );
  }

  return null;
};

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent extends AutoUnsubscription {
  isValid = true;
  isFormPopulated = false;
  searchForm = this.formBuilder.group(createDefaultForValues([]));
  inputFields: GenericFilterField[];

  environmentDisplayRefresh =
    this.appConfig.config.features.filterModals.refresh;

  @Input()
  set fields(value: GenericFilterField[]) {
    this.inputFields = value ? value : [];
    this.initForm();
  }

  @Input()
  set searchData(value: SearchRequest) {
    this.populateForm(value);
  }

  @Input()
  forceFullWidthInputs = false;

  @Input()
  showRefresh = true;

  @Input()
  showReset = true;

  @Input()
  automaticCheckboxTicking = true;

  @Output()
  search = new EventEmitter<any>();

  @Output()
  resetSearch = new EventEmitter<any>();

  validationSubscription: Subscription;
  selectedFiltersSubscription: Subscription;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private appConfig: AppConfigService,
  ) {
    super();
  }

  initForm() {
    const defaultFormState = createDefaultForValues(this.inputFields);

    this.searchForm = this.formBuilder.group(defaultFormState);

    if (this.validationSubscription) {
      this.validationSubscription.unsubscribe();
    }

    this.validationSubscription = this.searchForm.valueChanges
      .pipe(takeUntil(this.notifier))
      .subscribe(this.validateForm);

    if (this.selectedFiltersSubscription) {
      this.selectedFiltersSubscription.unsubscribe();
    }

    this.selectedFiltersSubscription = this.searchForm.controls[
      selectAllFilters
    ].valueChanges
      .pipe(takeUntil(this.notifier))
      .subscribe((value) => {
        Object.keys(this.searchForm.controls)
          .filter((key: string) => key.includes(filterSuffix))
          .forEach((filterKey) => {
            const fieldKey = filterKey.replace(filterSuffix, '');
            const fieldControl = this.searchForm.get(fieldKey);
            const filterControl = this.searchForm.get(filterKey);

            if (fieldControl && fieldControl.value) {
              filterControl.setValue(!!value);
            } else {
              filterControl.setValue(false);
            }
          });
      });
  }

  populateForm(searchData: SearchRequest) {
    if (searchData == null) {
      this.searchForm.reset();
    }
    const canBePopulated = searchData && !this.isFormPopulated;
    if (canBePopulated) {
      const filterParams = searchData.filterParameters || [];
      const formGroup = filterParams.reduce(
        (acc: any, { columnName, value }: FilterParameter) => {
          const field = this.inputFields.find(
            ({ inputName }) => inputName === columnName,
          );

          if (field && value) {
            acc[columnName] = field.parseValue(value);
            if (this.automaticCheckboxTicking) {
              acc[getCheckboxInputName(columnName)] = true;
            }
          }

          return acc;
        },
        this.searchForm.value,
      );
      this.isFormPopulated = true;
      this.searchForm.patchValue(formGroup);
    }
  }

  refresh() {
    this.initForm();
    this.validateForm(this.searchForm.value);
  }

  doSearch() {
    const searchParams = flattenFormValues(
      this.inputFields,
      this.searchForm.value,
    );

    this.search.emit(searchParams);
  }

  doReset() {
    this.resetSearch.emit();
  }

  validateForm = (value: any) => {
    const selectedKeys = Object.keys(value)
      .filter((key) => key.endsWith(filterSuffix))
      .filter((filterKey) => value[filterKey]);
    const hasFilterEnabled = selectedKeys.some((filterKey) => value[filterKey]);

    const selectedInputKeys = selectedKeys.map((key) =>
      key.replace(filterSuffix, ''),
    );

    const allSelectedFilterInputsHaveValue = selectedInputKeys.every(
      (inputKey) => {
        const selectedField = this.inputFields.find(
          (x) => x.inputName === inputKey,
        );
        return selectedField.isValid(value[selectedField.inputName]);
      },
    );

    this.isValid =
      this.searchForm.valid &&
      hasFilterEnabled &&
      allSelectedFilterInputsHaveValue;
  };

  onInputFocused(inputName: string) {
    const key = `${inputName}${filterSuffix}`;
    this.searchForm.get(key).setValue(true);
  }

  onInputBlurred(inputName: string) {
    const input = this.searchForm.get(`${inputName}`).value;
    const inputIsEmpty = !input;
    if (inputIsEmpty) {
      const key = `${inputName}${filterSuffix}`;
      this.searchForm.get(key).setValue(false);
    }
  }

  isDateField(field: GenericFilterField) {
    return isDateField(field);
  }

  isGlPeriodField(field: GenericFilterField) {
    return isGlPeriodField(field);
  }

  isNumberField(field: GenericFilterField) {
    return isNumberField(field);
  }

  isTextField(field: GenericFilterField) {
    return isTextField(field);
  }

  isTypeaheadField(field: GenericFilterField) {
    return isTypeaheadField(field);
  }

  isSelectField(field: GenericFilterField) {
    return isSelectField(field);
  }

  isCurrencyField(field: GenericFilterField) {
    return isCurrencyField(field);
  }

  getCheckboxInputName(inputName: string) {
    return getCheckboxInputName(inputName);
  }
}
