import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-back',
  templateUrl: './back.component.html',
  styleUrls: ['./back.component.scss'],
})
export class BackComponent {
  @Input()
  title: string;

  @Output()
  back = new EventEmitter();

  backClicked() {
    this.back.emit();
  }
}
