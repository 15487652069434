<div class="form-group">
  <label
    *ngIf="!hideLabel"
    [class.success]="showSuccess"
    [for]="inputName"
    class="input-label"
    >{{ labelName }} <span *ngIf="flagAsRequired">*</span></label
  >
  <div class="input-group mb-3">
    <div *ngIf="prependButton" class="input-group-prepend">
      <span (click)="onIconButtonClick()" class="btn-icon">
        <app-icon [name]="buttonIcon" class="icon"></app-icon>
      </span>
    </div>

    <input
      #d="ngbDatepicker"
      (blur)="onBlur()"
      (focus)="onFocus()"
      [formControlName]="inputName"
      [maxDate]="maxDate"
      [minDate]="minDate"
      [disabled]="!editable"
      class="form-control"
      container="body"
      ngbDatepicker
      placeholder="yyyy-mm-dd"
    />
    <div (click)="editable && d.toggle()" class="input-group-append">
      <span [class.inactive-icon]="!editable" class="btn-icon">
        <app-icon [name]="iconButton" class="icon"></app-icon>
      </span>
    </div>
  </div>
  <app-error-label
    *ngIf="value.invalid && value?.touched"
    [message]="'Invalid ' + labelName"
  ></app-error-label>
</div>
