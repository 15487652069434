import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-reordering-list-display',
  templateUrl: './reordering-list-display.component.html',
  styleUrls: ['./reordering-list-display.component.scss'],
})
export class ReorderingListDisplayComponent implements OnInit {
  @Input()
  showButtons = false;

  @Input()
  data: string[] = [];

  @Output()
  dropEventEmitter = new EventEmitter<CdkDragDrop<string[]>>();

  @Output()
  buttonClicked = new EventEmitter<number>();

  @Input()
  buttonIcon: string;

  constructor() {}

  ngOnInit(): void {}

  drop(event: CdkDragDrop<string[]>) {
    this.dropEventEmitter.emit(event);
  }

  clickButton(index: number) {
    this.buttonClicked.emit(index);
  }

  getHasItems() {
    return this.data.length > 0;
  }
}
