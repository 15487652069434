import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@env/environment';

@Pipe({
  name: 'standardDayOfTheWeek',
})
export class StandardDayOfTheWeekPipe
  extends DatePipe
  implements PipeTransform
{
  public transform(value: Date): any {
    const nullFieldText = '';
    try {
      return super.transform(value, environment.dayOfTheWeekWithTimeFormat);
    } catch (e) {
      return nullFieldText;
    }
  }
}
