<table class="table table-striped" *ngIf="_field && rows">
  <thead>
    <tr>
      <th *ngFor="let column of columns">{{ column.label }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of rows">
      <td
        [class.font-weight-bold]="hasStyling(column, fancyFieldStyles.Bold)"
        *ngFor="let column of row.fields"
      >
        <ng-container *ngIf="column" [ngSwitch]="column?.type">
          <app-text-display
            *ngSwitchCase="'Percentage'"
            [hideLabel]="true"
            [applyFormStyling]="false"
            [value]="(column.value | number: '1.2-2') + '%'"
          ></app-text-display>

          <app-text-display
            *ngSwitchCase="'Currency'"
            [hideLabel]="true"
            [applyFormStyling]="false"
            [value]="column.value | displayAsCurrency"
          ></app-text-display>

          <app-text-display
            *ngSwitchDefault
            [value]="column.value"
            [hideLabel]="true"
            [applyFormStyling]="false"
          ></app-text-display>
        </ng-container>
      </td>
    </tr>
  </tbody>
</table>
