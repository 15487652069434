import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-circle-loader',
  templateUrl: './circle-loader.component.html',
  styleUrls: ['./circle-loader.component.scss'],
})
export class CircleLoaderComponent implements OnInit {
  private _loading = false;

  @Input()
  set loading(value: boolean) {
    this._loading = value;
    if (!this._loading && this.fullHeight) {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 0);
    }
  }

  get loading(): boolean {
    return this._loading;
  }

  @Input()
  error: string;

  get message(): string {
    return this.reason ? this.reason : this.error ? this.error : '';
  }

  @Input() fullHeight: boolean;
  @Input() reason: string = null;
  @Input() overlay: boolean;
  @Input() tableColumnLoader: boolean;

  constructor() {}

  ngOnInit() {}

  getOpacity() {
    const isOverlay = 0.2;
    const isNotOverlay = 0;
    if (this.loading && this.overlay) {
      return isOverlay;
    }
    if (this.loading && !this.overlay) {
      return isNotOverlay;
    }
  }
}
