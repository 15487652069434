import { Authentication } from '@app/authentication/models/authentication';
import { getActionName } from '@app/core/state/state-helpers';
import { createAction, props } from '@ngrx/store';
import { UserDetails } from '../models/user-details';

const actionPrefix = '[Authentication]';

export const initializeAuthentication = createAction(
  getActionName('InitializeAuthentication', actionPrefix),
);

export const logout = createAction(getActionName('Logout', actionPrefix));

export const loginSuccess = createAction(
  getActionName('LoginSuccess', actionPrefix),
  props<{ payload: Authentication }>(),
);

export const loginError = createAction(
  getActionName('LoginError', actionPrefix),
  props<{ payload: string }>(),
);

export const logoutSuccess = createAction(
  getActionName('LogoutSuccess', actionPrefix),
  props<{ payload: Authentication }>(),
);

export const logoutError = createAction(
  getActionName('LogoutError', actionPrefix),
  props<{ payload: string }>(),
);

export const handleLogout = createAction(
  getActionName('HandleLogout', actionPrefix),
);

export const trackUserLogin = createAction(
  getActionName('TrackUserLogin', actionPrefix),
);

export const trackUserLoginSuccess = createAction(
  getActionName('TrackUserLoginSuccess', actionPrefix),
);

export const trackUserLoginError = createAction(
  getActionName('TrackUserLoginError', actionPrefix),
  props<{ payload: string }>(),
);

export const trackUserLogout = createAction(
  getActionName('TrackUserLogout', actionPrefix),
);

export const trackUserLogoutSuccess = createAction(
  getActionName('TrackUserLogoutSuccess', actionPrefix),
);

export const trackUserLogoutError = createAction(
  getActionName('TrackUserLogoutError', actionPrefix),
  props<{ payload: string }>(),
);

export const logoutFinalized = createAction(
  getActionName('LogoutFinalized', actionPrefix),
);

export const loadCurrentUserDetails = createAction(
  getActionName('Load current user details', actionPrefix),
);

export const loadCurrentUserDetailsSuccess = createAction(
  getActionName('Load current user details success', actionPrefix),
  props<{ user: UserDetails }>(),
);

export const loadCurrentUserDetailsFailure = createAction(
  getActionName('Load current user details failure', actionPrefix),
  props<{ payload: string }>(),
);
export const startNotifications = createAction(
  getActionName('Start notifications', actionPrefix),
);
