import { SearchRequest } from '@app/shared/search/interfaces/search-request';
import { PagedQueryParameters } from '../models/paged-query-parameters';

export const maxLimit = 1000;
export const defaultLimit = 50;
export const defaultOffset = 0;
export const top10Limit = 10;

export const createPaginationQuery = (
  searchData: SearchRequest = null,
  limit = defaultLimit,
  offset = defaultOffset,
): PagedQueryParameters => ({ searchData, offset, limit });
