import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { interval } from 'rxjs';
import {
  UpdateModalComponent,
  UpdateModalResult,
} from '../components/update-modal/update-modal.component';
import { errorless } from '../helpers/errorless';
import { AppConfigService } from './app-config/app-config.service';

@Injectable()
export class PwaService {
  updateCheckInterval = this.appConfig.config.serviceWorker.updateCheckTimeout;

  modalRef: NgbModalRef;
  constructor(
    private swUpdate: SwUpdate,
    private modalService: NgbModal,
    private appConfig: AppConfigService,
  ) {
    if (swUpdate.isEnabled) {
      interval(this.updateCheckInterval).subscribe(() =>
        swUpdate.checkForUpdate(),
      );

      this.logUpdates();
    }
  }

  public logUpdates() {
    this.swUpdate.versionUpdates.subscribe((evt) => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(
            `New app version ready for use: ${evt.latestVersion.hash}`,
          );
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(
            `Failed to install app version '${evt.version.hash}': ${evt.error}`,
          );
          break;
      }
    });
  }

  public registerUpdateHandlers() {
    this.registerPromptOnUpdate();
    this.registerPromptOnUnrecoverableState();
  }

  private registerPromptOnUpdate() {
    this.swUpdate.versionUpdates.subscribe(async (event) => {
      if (
        event.type === 'VERSION_READY' ||
        event.type === 'VERSION_INSTALLATION_FAILED'
      ) {
        await this.askUserToUpdate();
      }
    });
  }

  private registerPromptOnUnrecoverableState() {
    this.swUpdate.unrecoverable.subscribe(async (event) =>
      this.askUserToUpdate(),
    );
  }

  private async askUserToUpdate() {
    const shouldDisplayModal = !this.modalRef;
    if (shouldDisplayModal) {
      const modalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false,
        size: 'sm',
      };

      this.modalRef = this.modalService.open(
        UpdateModalComponent,
        modalOptions,
      );
      const userUpdatePromptResult = await errorless(this.modalRef.result);
      if (userUpdatePromptResult === UpdateModalResult.Refresh) {
        this.swUpdate.activateUpdate().then(() => window.location.reload());
      }
    }
  }
}
