import { DOCUMENT } from '@angular/common';
import {
  Directive,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appDrag]',
})
export class DragDirective implements OnInit, OnDestroy {
  parentHtmlElement: HTMLElement;
  controlHtmlElement: HTMLElement;
  currentX = 0;
  currentY = 0;

  subscriptions: Subscription[] = [];

  @Input()
  parentElement: ElementRef;

  @Input()
  reset$: Observable<boolean>;

  constructor(
    private controlElement: ElementRef,
    @Inject(DOCUMENT) private document: any,
  ) {}

  ngOnInit(): void {
    this.parentHtmlElement = this.parentElement.nativeElement as HTMLElement;
    this.controlHtmlElement = this.controlElement.nativeElement as HTMLElement;
    this.initDrag();
    this.subscribeToReset();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s?.unsubscribe());
  }

  subscribeToReset() {
    if (this.reset$) {
      const subscription = this.reset$.subscribe((_) => {
        this.currentX = 0;
        this.currentY = 0;

        this.parentElement.nativeElement.style.transform =
          'translate3d(0px, 0px, 0)';
      });
      this.subscriptions.push(subscription);
    }
  }

  initDrag(): void {
    const dragStart$ = fromEvent<MouseEvent>(
      this.controlHtmlElement,
      'mousedown',
    );
    const dragEnd$ = fromEvent<MouseEvent>(this.document, 'mouseup');
    const drag$ = fromEvent<MouseEvent>(this.document, 'mousemove').pipe(
      takeUntil(dragEnd$),
    );

    let initialX: number;
    let initialY: number;

    let dragSub: Subscription;

    const dragStartSub = dragStart$.subscribe((event: MouseEvent) => {
      initialX = event.clientX - this.currentX;
      initialY = event.clientY - this.currentY;
      this.parentHtmlElement.classList.add('dragging');
      this.parentHtmlElement.style.display = 'block';

      dragSub = drag$.subscribe((event: MouseEvent) => {
        event.preventDefault();

        this.currentX = event.clientX - initialX;
        this.currentY = event.clientY - initialY;
        this.parentHtmlElement.style.transform =
          'translate3d(' + this.currentX + 'px, ' + this.currentY + 'px, 0)';
      });
    });

    const dragEndSub = dragEnd$.subscribe(() => {
      initialX = this.currentX;
      initialY = this.currentY;

      this.parentHtmlElement.classList.remove('dragging');
      if (dragSub) {
        dragSub.unsubscribe();
      }
    });

    this.subscriptions.push.apply(this.subscriptions, [
      dragStartSub,
      dragSub,
      dragEndSub,
    ]);
  }
}
