import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { AuthenticationModule } from '@app/authentication/authentication.module';
import { SharedModule } from '@app/shared/shared.module';
import { MsalInterceptor } from '@azure/msal-angular';
import { MarkdownModule } from 'ngx-markdown';
import { LoaderComponent } from './components/loader/loader.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { PendingChangesModalComponent } from './components/pending-changes-modal/pending-changes-modal.component';
import { UpdateModalComponent } from './components/update-modal/update-modal.component';
import { RouteReusableStrategy } from './helpers/route-reusable-strategy';
import { QueryParameterSerializer } from './http/query-parameter-serializer/query-parameter-serializer';
import { AppInsightsService } from './services/app-insights.service';
import { ErrorHandlerService } from './services/error-handler.service';
import { LocalStorageService } from './services/local-storage.service';
import { PwaService } from './services/pwa.service';
import { ToastService } from './services/toast.service';
import { AppConfigService } from './services/app-config/app-config.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    SharedModule,
    AuthenticationModule,
    MarkdownModule,
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy,
    },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    MsalInterceptor,
    LocalStorageService,
    ToastService,
    QueryParameterSerializer,
    AppInsightsService,
    PwaService,
    AppInsightsService,
    AppConfigService,
  ],
  declarations: [
    NotFoundComponent,
    LoaderComponent,
    PendingChangesModalComponent,
    UpdateModalComponent,
  ],
  exports: [LoaderComponent],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(
        `${parentModule} has already been loaded. Import Core module in the AppModule only.`,
      );
    }
  }
}
