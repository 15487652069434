<label class="input-label">{{ label }}</label>
<form [formGroup]="inputForm">
  <app-select-input
    [parentForm]="inputForm"
    [inputName]="'option'"
    [labelName]="label"
    [selectOptions]="selectionOptions"
    [canCopy]="canCopy"
    [copyLabel]="copyLabel"
    (selectedValueChanged)="onSelectedValueChanged($event)"
  ></app-select-input>
  <div class="mt-2">
    <app-info-label
      *ngIf="description"
      [message]="description"
    ></app-info-label>
  </div>
</form>
