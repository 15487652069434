<div class="form-group">
  <label
    *ngIf="appendLabel"
    class="input-label select-label"
  >{{ labelName }} <span *ngIf="flagAsRequired">*</span>
  </label>
  <div>
    <ng-content></ng-content>
  </div>
  <div *ngIf="showInputText" class="form-group">
    <form (submit)="addInternalItem()" [formGroup]="internalForm">
      <div class="input-group mb-3">
        <input
          #newItemInput
          [formControlName]="internalControlName"
          class="form-control"
          type="text" />
        <div
          (click)="addInternalItem()"
          class="input-group-append"
        >
        <span class="btn-icon">
          <app-icon [name]="'plus-circle'" class="icon"></app-icon>
        </span>
        </div>
      </div>
      <app-error-label
        *ngIf="internalValue.invalid && internalValue.touched"
        [message]="'Invalid ' + labelName || ''"
      ></app-error-label>
    </form>
  </div>


  <app-multi-item-display
    (buttonClicked)="removeItem($event)"
    [buttonIcon]="'cross-circle'"
    [data]="data"
    [showButton]="true"
  >
  </app-multi-item-display>

</div>
