<div class="container-fluid filter-wrapper">
  <div class="row">
    <div class="col">
      <form [formGroup]="searchForm" *ngIf="searchForm">
        <div class="row">
          <div
            class="col-lg-12"
            [class.col-12]="forceFullWidthInputs"
            [class.col-xl-6]="!forceFullWidthInputs"
            *ngFor="let field of inputFields">
            <div class="row">
              <div class="col-12">
                <app-checkbox-input
                  class="float-start"
                  [inputName]="
                    getCheckboxInputName(field.inputName)
                  "></app-checkbox-input>
                <app-date-input
                  *ngIf="isDateField(field)"
                  (keyup.enter)="doSearch()"
                  [parentForm]="searchForm"
                  [inputName]="field.inputName"
                  [labelName]="field.inputLabel"
                  [iconButton]="'calender'"
                  (inputFocused)="onInputFocused(field.inputName)"
                  (inputBlurred)="
                    onInputBlurred(field.inputName)
                  "></app-date-input>
                <app-gl-period-input
                  *ngIf="isGlPeriodField(field)"
                  [formControlName]="field.inputName"
                  (keyup.enter)="doSearch()"
                  [labelName]="field.inputLabel"
                  (inputFocused)="onInputFocused(field.inputName)"
                  (inputBlurred)="
                    onInputBlurred(field.inputName)
                  "></app-gl-period-input>
                <app-number-input
                  *ngIf="isNumberField(field)"
                  (keyup.enter)="doSearch()"
                  [parentForm]="searchForm"
                  [inputName]="field.inputName"
                  [labelName]="field.inputLabel"
                  (inputFocused)="onInputFocused(field.inputName)"
                  (inputBlurred)="onInputBlurred(field.inputName)"
                  [hideLabel]="false"></app-number-input>
                <app-text-input
                  *ngIf="isTextField(field)"
                  (keyup.enter)="doSearch()"
                  [parentForm]="searchForm"
                  [inputName]="field.inputName"
                  [labelName]="field.inputLabel"
                  (inputFocused)="onInputFocused(field.inputName)"
                  (inputBlurred)="onInputBlurred(field.inputName)">
                </app-text-input>
                <app-typeahead-input
                  *ngIf="isTypeaheadField(field)"
                  [parentForm]="searchForm"
                  [inputName]="field.inputName"
                  [labelName]="field.inputLabel"
                  [data]="$any(field).data"
                  [editable]="false"
                  [inputProperty]="$any(field).inputProperty"
                  [inputDescription]="$any(field).inputDescription"
                  [showDescriptionOnResult]="
                    $any(field).showDescriptionOnResult
                  "
                  [showDescriptionOnValue]="$any(field).showDescriptionOnValue"
                  [showInputOnResult]="$any(field).showInputOnResult"
                  [showInputOnValue]="$any(field).showInputOnValue"
                  [showDescription]="$any(field).showDescription"
                  (inputFocused)="onInputFocused(field.inputName)"
                  (inputBlurred)="onInputBlurred(field.inputName)"
                  (itemSelected)="onInputFocused(field.inputName)">
                </app-typeahead-input>
                <app-select-input
                  *ngIf="isSelectField(field)"
                  [parentForm]="searchForm"
                  [appendLabel]="true"
                  [inputName]="field.inputName"
                  [labelName]="field.inputLabel"
                  [selectOptions]="$any(field).data"
                  (inputFocused)="onInputFocused(field.inputName)"
                  (inputBlurred)="onInputBlurred(field.inputName)"
                  (selectedValueChanged)="onInputFocused(field.inputName)">
                </app-select-input>
                <app-currency-input
                  *ngIf="isCurrencyField(field)"
                  [parentForm]="searchForm"
                  [inputName]="field.inputName"
                  [labelName]="field.inputLabel"
                  (inputFocused)="onInputFocused(field.inputName)"
                  (inputBlurred)="
                    onInputBlurred(field.inputName)
                  "></app-currency-input>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="flexSpacing"></div>
  <div class="row">
    <div class="col">
      <div class="text-left left-checkbox">
        <form [formGroup]="searchForm">
          <app-checkbox-input
            class="ms-3"
            [inputName]="'selectAll'"
            [labelName]="'Select all populated fields'"></app-checkbox-input>
        </form>
      </div>
    </div>
    <div class="text-right action-buttons">
      <span class="ms-auto">
        <button
          type="button"
          title="Refresh all inputs on this search"
          *ngIf="showRefresh && environmentDisplayRefresh"
          class="btn btn-outline-primary me-1"
          (click)="refresh()">
          Refresh
        </button>
        <button
          type="button"
          title="Clear the search results table"
          *ngIf="showReset"
          class="btn btn-outline-primary me-1"
          (click)="doReset()">
          Clear
        </button>
        <button
          type="submit"
          (click)="doSearch()"
          [disabled]="!isValid"
          class="btn btn-primary">
          Search
        </button>
      </span>
    </div>
  </div>
</div>
