<div class="component">
  <div class="component-header">
    <nav class="navbar">
      <span>Search Results</span>
    </nav>
  </div>
  <div class="component-body">
    <app-table
      [isLoading]="isLoading"
      [rowHeight]="50"
      [headerHeight]="35"
      [rows]="rows"
      [columns]="columns"
      (selected)="selectedItem($event)"
      [shrinkHeight]="true"
      [filteredItems]="searchData"
      [stretchColumns]="false"
      (sort)="sortColumn($event)"
    ></app-table>
  </div>
</div>
