import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SelectOption } from '@app/core/models/selector-option';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-overview-section-advanced-select',
  templateUrl: './overview-section-advanced-select.component.html',
  styleUrls: ['./overview-section-advanced-select.component.scss'],
})
export class OverviewSectionAdvancedSelectComponent {
  private _value: any;

  get value(): any {
    return this._value;
  }

  @Input() set value(value: any) {
    this._value = value;

    if (value) {
      this.inputForm.setValue({
        option: value,
      });
    }
  }

  @Input()
  label: string;

  @Input()
  description: string;

  @Input()
  selectOptions: Observable<SelectOption[]>;

  @Input()
  noValueText: string;

  @Output()
  selectedValueChanged = new EventEmitter<string | number>();

  onSelectedValueChanged(value: string | number) {
    this.selectedValueChanged.emit(value);
  }

  inputForm: UntypedFormGroup;

  constructor() {
    this.inputForm = new UntypedFormGroup({
      option: new UntypedFormControl(''),
    });
  }
}
