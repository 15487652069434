import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-multi-item-display',
  templateUrl: './multi-item-display.component.html',
  styleUrls: ['./multi-item-display.component.scss'],
})
export class MultiItemDisplayComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  @Input()
  labelName: string;

  @Input()
  showLabel: boolean;

  @Input()
  showButton: boolean;

  @Input()
  data: string[];

  @Input()
  buttonIcon: string;

  @Output()
  buttonClicked = new EventEmitter<number>();

  clickButton(index: number) {
    this.buttonClicked.emit(index);
  }
}
