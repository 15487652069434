export enum PermissionsEnum {
  SaveLetters = 'LETTERS.LETTER.SAVE',
  GenerateLetters = 'LETTERS.REPORT.GENERATE',
  ReallocateImage = 'REGISTRY.IMAGE.ALLOCATE',
  GetTeam = 'TEAM.GET',
  TeamAssignedImages = 'TEAM.IMAGES.IMAGES_ASSIGNED',
  TeamIndexedImages = 'TEAM.IMAGES.IMAGES_INDEXED',
  TeamClaimsFollowUp = 'TEAM.CLAIMS.FOLLOW_UPS_DUE',
  TeamAllocatedClaims = 'TEAM.CLAIMS.ALLOCATED_CLAIMS',
  GetUserJobLevel = 'USER.JOB_LEVEL.GET',
  TableConvert = 'USER.TABLE.CONVERT',
  ViewTeamLeave = 'REGISTRY.TEAM.LEAVE.VIEW',
  RequestFlag = 'CLAIMS_ADMINISTRATION.SERVICEPROVIDER.REQUEST_FLAG',
  ViewComplexReason = 'CLAIMS_ADMINISTRATION.SERVICEPROVIDER.VIEW_COMPLEX_REASON',
}
