import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
@Component({
  selector: 'app-overview-section-text-display',
  templateUrl: './overview-section-text-display.component.html',
  styleUrls: ['./overview-section-text-display.component.scss'],
})
export class OverviewSectionTextDisplayComponent {
  @Input()
  label: string;

  @Input()
  value: any;

  @Input()
  showSuccess = false;

  @Input()
  showWarning = false;

  @Input()
  showError = false;

  @Input()
  description: string;

  @Input()
  hasToolTip? = false;

  @Input()
  tooltip: string | TemplateRef<any> = null;

  @Input()
  descriptionTooltip: string | TemplateRef<any> = null;

  @Input()
  hasDisplayAction = false;

  @Input()
  actionDescription = '';

  @Input()
  canCopy = false;

  @Output()
  actionClicked = new EventEmitter();

  onActionClicked() {
    this.actionClicked.emit();
  }
}
