<app-circle-loader [loading]="isLoading">
  <app-card
    [title]="title"
    [actionButtons]="actionButtons"
    [dropdownOptions]="dropdownButtons"
    [buttons]="buttons"
    [informationLabels]="informationLabels"
    [buttonsWithLabels]="buttonsWithLabels"
    [showHeaderOverdue]="showHeaderOverdue"
    [showReopenRequestHeader]="showReopenRequestHeader"
    [showWarningHeader]="showWarningHeader"
    [showErrorHeader]="showErrorHeader">
    <ng-content></ng-content>
  </app-card>
</app-circle-loader>
