import { Component, Input, OnInit } from '@angular/core';
import { TableAction } from '../dashboard-table/models/table-action';

@Component({
  selector: 'app-action-dropdown',
  templateUrl: './action-dropdown.component.html',
  styleUrls: ['./action-dropdown.component.scss'],
})
export class ActionDropdownComponent implements OnInit {
  constructor() {}

  @Input() actions: TableAction<any>[] = [];
  @Input() row: any;
  @Input() showText = false;
  @Input() defaultText: string;

  hasVisibleItem(): boolean {
    return (
      this.actions.filter((action) => action.isVisible(this.row)).length > 0
    );
  }

  ngOnInit(): void {}
}
