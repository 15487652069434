import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ControlContainer,
  UntypedFormGroup,
  FormGroupDirective,
} from '@angular/forms';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { DateModelAdapter } from './date-model-adapter';
import { DateParserFormatter } from './date-parser-formatter';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: DateModelAdapter },
    { provide: NgbDateParserFormatter, useClass: DateParserFormatter },
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class DateInputComponent {
  @Input()
  parentForm: UntypedFormGroup;

  @Input()
  inputName: string;

  @Input()
  labelName: string;

  @Input()
  iconButton: string;

  _minMonth = 1;
  @Input()
  set minMonth(value: number) {
    this._minMonth = value;
    this.updateMinDate();
  }
  _minDay = 1;
  @Input()
  set minDay(value: number) {
    this._minDay = value;
    this.updateMinDate();
  }
  _minYear = new Date().getFullYear() - 200;
  @Input()
  set minYear(value: number) {
    this._minYear = value;
    this.updateMinDate();
  }

  _maxMonth = 1;
  @Input()
  set maxMonth(value: number) {
    this._maxMonth = value;
    this.updateMaxDate();
  }
  _maxDay = 1;
  @Input()
  set maxDay(value: number) {
    this._maxDay = value;
    this.updateMaxDate();
  }
  _maxYear = new Date().getFullYear() + 30;
  @Input()
  set maxYear(value: number) {
    this._maxYear = value;
    this.updateMaxDate();
  }

  @Input()
  editable = true;

  @Input()
  flagAsRequired: boolean;

  @Input()
  showSuccess = false;

  @Input()
  hideLabel: boolean;

  @Input()
  prependButton: boolean;

  @Input()
  buttonIcon: string;

  @Output()
  inputFocused = new EventEmitter<string>();

  @Output()
  inputBlurred = new EventEmitter<string>();

  @Output()
  iconButtonClick = new EventEmitter();

  minDate: NgbDateStruct = {
    year: this._minYear,
    month: this._minMonth,
    day: this._minDay,
  };
  maxDate: NgbDateStruct = {
    year: this._maxYear,
    month: this._maxMonth,
    day: this._maxDay,
  };

  get value() {
    return this.parentForm.get(this.inputName);
  }

  onFocus() {
    this.inputFocused.emit(this.inputName);
  }

  onBlur() {
    this.inputBlurred.emit(this.inputName);
  }

  private updateMinDate() {
    this.minDate = {
      year: this._minYear,
      month: this._minMonth,
      day: this._minDay,
    };
  }

  private updateMaxDate() {
    this.maxDate = {
      year: this._maxYear,
      month: this._maxMonth,
      day: this._maxDay,
    };
  }

  onIconButtonClick() {
    this.iconButtonClick.emit();
  }
}
