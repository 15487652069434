import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import { Observable, of } from 'rxjs';
import { filter, takeWhile } from 'rxjs/operators';
import { EventMessage, EventType } from '@azure/msal-browser';
import { AppConfigService } from '@app/core/services/app-config/app-config.service';
import { Authentication } from '../models/authentication';
import { AuthenticationFacade } from '../state/authentication.facade';
import { UserDetails } from '../models/user-details';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService implements OnDestroy {
  currentUser: UserDetails;
  alive = true;
  authHandled = false;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private broadcastService: MsalBroadcastService,
    private authFacade: AuthenticationFacade,
    private appConfig: AppConfigService,
  ) {}

  ngOnDestroy(): void {
    this.alive = false;
  }

  checkIsLoggedInToValidAccount() {
    const allAccounts = this.msalService.instance.getAllAccounts();

    if (allAccounts?.length > 0) {
      this.msalService.instance.setActiveAccount(allAccounts[0]);
      this.loginSuccess();
    }
  }

  registerBroadcastService() {
    this.authFacade.initializeAuthentication();

    this.broadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.HANDLE_REDIRECT_END,
        ),
      )
      .subscribe((result: EventMessage) => {
        if (this.appConfig.config.msal.logging) {
          if (result?.error?.message) {
            console.error('Redirect Error: ', result.error.message);
            return;
          }
          console.log('Redirect Success: ', result.payload);
        }
      });
  }

  subscribeToUserDetails() {
    this.authFacade.user$
      .pipe(takeWhile(() => this.alive))
      .subscribe((user: UserDetails) => {
        this.currentUser = user;
      });
  }

  loginSuccess() {
    if (!this.authHandled) {
      this.authHandled = true;

      const auth = new Authentication();
      auth.isAuthenticated = true;
      this.authFacade.loginSuccess(auth);
    }
  }

  logout(): Observable<Authentication> {
    this.msalService.logout();
    return of(null);
  }

  getPermissions(): string[] {
    return this.currentUser?.userPermissions.map(
      (permission) => permission.name,
    );
  }

  hasPermission(permission: string): boolean {
    const permissions = this.getPermissions();
    const hasPermissions = permissions?.includes(permission);
    return hasPermissions;
  }

  getJobTitle(): string {
    return this.currentUser?.jobTitle;
  }

  getUsername(): string {
    return this.currentUser?.username;
  }

  getUserId(): number {
    return this.currentUser?.userId;
  }

  getCurrentGeneralLedgerPeriod(): number {
    return this.currentUser?.currentGeneralLedgerPeriod;
  }

  getUserRegion(): number {
    return this.currentUser?.regionNumber;
  }
}
