<label [class.success]="showSuccess" *ngIf="appendLabel" class="input-label"
  >{{ _labelName }}<span *ngIf="flagAsRequired">*</span>
</label>
<br *ngIf="labelOrientation === 'vertical'" />
<div class="checkbox-container" *ngFor="let item of values">
  <label
    *ngIf="labelPosition === 'before' && appendLabel"
    class="input-label label-before"
    >{{ item.label }}</label
  >
  <input
    type="radio"
    [name]="inputName"
    [id]="inputName + item.value"
    [formControlName]="inputName"
    class="custom-control-input"
    [value]="item.value" />
  <label
    *ngIf="labelPosition === 'after' && appendLabel"
    class="input-label label-after"
    >{{ item.label }}</label
  >
  <br *ngIf="orientation === 'vertical'" />
</div>
