import { Component, Input, ViewChild } from '@angular/core';
import { ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';
import { Button } from '../interfaces/button';
import { DropDown } from '../interfaces/dropdown';

export interface ContextMenuClickEvent {
  item: any;
  button: Button;
}

@Component({
  selector: 'app-context-menu-wrapper',
  templateUrl: './context-menu-wrapper.component.html',
  styleUrls: ['./context-menu-wrapper.component.scss'],
})
export class ContextMenuWrapperComponent {
  @ViewChild('contextMenu')
  contextMenu: ContextMenuComponent<any>;

  @Input() menuItems: (Button | DropDown)[] = [];

  getActionAsDropdown(action: any) {
    return action as DropDown;
  }
}
