import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  UntypedFormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { IconSizeEnum } from '@app/shared/models/icon-size-enum';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class TextInputComponent {
  @Input()
  parentForm: UntypedFormGroup;

  @Input()
  inputName: string;

  @Input()
  labelName: string;

  @Input()
  appendButton: boolean;

  @Input()
  hideLabel: boolean;

  @Input()
  iconButton: string;

  @Input()
  description: string;

  @Input()
  tooltip: string | TemplateRef<any>;

  @Input()
  flagAsRequired: boolean;

  @Input()
  placeholder = '';

  @Input()
  editable = true;

  @Input()
  isCancellable = false;

  @Input()
  showSuccess: boolean;

  @Output()
  iconButtonClick = new EventEmitter();

  @Output()
  clearClicked = new EventEmitter();

  @Output()
  inputFocused = new EventEmitter<string>();

  @Output()
  inputBlurred = new EventEmitter<string>();

  get value(): AbstractControl {
    return this.parentForm.get(this.inputName);
  }

  @Input()
  iconSize = IconSizeEnum.Large;

  @Input()
  canCopy: boolean;

  _copyLabel: string;

  @Input()
  set copyLabel(value: string) {
    this._copyLabel = value;
  }

  get copyLabel() {
    if (this._copyLabel) {
      return this._copyLabel;
    }
    return this.labelName;
  }

  getCopyItem(): string {
    const formValue = this.parentForm.get(this.inputName).value;

    return formValue;
  }

  onIconButtonClick() {
    this.iconButtonClick.emit();
  }

  onFocus() {
    this.inputFocused.emit(this.inputName);
  }

  onBlur() {
    this.inputBlurred.emit(this.inputName);
  }

  clear() {
    this.clearClicked.emit();
  }
}
