import {
  ComplexSearchRequest,
  SearchRequest,
} from '@app/shared/search/interfaces/search-request';
import { SortPropDir } from '@swimlane/ngx-datatable';
import {
  defaultLimit,
  defaultOffset,
} from '@app/shared/helpers/pagination-helpers';
import { ApiData } from './api-data';

export interface PagedApiData<T> extends ApiData<T[]> {
  limit: number;
  offset: number;
  total: number;
  totalPages: number;
  currentPage: number;
  sort: SortPropDir;
  searchData: SearchRequest;
  complexSearchData: ComplexSearchRequest;
}

export const initialPagedData: PagedApiData<any> = {
  data: [],
  isLoading: false,
  limit: defaultLimit,
  offset: defaultOffset,
  error: null,
  total: 0,
  totalPages: 0,
  currentPage: 0,
  sort: null,
  searchData: null,
  complexSearchData: null,
};

export interface PagedResponse<T> {
  currentPage: number;
  data: T[];
  total: number;
  totalPages: number;
}
