<div class="form-group">
  <label [class.success]="showSuccess&&showLabelSuccess" *ngIf="!hideLabel" [for]="inputName" class="input-label"
    >{{ labelName }} <span *ngIf="flagAsRequired">*</span>
  </label>
  <div class="input-group mb-3">
    <input
      appPostalCode
      type="text"
      [disabled]="!editable"
      [formControlName]="inputName"
      class="form-control"
      (focus)="onFocus()"
      (blur)="onBlur()"
    />
    <div
      *ngIf="appendButton"
      class="input-group-append"
      (click)="onIconButtonClick()"
    >
      <span class="btn-icon" [class.inactive-icon]="!editable">
        <app-icon class="icon" [name]="iconButton"></app-icon>
      </span>
    </div>
  </div>
  <app-error-label
    *ngIf="value.invalid && value.touched"
    [message]="'Please enter a valid postal code'"
  ></app-error-label>
  <app-info-label *ngIf="description" [message]="description"></app-info-label>
</div>
