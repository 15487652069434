import { Action } from '@ngrx/store';
import { curry, flip } from 'ramda';
import { pipe } from 'rxjs';

export const pipeline = pipe as any; // alias needed because TS cannot deal
const curryFlip = pipeline(curry, flip);

type ReducerType<T> = (s: T, a: Action) => T;
type AppliedReducer<T> = (s: T) => T;
type CurriedReducer<T> = (a: Action) => AppliedReducer<T>;

export const applyAction = <T>(reducerFn: ReducerType<T>, action: Action) =>
  pipeline(curryFlip, (fn: CurriedReducer<T>) => fn(action))(reducerFn);

export const getActionName = (action: string, actionPrefix: string) =>
  `${actionPrefix} ${action}`;
