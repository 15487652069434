<div [class.form-group]="!hideLabel">
  <label
    [class.success]="showSuccess"
    *ngIf="!hideLabel"
    [for]="inputName"
    class="input-label"
    >{{ labelName }} <span *ngIf="flagAsRequired">*</span>
  </label>
  <div class="input-group mb-3">
    <input
      type="text"
      [disabled]="!editable"
      [formControlName]="inputName"
      class="form-control"
      [placeholder]="placeholder"
      (focus)="onFocus()"
      (blur)="onBlur()"
    />
    <app-copy-button
      *ngIf="canCopy"
      [copyItem]="getCopyItem()"
      [label]="copyLabel"
      [iconSize]="iconSize"
    >
    </app-copy-button>
    <div *ngIf="appendButton" class="input-group-append">
      <ng-container *ngIf="isCancellable; else iconButtonTemplate">
        <span
          (click)="onIconButtonClick()"
          class="btn-icon"
          *ngIf="!value.value"
          [class.inactive-icon]="!editable"
        >
          <app-icon class="icon" [name]="iconButton"></app-icon>
        </span>
        <span
          (click)="clear()"
          class="btn-icon"
          *ngIf="value.value && editable"
          [class.inactive-icon]="!editable"
        >
          <app-icon
            class="icon icon-btn fa-edit ms-1"
            name="cross-circle"
          ></app-icon>
        </span>
      </ng-container>

      <ng-template #iconButtonTemplate>
        <span
          (click)="onIconButtonClick()"
          class="btn-icon"
          [class.inactive-icon]="!editable"
        >
          <app-icon class="icon" [name]="iconButton"></app-icon>
        </span>
      </ng-template>
    </div>
  </div>

  <app-error-label
    *ngIf="value.invalid && value.touched"
    [message]="'Invalid ' + labelName || ''"
  ></app-error-label>

  <ng-container *ngIf="description">
    <app-info-label
      *ngIf="!!tooltip; else noTooltip"
      [message]="description"
      [tooltip]="tooltip"
    ></app-info-label>

    <ng-template #noTooltip>
      <app-info-label [message]="description"></app-info-label>
    </ng-template>
  </ng-container>
</div>
