<div class="custom-control custom-checkbox mb-2">
  <input
    [formControlName]="inputName"
    [id]="inputName"
    [name]="inputName"
    class="custom-control-input"
    type="checkbox" />
  <label
    *ngIf="!hideLabel"
    [for]="inputName"
    [ngbTooltip]="tooltip"
    class="custom-control-label input-label"
    >{{ labelName }}</label
  >
</div>
