<div [ngClass]="{ 'display-full-height': fullHeight && (loading || error) }">
  <div
    class="loader-container"
    *ngIf="loading"
    [ngStyle]="{ opacity: loading ? 1 : 0 }">
    <div>
      <div
        *ngIf="loading"
        [ngClass]="tableColumnLoader ? 'table-column-loader' : 'loader'"></div>
      <label>{{ reason }}</label>
    </div>
  </div>
  <div class="col" *ngIf="!loading && error">
    <h5>An Error has Occurred</h5>
    <label>Message: "{{ error }}"</label>
  </div>
  <div
    *ngIf="!loading && !error"
    class="loader-content"
    [ngStyle]="{
      opacity: getOpacity(),
    }">
    <ng-content></ng-content>
  </div>
</div>
