import { ElementRef, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FormDirectiveService {
  private _regexPattern: string;
  private _allowedKeys: string[];

  public get regexPattern(): string {
    return this._regexPattern;
  }
  public set regexPattern(value: string) {
    this._regexPattern = value;
  }

  public get allowedKeys(): string[] {
    return this._allowedKeys;
  }
  public set allowedKeys(value: string[]) {
    this._allowedKeys = value;
  }

  public check(value: string) {
    const regExpString = this.regexPattern;
    return String(value).match(new RegExp(regExpString));
  }

  public checkKeysAndRegex(el: ElementRef, event: KeyboardEvent) {
    if (this.allowedKeys.indexOf(event.key) !== -1) {
      return;
    }

    const current: string = el.nativeElement.value;
    const cursorPos = el.nativeElement.selectionStart;
    const next: string =
      current.slice(0, cursorPos) + event.key + current.slice(cursorPos);
    if (next && !this.check(next)) {
      event.preventDefault();
    }
  }
}
