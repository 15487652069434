<div class="accordion-wrapper">
  <div ngbAccordion>
    <div ngbAccordionItem [collapsed]="!open">
      <h2 ngbAccordionHeader>
        <button ngbAccordionButton>{{ headerTitle }}</button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <ng-content></ng-content>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
