import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noOp',
})
export class NoOpPipe implements PipeTransform {
  transform(value: any) {
    return value;
  }
}
