import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UserDetails } from '../models/user-details';
import * as authenticationActions from './authentication.actions';
import { AuthenticationState } from './authentication.state';
import {
  getAuthentication,
  getIsAuthenticated,
  getIsLoading,
  getUserDetails,
} from './authentication.selectors';

@Injectable()
export class AuthenticationFacade {
  isLoading$: Observable<boolean>;
  isAuthenticated$: Observable<boolean>;
  authentication$: Observable<any>;
  user$: Observable<UserDetails | null>;

  constructor(private store$: Store<AuthenticationState>) {
    this.isLoading$ = this.store$.pipe(select(getIsLoading));
    this.isAuthenticated$ = this.store$.pipe(select(getIsAuthenticated));
    this.authentication$ = this.store$.pipe(select(getAuthentication));
    this.user$ = this.store$.pipe(select(getUserDetails));
  }

  initializeAuthentication() {
    this.store$.dispatch(authenticationActions.initializeAuthentication());
  }

  logout() {
    this.store$.dispatch(authenticationActions.logout());
  }

  loginSuccess(auth: any) {
    this.dispatchActionWithDelay(
      authenticationActions.loginSuccess({ payload: auth }),
    );
  }

  loginError(message: string) {
    this.dispatchActionWithDelay(
      authenticationActions.loginError({ payload: message }),
    );
  }
  /**
   * Dispatch an action on the next Angular lifecycle run through.
   * This is needed because the effects, while registered at root level,
   * are registered after certain actions get fired in this service.
   *
   * This service still needs to be registered at root level because the
   *
   * @azure/msal-angular events need to be registered ASAP so that they can
   * interact with the system correctly.
   *
   * @param action: The action to be dispatched on the next Angular lifecycle
   * run through.
   */
  dispatchActionWithDelay(action: Action) {
    setTimeout(() => {
      this.store$.dispatch(action);
    }, 0);
  }
}
