<div
  ngbDropdown
  class="d-inline-block"
  container="body"
>
  <span
    *ngIf="!showText && hasVisibleItem()"
    class="no-styling action-button"
    ngbDropdownToggle
  ><app-icon [name]="'action-more'"></app-icon
  ></span>
  <button class="btn btn-primary" *ngIf="showText && hasVisibleItem()" ngbDropdownToggle>
    {{ defaultText }}
  </button>
  <div class="ontop" ngbDropdownMenu>
    <ng-container *ngFor="let action of actions">
      <button
        *ngIf="action.isVisible(row)"
        (click)="action.click(row)"
        ngbDropdownItem
        [class.highlighted]="action.isHighlighted"
        [class.highlightedSecondary]="action.isSecondaryHighlighted"
      >
        {{ action.label }}
      </button>
    </ng-container>
  </div>
</div>
