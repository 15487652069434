import {
  ComplexSearchRequest,
  SearchRequest,
  ShortenedComplexSearchRequest,
} from '@app/shared/search/interfaces/search-request';
import { SortPropDir } from '@swimlane/ngx-datatable';
import {
  defaultLimit,
  defaultOffset,
} from '@app/shared/helpers/pagination-helpers';

export interface GetParameters {
  limit: number;
  offset: number;
  sortParams: SortPropDir;
  searchData: SearchRequest;
  useSortBy?: boolean;
}

export interface GetComplexParameters {
  limit: number;
  offset: number;
  sortParams: SortPropDir;
  searchData: ComplexSearchRequest | ShortenedComplexSearchRequest;
  useSortBy?: boolean;
}

export const prepareParameters = ({
  limit = defaultLimit,
  offset = defaultOffset,
  searchData,
  sortParams,
  useSortBy,
}: GetParameters): any => {
  const { pageSize, pageNumber } = convertOffsetToPagination(offset, limit);

  let params: any = {
    pageSize,
    pageNumber,
    ...searchData,
  };

  let sort: any;
  if (sortParams) {
    if (useSortBy) {
      sort = convertToSortByParams([sortParams]);
      params.sortBy = sort;
    } else {
      sort = convertSortPropDirToParams(sortParams);
      params = { ...params, ...sort };
    }
  }

  return params;
};

export const prepareComplexParameters = ({
  limit = defaultLimit,
  offset = defaultOffset,
  searchData,
  sortParams,
  useSortBy,
}: GetComplexParameters): any => {
  const { pageSize, pageNumber } = convertOffsetToPagination(offset, limit);

  let params: any = {
    pageSize,
    pageNumber,
    ...searchData,
  };

  let sort: any;
  if (sortParams) {
    if (useSortBy) {
      sort = convertToSortByParams([sortParams]);
      params.sortBy = sort;
    } else {
      sort = convertSortPropDirToParams(sortParams);
      params = { ...params, ...sort };
    }
  }

  return params;
};

export const convertOffsetToPagination = (
  offset: number,
  limit: number,
): { pageSize: number; pageNumber: number } => {
  if (offset < 0 || limit <= 0 || isNaN(offset) || isNaN(limit)) {
    throw new Error(
      'Offset and limit need to be valid to calculate pagination.',
    );
  }

  return {
    pageSize: limit,
    pageNumber: offset > 0 ? offset / limit : 1,
  };
};

export const convertToSortByParams = (sortBy: SortPropDir[]) =>
  sortBy.map(({ dir, prop }) => ({
    columnName: prop,
    direction: dir === 'asc' ? 'Ascending' : 'Descending',
  }));

// FIXME: To be deprecated
/**
 * @deprecated Only supports single column sorting. Use `convertToSortByParams` instead
 */
export const convertSortPropDirToParams = (sortData: SortPropDir): any => ({
  sortColumn: sortData.prop,
  sortDirection: sortData.dir === 'asc' ? 'Ascending' : 'Descending',
});
