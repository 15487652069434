import { Injectable } from '@angular/core';
import {
  ApplicationInsights,
  IDependencyTelemetry,
  ITelemetryItem,
  SeverityLevel,
} from '@microsoft/applicationinsights-web';
import { AppConfigService } from './app-config/app-config.service';

@Injectable()
export class AppInsightsService {
  private appInsights = new ApplicationInsights({
    config: {
      ...this.appConfig.config.applicationInsights,
      enableAutoRouteTracking: true,
    },
  });

  constructor(private appConfig: AppConfigService) {
    this.appInsights.loadAppInsights();
    this.addTelemetryInitializer();
  }

  private addTelemetryInitializer() {
    this.appInsights.addTelemetryInitializer((envelope) => {
      return this.filterDependency(envelope);
    });
  }

  private filterDependency(envelope: ITelemetryItem) {
    const remoteDependencyData = 'RemoteDependencyData';
    const baseType = envelope.baseType;

    if (baseType !== remoteDependencyData) {
      return true;
    }

    const telemetryItem = envelope.baseData;
    if (telemetryItem) {
      const dependencyTelemetry = telemetryItem as IDependencyTelemetry;

      return !(dependencyTelemetry.success === true);
    }
  }

  logTrace(message: string): void {
    this.appInsights.trackTrace({
      message,
      severityLevel: SeverityLevel.Verbose,
    });
  }

  logInformation(message: string): void {
    this.appInsights.trackTrace({
      message,
      severityLevel: SeverityLevel.Information,
    });
  }

  log(message: string, data: any): void {
    this.appInsights.trackTrace(
      {
        message,
        severityLevel: SeverityLevel.Information,
      },
      data,
    );
  }

  logWarning(message: string, data?: any): void {
    this.appInsights.trackTrace(
      {
        message,
        severityLevel: SeverityLevel.Warning,
      },
      data,
    );
  }

  logException(exception: any) {
    this.appInsights.trackException({ exception });
  }

  logError(message: string): void {
    this.appInsights.trackTrace({
      message,
      severityLevel: SeverityLevel.Error,
    });
  }

  logCritical(message: string): void {
    this.appInsights.trackTrace({
      message,
      severityLevel: SeverityLevel.Critical,
    });
  }

  logPageView(name?: string, uri?: string): void {
    this.appInsights.trackPageView({ name, uri });
  }
}
