import { Component, Input } from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  UntypedFormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { RadioButtonField } from '@app/shared/models/radio-button-fields';

@Component({
  selector: 'app-radio-input',
  templateUrl: './radio-input.component.html',
  styleUrls: ['./radio-input.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class RadioInputComponent {
  @Input()
  parentForm: UntypedFormGroup;
  @Input()
  inputName: string;
  @Input()
  labelPosition: string;
  @Input()
  appendLabel: boolean;
  @Input()
  orientation = 'horizontal';
  @Input()
  labelOrientation = 'horizontal';
  @Input()
  values: RadioButtonField[];
  @Input()
  set labelName(labelName: string) {
    if (labelName) {
      this._labelName = labelName;
    }
  }
  @Input()
  flagAsRequired: boolean;

  _labelName: string = null;

  get value(): AbstractControl {
    return this.parentForm.get(this.inputName);
  }

  @Input()
  showSuccess: boolean;
}
