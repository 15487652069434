<div class="button-panel d-flex flex-wrap">
  <div *ngFor="let item of buttons">
    <div
      *ngIf="item.isVisible && isButton(item)"
      [ngbTooltip]="!$any(item).isEnabled ? tooltip : false"
      placement="bottom"
      class="d-inline-block">
      <button
        class="btn m-1"
        [class]="item.style?.length > 0 ? item.style : buttonStyle"
        [id]="item.id"
        (click)="onButtonClicked($any(item))"
        [disabled]="!$any(item).isEnabled">
        {{ item.name }}
      </button>
      <ng-template #tooltip>
        <div
          [ngClass]="{
            'button-tooltip': !item.disabledReasons,
            'disabled-reasons': item.disabledReasons,
          }">
          <span
            *ngIf="
              !$any(item).isEnabled &&
              (!$any(item).disabledReasons ||
                $any(item).disabledReasons.length === 0)
            "
            >{{ item.name }} is disabled</span
          >

          <div *ngFor="let item of item.disabledReasons">{{ item }}</div>
          <div *ngIf="item.tooltip">{{ item.tooltip }}</div>
        </div>
      </ng-template>
    </div>
    <div ngbDropdown class="d-inline-block">
      <button
        class="btn m-1"
        [class]="buttonStyle"
        [id]="item.id"
        ngbDropdownToggle
        *ngIf="item.isVisible && isDropDown(item)">
        {{ item.name }}
      </button>
      <div ngbDropdownMenu>
        <button
          ngbDropdownItem
          [disabled]="!$any(dropDownItem).isEnabled"
          *ngFor="let dropDownItem of $any(item).options"
          (click)="onButtonClicked(dropDownItem)">
          {{ dropDownItem.name }}
        </button>
      </div>
    </div>
  </div>
</div>
