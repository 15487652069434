import { Directive, ElementRef, HostListener } from '@angular/core';
import { FormDirectiveService } from '@app/shared/services/form-directive.service';

@Directive({
  selector: '[appNumbersOnly]',
  providers: [FormDirectiveService],
})
export class NumbersOnlyDirective {
  private allowedKeys = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'ArrowLeft',
    'ArrowRight',
    'Delete',
    '.',
    'Control',
    'c',
    'C',
    'v',
    'V',
    'z',
    'Z',
  ];

  private regExpString =
    '^([1-9][0-9]*([.][0-9]{1,2}|))$|^(([0]{1})?([.][0-9]{1,2}|))$';

  constructor(
    private formDirectiveService: FormDirectiveService,
    private el: ElementRef,
  ) {
    formDirectiveService.allowedKeys = this.allowedKeys;
    formDirectiveService.regexPattern = this.regExpString;
  }

  @HostListener('keydown', ['$event'])
  keyDown(event: KeyboardEvent) {
    this.formDirectiveService.checkKeysAndRegex(this.el, event);
  }
}
