import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class IconComponent {
  @Input()
  name: string;

  iconClass(): string {
    return `fem-icon fem-icon-${this.name}`;
  }
}
