import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[appNumberInputRange]',
})
export class NumberInputRangeDirective {
  @Input() max: number;
  @Input() min: number;

  @HostListener('keyup') onKeyUp() {
    const value: number = this.el.nativeElement.value;

    if (value > this.max) {
      this.renderer.setProperty(this.el.nativeElement, 'value', this.max);
    } else if (value < this.min) {
      this.renderer.setProperty(this.el.nativeElement, 'value', this.min);
    }
  }

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}
}
