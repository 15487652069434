import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayAsCurrencyDefault',
})
export class DisplayAsCurrencyDefaultPipe implements PipeTransform {
  currencyPrefix = 'R';
  readonly thousandSeparatorRegex = /\B(?=(\d{3})+(?!\d))/g;
  readonly thousandsSeparator = ' ';
  transform(value: number | string): string {
    const currency = +value;
    return `${this.currencyPrefix} ${currency
      .toFixed(2)
      .replace(this.thousandSeparatorRegex, this.thousandsSeparator)}`;
  }
}
