import { Component, Input, OnInit } from '@angular/core';
import { IconSizeEnum } from '@app/shared/models/icon-size-enum';
import { TooltipPlacementEnum } from '@app/shared/models/tooltip-placement-enum';

@Component({
  selector: 'app-copy-button',
  templateUrl: './copy-button.component.html',
  styleUrls: ['./copy-button.component.scss'],
})
export class CopyButtonComponent implements OnInit {
  @Input()
  copyItem: string;

  @Input()
  label: string;

  icon: string;

  @Input()
  iconSize = IconSizeEnum.Medium;

  @Input()
  placement = TooltipPlacementEnum.Top;

  constructor() {}

  ngOnInit(): void {
    this.initialiseIcon();
  }

  /**  Create a temporary textarea element, 'execCommand' is deprecated,
   *  this creates a temp Variable(textarea) that stores the text that needs to be copied to the clipboard*/
  copyToClipboard(event: Event) {
    event.stopPropagation();
    const textarea = document.createElement('textarea');
    textarea.value = this.copyItem;
    document.body.appendChild(textarea);
    textarea.select();

    document.execCommand('copy');
    document.body.removeChild(textarea);
    this.icon = 'approval';
  }

  getTooltip() {
    return `Copy ${this.label}`;
  }

  highlightIcon() {
    this.icon = 'copy_filled';
  }

  initialiseIcon() {
    this.icon = 'copy';
  }
}
