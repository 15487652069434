<div
  (click)="onClick($event)"
  (mouseleave)="mouseLeave.emit()"
  (mouseover)="mouseOver.emit()"
  [ngbTooltip]="tooltip"
  class="control-button-group"
  placement="{{placement}}"
>
  <a class="control-button" [class.disabled]="showLoader">
    <app-icon
      *ngIf="icon && !showLoader"
      [name]="icon"
      [ngStyle]="{ 'font-size.px': getFontSize() }"></app-icon>
    <div>
      <div class="spinner-border spinner-border-sm mb-1" role="status" *ngIf="showLoader">
      </div>
    </div>
    <span
      [ngStyle]="{'font-size.px': buttonLabelSize}">{{ label }}</span>
  </a>
</div>
