import { Injectable, OnDestroy } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { DateRangeFields } from '@app/shared/models/date-range-field';
import { Observable } from 'rxjs';
import { debounceTime, map, takeWhile } from 'rxjs/operators';

@Injectable()
export class DateRangeFormService implements OnDestroy {
  alive = true;
  public dateRangeForm: UntypedFormGroup;
  public dateRangeFields$ = new Observable<DateRangeFields>();

  dateFrom = new UntypedFormControl(null);
  dateTo = new UntypedFormControl(null);

  constructor() {
    this.dateRangeForm = new UntypedFormGroup(
      {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      },
      this.dateLessThan('dateFrom', 'dateTo'),
    );

    this.dateRangeFields$ = this.dateRangeForm.valueChanges.pipe(
      takeWhile(() => this.alive),
      debounceTime(300),
      map(
        ({ dateFrom, dateTo }) =>
          ({
            fromDate: dateFrom && this.dateRangeForm.valid ? dateFrom : '',
            toDate: dateTo && this.dateRangeForm.valid ? dateTo : '',
          }) as DateRangeFields,
      ),
    );
  }

  get value() {
    const dateRange: DateRangeFields = {
      fromDate: this.dateRangeForm.get('dateFrom').value,
      toDate: this.dateRangeForm.get('dateTo').value,
    };
    return dateRange;
  }

  dateLessThan(from: string, to: string): ValidatorFn {
    return (group: UntypedFormGroup): ValidationErrors | null => {
      const f = group.controls[from];
      const t = group.controls[to];
      if (f.value && t.value && f.value > t.value) {
        return {
          dates: 'The From date should be before the To date',
        };
      }
      return null;
    };
  }

  addFromDateValidators(validators: ValidatorFn | ValidatorFn[]) {
    this.dateFrom.addValidators(validators);
  }

  addToDateValidators(validators: ValidatorFn | ValidatorFn[]) {
    this.dateTo.addValidators(validators);
  }

  addDateRangeValidators(validators: ValidatorFn | ValidatorFn[]) {
    this.dateRangeForm.addValidators(validators);
  }

  reset() {
    this.dateRangeForm.reset();
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
