import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ScrollPosition } from './scroll-position';

export class MockWheelEvent {
  deltaY: number;
  stopPropagation: () => void;
  preventDefault: () => void;
}

export class MockElement {
  scrollHeight: number;
  clientHeight: number;
  scrollTop: number;
}

enum PagingAction {
  NextPage = 1,
  PrevPage = -1,
  Nothing = 0,
}

@Injectable()
export class ScrollHelperService {
  searchImageScrollEvent$ = new Subject<ScrollPosition>();
  selectedImageScrollEvent$ = new Subject<ScrollPosition>();

  constructor() {}

  onMouseWheel(event: WheelEvent | MockWheelEvent, ele: Element | MockElement) {
    event.stopPropagation();

    const isScrollBarHidden = ele.scrollHeight === ele.clientHeight;
    if (isScrollBarHidden) {
      event.preventDefault();
    }

    if (event.deltaY < 0 && ele.scrollTop === 0) {
      return PagingAction.PrevPage;
    } else {
      if (
        ele.clientHeight + ele.scrollTop >= ele.scrollHeight - 20 &&
        event.deltaY > 0
      ) {
        return PagingAction.NextPage;
      }
    }

    return PagingAction.Nothing;
  }

  public resetScrollPosition(ele: Element | MockElement) {
    ele.scrollTop = 0;
  }

  searchImageScrolled(scrollPosition: ScrollPosition) {
    this.selectedImageScrollEvent$.next(scrollPosition);
  }

  selectedImageScrolled(scrollPosition: ScrollPosition) {
    this.searchImageScrollEvent$.next(scrollPosition);
  }
}
