<app-modal
  #updateModal
  title="App Update Available"
  [footer]="footerTemplate"
  [showClose]="false"
>
  <p>An update for Bokamoso is available with new features and fixes!</p>
  <p>Click 'Reload' to reload the application and apply them immediately.</p>
  <p>
    To see what the new exciting updates are, navigate to the change log
    afterwards.
  </p>
</app-modal>

<ng-template #footerTemplate>
  <button class="btn btn-primary" (click)="refresh()">Reload</button>
</ng-template>
