import { Injectable } from '@angular/core';

export enum LocalStorageArea {
  AUTH,
}

export enum LocalStorageType {
  TOKEN,
}

@Injectable()
export class LocalStorageService {
  constructor() {}

  clear(): void {
    localStorage.clear();
  }

  getItem(area: LocalStorageArea, type: LocalStorageType): string {
    return localStorage.getItem(this.getKey(area, type));
  }

  removeItem(area: LocalStorageArea, type: LocalStorageType): void {
    localStorage.removeItem(this.getKey(area, type));
  }

  setItem(area: LocalStorageArea, type: LocalStorageType, value: string): void {
    localStorage.setItem(this.getKey(area, type), value);
  }

  getKey(area: LocalStorageArea, type: LocalStorageType) {
    return area + '.' + type;
  }
}
