import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isString } from 'lodash';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@env/environment';
import { ApiError, ErrorResponse } from '../models/ErrorResponse';
import { AppInsightsService } from '../services/app-insights.service';
import { Logger } from '../services/logger.service';
import { AppConfigService } from '../services/app-config/app-config.service';

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private appInsights: AppInsightsService,
    private appConfig: AppConfigService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(catchError((error) => this.errorHandler(error)));
  }

  private errorHandler(response: ErrorResponse): Observable<any> {
    // Handle special case where error is returned from endpoints that return images/reports
    if (response.error instanceof ArrayBuffer) {
      const text = new TextDecoder('utf-8').decode(response.error);
      response.error = JSON.parse(text);
    }

    if (response.error instanceof Blob) {
      const reader = new FileReader();

      const blobError = new Observable((observer) => {
        reader.onloadend = () => {
          observer.error(JSON.parse(reader.result as string));
          observer.complete();
        };
      });

      reader.readAsText(response.error);
      return blobError;
    }

    const apiError: ApiError = isString(response.error)
      ? {
          friendlyMessage: response.error,
          responseStatus: response.status,
        }
      : response.error
        ? {
            ...response.error,
            responseStatus: response.status,
          }
        : {
            friendlyMessage:
              response.status === 404 ? 'Not found' : 'Unknown Error',
            responseStatus: response.status,
          };

    if (this.appConfig.config.applicationInsights.logExceptions) {
      this.appInsights.logError(`Request Error Occurred: ${response.error}`);
    }

    if (!environment.production) {
      log.error('Request Failure', response);
    }

    throw apiError;
  }
}
