export type GenericFilterField = FilterField<any, any>;

export abstract class FilterField<TInputProperty, KOutputProperty> {
  inputName: string;
  inputLabel: string;

  constructor(inputName: string, inputLabel: string) {
    this.inputName = inputName;
    this.inputLabel = inputLabel;
  }

  abstract getDefaultValue(): TInputProperty;

  parseValue(value: any): TInputProperty {
    return value;
  }

  abstract isValid(value: any): boolean;

  setInputLabel(inputLabel: string) {
    this.inputLabel = inputLabel;
    return this;
  }

  abstract getValue(value: any): KOutputProperty;
}
