import { CommonModule } from '@angular/common';
import { LOCALE_ID, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import {
  NgbAccordionModule,
  NgbAlertModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbModule,
  NgbTooltipModule,
  NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { OverviewSectionTextDisplayComponent } from '@app/shared/overview-section/overview-section-text-display/overview-section-text-display.component';
import { OverviewSectionSearchComponent } from '@app/shared/overview-section/overview-section-search/overview-section-search.component';
import { OverviewSectionSelectComponent } from '@app/shared/overview-section/overview-section-select/overview-section-select.component';
import { OverviewSectionAdvancedSelectComponent } from '@app/shared/overview-section/overview-section-advanced-select/overview-section-advanced-select.component';
import { OverviewSectionRowComponent } from '@app/shared/overview-section/overview-section-row/overview-section-row.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { StandardTimePipe } from '@app/shared/pipes/standard-time.pipe';
import { RegionPipe } from '@app/shared/pipes/region/region-pipe';
import { LoadingAuthorisationStatusPipe } from '@app/shared/pipes/loading-authorisation/loading-authorisation-status-pipe';
import { AccordionComponent } from './accordion/accordion.component';
import { BackComponent } from './back/back.component';
import { ButtonPanelComponent } from './button-panel/button-panel.component';
import { CalloutComponent } from './callout/callout.component';
import { CircleLoaderComponent } from './circle-loader/circle-loader.component';
import { ConfirmationButtonComponent } from './confirmation-button/confirmation-button.component';
import { ContextMenuWrapperComponent } from './context-menu-wrapper/context-menu-wrapper.component';
import { DashboardTableComponent } from './dashboard-table/dashboard-table.component';
import { IDNumberDirective } from './directives/form/idnumber.directive';
import { NumberInputRangeDirective } from './directives/form/number-input-range.directive';
import { NumbersOnlyDirective } from './directives/form/numbers-only.directive';
import { PhoneNumberDirective } from './directives/form/phone-number.directive';
import { PostalCodeDirective } from './directives/form/postal-code.directive';
import { TextDisplayComponent } from './display/text-display/text-display.component';
import { FilterComponent } from './filter/filter.component';
import { AdvancedSelectInputComponent } from './form-inputs/advanced-select-input/advanced-select-input.component';
import { AlertComponent } from './form-inputs/alert/alert.component';
import { AsyncTypeaheadInputComponent } from './form-inputs/async-typeahead-input/async-typeahead-input.component';
import { CheckboxInputComponent } from './form-inputs/checkbox-input/checkbox-input.component';
import { CurrencyInputComponent } from './form-inputs/currency-input/currency-input.component';
import { DateInputComponent } from './form-inputs/date-input/date-input.component';
import { DateRangePickerComponent } from './form-inputs/date-range-picker/date-range-picker.component';
import { ErrorLabelComponent } from './form-inputs/error-label/error-label.component';
import { IdNumberInputComponent } from './form-inputs/id-number-input/id-number-input.component';
import { InfoLabelComponent } from './form-inputs/info-label/info-label.component';
import { NumberInputComponent } from './form-inputs/number-input/number-input.component';
import { PhoneNumberInputComponent } from './form-inputs/phone-number-input/phone-number-input.component';
import { PostalCodeInputComponent } from './form-inputs/postal-code-input/postal-code-input.component';
import { RadioInputComponent } from './form-inputs/radio-input/radio-input.component';
import { RichTextInputComponent } from './form-inputs/rich-text-input/rich-text-input.component';
import { SelectInputComponent } from './form-inputs/select-input/select-input.component';
import { TextInputComponent } from './form-inputs/text-input/text-input.component';
import { TextareaInputComponent } from './form-inputs/textarea-input/textarea-input.component';
import { TypeaheadInputComponent } from './form-inputs/typeahead-input/typeahead-input.component';
import { TypeaheadLabelInputComponent } from './form-inputs/typeahead-label-input/typeahead-label-input.component';
import { CardComponent } from './card/card.component';
import { IconComponent } from './icon/icon.component';
import { ModalComponent } from './modal/modal.component';
import { OpenStateIndicatorComponent } from './open-state-indicator/open-state-indicator.component';
import { ConvertToYesNoPipe } from './pipes/convert-to-yes-no.pipe';
import { CurrencyWithCommaPipe } from './pipes/currency/currency-with-comma.pipe';
import { DisplayAsCurrencyPipe } from './pipes/currency/display-as-currency.pipe';
import { DisplayAsCurrencyDefaultPipe } from './pipes/currency/display-as-currency-default.pipe';
import { AddressPipe } from './pipes/employer-contact-detail/address.pipe';
import { RelativeDatePipe } from './pipes/relative-date-pipe';
import { StandardDateTimePipe } from './pipes/standard-date-time.pipe';
import { StandardDatePipe } from './pipes/standard-date.pipe';
import { TextEllipsisPipe } from './pipes/text-ellipsis/text-ellipsis.pipe';
import { NullableFieldPipe } from './pipes/nullable-field.pipe';
import { SearchListComponent } from './search/components/search-list/search-list.component';
import { TableComponent } from './table/table.component';
import { OverviewSectionComponent } from './overview-section/overview-section/overview-section.component';
import { NoOpPipe } from './pipes/noop/no-op.pipe';
import { GenericFieldDisplayComponent } from './generic-data/components/authorisation-field-display/generic-field-display.component';
import { FancyTableComponent } from './generic-data/components/fancy-table/fancy-table.component';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { ScrollHelperService } from './helpers/scroll-helper/scroll-helper.service';
import { ActionDropdownComponent } from './action-dropdown/action-dropdown.component';
import { StandardDayOfTheWeekPipe } from './pipes/standard-day-of-the-week.pipe';
import { ClaimCodePipe } from './pipes/claim-code.pipe';
import { BooleanPendingPipe } from './pipes/boolean-pending.pipe';
import { RadioTextInputComponent } from './form-inputs/radio-text-input/radio-text-input.component';
import { GlPeriodInputComponent } from './form-inputs/gl-period-input/gl-period-input.component';
import { DisplayItemDetailPricePipe } from './pipes/procurements/display-item-detail-price.pipe';
import { TableCurrencyInputComponent } from './form-inputs/table-currency-input/table-currency-input.component';
import { PascalCaseStringSplit } from './pipes/pascal-case-string-split.pipe';
import { CopyButtonComponent } from './copy-button/copy-button.component';
import { DragDirective } from './directives/drag.directive';
import { ResizeDirective } from './directives/resize.directive';
import { MultiItemListInputComponent } from './form-inputs/multi-item-list-input/multi-item-list-input.component';
import { PercentagePipe } from './pipes/percentage-pipe.pipe';
import { MultiItemDisplayComponent } from './display/multi-item-display/multi-item-display.component';
import { ReorderingListDisplayComponent } from './reordering-list-display/reordering-list-display.component';
import { TimeInputComponent } from './form-inputs/time-input/time-input.component';
import { ExportExcelModalComponent } from './dashboard-table/export-excel-modal/export-excel-modal.component';
import { InternationalPhoneNumberInputComponent } from './form-inputs/international-phone-number-input/international-phone-number-input.component';
import { CustomTooltipComponent } from './custom-tooltip/custom-tooltip.component';

@NgModule({
  declarations: [
    IconComponent,
    TextInputComponent,
    NumberInputComponent,
    DateInputComponent,
    TypeaheadInputComponent,
    SelectInputComponent,
    CheckboxInputComponent,
    ErrorLabelComponent,
    InfoLabelComponent,
    ButtonPanelComponent,
    AlertComponent,
    AccordionComponent,
    ContextMenuWrapperComponent,
    TextareaInputComponent,
    ConfirmationButtonComponent,
    CircleLoaderComponent,
    ModalComponent,
    OpenStateIndicatorComponent,
    SearchListComponent,
    RelativeDatePipe,
    FilterComponent,
    TableComponent,
    NumberInputRangeDirective,
    BackComponent,
    DashboardTableComponent,
    StandardDatePipe,
    StandardDateTimePipe,
    ClaimCodePipe,
    StandardDayOfTheWeekPipe,
    AsyncTypeaheadInputComponent,
    AdvancedSelectInputComponent,
    CurrencyInputComponent,
    CurrencyWithCommaPipe,
    ConvertToYesNoPipe,
    NumbersOnlyDirective,
    DisplayAsCurrencyPipe,
    DisplayAsCurrencyDefaultPipe,
    TextDisplayComponent,
    AddressPipe,
    PhoneNumberInputComponent,
    PhoneNumberDirective,
    PostalCodeInputComponent,
    PostalCodeDirective,
    IdNumberInputComponent,
    IDNumberDirective,
    RadioInputComponent,
    DateRangePickerComponent,
    CalloutComponent,
    RichTextInputComponent,
    TextEllipsisPipe,
    TypeaheadLabelInputComponent,
    CardComponent,
    OverviewSectionComponent,
    OverviewSectionTextDisplayComponent,
    OverviewSectionSearchComponent,
    OverviewSectionSelectComponent,
    OverviewSectionAdvancedSelectComponent,
    OverviewSectionRowComponent,
    NoOpPipe,
    GenericFieldDisplayComponent,
    FancyTableComponent,
    IconButtonComponent,
    NullableFieldPipe,
    IconButtonComponent,
    ActionDropdownComponent,
    BooleanPendingPipe,
    RadioTextInputComponent,
    GlPeriodInputComponent,
    DisplayItemDetailPricePipe,
    TableCurrencyInputComponent,
    PascalCaseStringSplit,
    CopyButtonComponent,
    DragDirective,
    ResizeDirective,
    MultiItemListInputComponent,
    PercentagePipe,
    MultiItemDisplayComponent,
    ReorderingListDisplayComponent,
    TimeInputComponent,
    ExportExcelModalComponent,
    InternationalPhoneNumberInputComponent,
    RegionPipe,
    LoadingAuthorisationStatusPipe,
    CustomTooltipComponent,
  ],
  imports: [
    CommonModule,
    NgbDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    NgbTypeaheadModule,
    NgbAlertModule,
    NgbDropdownModule,
    NgbAlertModule,
    NgbAccordionModule,
    NgbModule,
    NgbTooltipModule,
    ContextMenuModule,
    NgxDatatableModule,
    PdfViewerModule,
    CKEditorModule,
    DragDropModule,
  ],
  exports: [
    IconComponent,
    IconButtonComponent,
    TextInputComponent,
    NumberInputComponent,
    DateInputComponent,
    TypeaheadInputComponent,
    SelectInputComponent,
    CheckboxInputComponent,
    TypeaheadLabelInputComponent,
    InfoLabelComponent,
    ButtonPanelComponent,
    AlertComponent,
    AccordionComponent,
    ContextMenuWrapperComponent,
    TextareaInputComponent,
    ConfirmationButtonComponent,
    CircleLoaderComponent,
    ModalComponent,
    StandardDatePipe,
    OpenStateIndicatorComponent,
    SearchListComponent,
    RelativeDatePipe,
    FilterComponent,
    TableComponent,
    NumberInputRangeDirective,
    BackComponent,
    DashboardTableComponent,
    StandardDateTimePipe,
    StandardDayOfTheWeekPipe,
    ClaimCodePipe,
    AsyncTypeaheadInputComponent,
    AdvancedSelectInputComponent,
    CurrencyInputComponent,
    CurrencyWithCommaPipe,
    ConvertToYesNoPipe,
    NumbersOnlyDirective,
    DisplayAsCurrencyPipe,
    DisplayAsCurrencyDefaultPipe,
    TextDisplayComponent,
    AddressPipe,
    PhoneNumberInputComponent,
    PostalCodeInputComponent,
    IdNumberInputComponent,
    RadioInputComponent,
    DateRangePickerComponent,
    ErrorLabelComponent,
    CalloutComponent,
    RichTextInputComponent,
    TextEllipsisPipe,
    CardComponent,
    OverviewSectionComponent,
    OverviewSectionTextDisplayComponent,
    OverviewSectionSearchComponent,
    OverviewSectionSelectComponent,
    OverviewSectionAdvancedSelectComponent,
    OverviewSectionRowComponent,
    GenericFieldDisplayComponent,
    NoOpPipe,
    IconButtonComponent,
    NullableFieldPipe,
    ActionDropdownComponent,
    RadioTextInputComponent,
    DisplayItemDetailPricePipe,
    TableCurrencyInputComponent,
    PascalCaseStringSplit,
    GlPeriodInputComponent,
    CopyButtonComponent,
    DragDirective,
    ResizeDirective,
    MultiItemListInputComponent,
    PercentagePipe,
    MultiItemDisplayComponent,
    ReorderingListDisplayComponent,
    TimeInputComponent,
    InternationalPhoneNumberInputComponent,
    RegionPipe,
    LoadingAuthorisationStatusPipe,
    CustomTooltipComponent,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en-ZA' },
    StandardDatePipe,
    StandardDateTimePipe,
    StandardDayOfTheWeekPipe,
    DisplayAsCurrencyPipe,
    BooleanPendingPipe,
    DisplayItemDetailPricePipe,
    PascalCaseStringSplit,
    StandardTimePipe,
    RegionPipe,
    ConvertToYesNoPipe,
    LoadingAuthorisationStatusPipe,
    PercentagePipe,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [AddressPipe, ScrollHelperService],
    };
  }
}
