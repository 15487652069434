<span>
  <span *ngIf="showInput">
    <label (click)="onClose()" class="icon-button close-button">&times;</label>
    <app-async-typeahead-input
      class="typeahead-input"
      [parentForm]="parentForm"
      [focus]="this.showInput"
      [inputName]="inputName"
      [labelName]="labelName"
      [data$]="data$"
      [editable]="true"
      [inputProperty]="inputProperty"
      [inputDescription]="inputDescription"
      [placeholder]="placeholder"
      [iconButton]="iconButton"
      [appendButton]="appendButton"
      [canCopy]="canCopy"
      [iconSize]="iconSize"
      [copyLabel]="copyLabel"
      (filterChanged)="onFilterChanged($event)"
      (itemSelected)="onFilterSelected($event)"
    ></app-async-typeahead-input>
  </span>

  <span *ngIf="!showInput" class="icon-button">
    <app-icon (click)="onLabelClicked($event)" name="search"></app-icon>
    <label class="ms-2">
      {{ label }}
    </label>
        <app-copy-button
          *ngIf="canCopy"
          [copyItem]="getCopyItem()"
          [label]="copyLabel"
        >
    </app-copy-button>
  </span>
</span>
