<div
  [ngbTooltip]="tooltipTemplate"
  class="control-button-group"
  placement="{{ placement }}">
  <i [class]="iconClass()"></i>
</div>

<ng-template #tooltipTemplate>
  <div [innerHTML]="tooltipText" class="text-{{ textAlign }}"></div>
</ng-template>
