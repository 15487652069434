import { Component, Input } from '@angular/core';

export enum CalloutType {
  Default = 'default',
  Primary = 'primary',
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  Info = 'info',
  Bdc = 'bdc',
}

@Component({
  selector: 'app-callout',
  templateUrl: './callout.component.html',
  styleUrls: ['./callout.component.scss'],
})
export class CalloutComponent {
  @Input()
  type = CalloutType.Default;

  get colourClass(): string {
    return `col-12 callout callout-${this.type}`;
  }
}
