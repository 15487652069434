import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ControlContainer,
  UntypedFormGroup,
  FormGroupDirective,
} from '@angular/forms';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class NumberInputComponent {
  @Input()
  parentForm: UntypedFormGroup;

  @Input()
  inputName: string;

  @Input()
  labelName: string;

  @Input()
  appendButton: boolean;

  @Input()
  hideLabel: boolean;

  @Input()
  iconButton: string;

  @Input()
  max: number;

  @Input()
  min = 0;

  @Input()
  flagAsRequired: boolean;

  @Input()
  prependDescription: boolean;

  @Input()
  descriptionPosition: string;

  @Input()
  description: string;

  @Input()
  showSuccess = false;

  @Output()
  iconButtonClick = new EventEmitter<any>();

  @Output()
  inputFocused = new EventEmitter<string>();

  @Output()
  inputBlurred = new EventEmitter<string>();

  get value() {
    return this.controlInstance.value;
  }

  set value(value: number) {
    this.controlInstance.setValue(value);
  }

  get controlInstance() {
    return this.parentForm.get(this.inputName);
  }

  onIconButtonClick() {
    this.iconButtonClick.emit();
  }

  onFocus() {
    this.inputFocused.emit(this.inputName);
  }

  onBlur() {
    if (this.max && this.value > this.max) {
      this.value = this.max;
    }
    if (this.value < this.min) {
      this.value = this.min;
    }
    this.inputBlurred.emit(this.inputName);
  }
}
