import { FilterParameterBuilder } from '../search/builders/filter-parameter.builder';
import { SearchRequestBuilder } from '../search/builders/search-request.builder';
import { FilterParameterOperator } from '../search/interfaces/filter-parameter';
import { SearchOperand } from '../search/interfaces/search-request';

export interface Field {
  label: string;
  name: string;
  position: Position;
  width: Width;
  value: any;
  type: RequestFieldType;
  styling: string[];
}

export interface Table {
  columns: Column[];
  rows: Row[];
}

export interface Column {
  name: string;
  type: RequestFieldType;
}

export interface Row {
  fields: Field[];
  styling: string[];
}

export enum RequestFieldType {
  Text = 0,
  Currency = 1,
  Date = 2,
  Percentage = 3,
  Table = 4,
  FancyTable = 5,
  TextArray = 6,
  Actions = 7,
  LongText = 8,
  Tooltip = 9,
}

export enum Position {
  Top = 1,
  Bottom = 2,
}

export enum Width {
  Half = 0,
  Full = 1,
  Third = 2,
}

export interface QueryableField {
  label: string;
  property: string;
  properties?: string[];
  values: QueryableFieldValue[];
  reliesOn: string;
  filterBy: string;
  isDescendingSort: boolean;
}

export interface QueryableFieldValue {
  name: string;
  value: string | string[];
  isMultiValue: boolean;
  filterOperand: SearchOperand;
  filterOperator: FilterParameterOperator;
  referenceObject: Record<string, any>;
  isDefault: boolean;
}

export const queryFieldValueToSearchRequest = (
  property: string,
  value: QueryableFieldValue,
  properties?: string[],
) => {
  const searchRequest = new SearchRequestBuilder().setOperand(
    value.filterOperand ? value.filterOperand : SearchOperand.AND,
  );

  if (value.isMultiValue) {
    // Value will be a string[] here
    for (const filterValue of value.value as string[]) {
      searchRequest.addFilterParameter(
        new FilterParameterBuilder()
          .setNonGenericColumnName(property)
          .setFilterOperator(value.filterOperator)
          .setValue(filterValue)
          .build(),
      );
    }
  } else {
    const isDefaultOption = value.value == null;
    if (isDefaultOption) {
      return null;
    }

    if (properties?.length > 0) {
      properties.forEach((prop) => {
        searchRequest.addFilterParameter(
          new FilterParameterBuilder()
            .setNonGenericColumnName(prop)
            .setFilterOperator(value.filterOperator)
            .setValue(value.value as string)
            .build(),
        );
      });
    } else {
      searchRequest.addFilterParameter(
        new FilterParameterBuilder()
          .setNonGenericColumnName(property)
          .setFilterOperator(value.filterOperator)
          .setValue(value.value as string)
          .build(),
      );
    }
  }

  return searchRequest.build();
};

export interface WidthMap {
  [K: number]: number;
}

export const bootstrapWidthMap: WidthMap = {
  [Width.Half]: 6,
  [Width.Full]: 12,
  [Width.Third]: 4,
};
