import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { ExcelTableDefinition } from '@app/shared/models/excel-table-definition';
import { HttpService } from '@app/core';

@Injectable({
  providedIn: 'root',
})
export class SpreadsheetDownloadService {
  maxSheetLength = 31;

  constructor(private http: HttpService) {}

  convertToExcel(excelTableDefinition: ExcelTableDefinition) {
    let rows: any[] = [];

    const headers = excelTableDefinition.headers;
    const headerNames = excelTableDefinition.headerNames;
    const data = excelTableDefinition.data;
    const tableName = (
      excelTableDefinition.tableName
        ? excelTableDefinition.tableName
        : 'Spreadsheet'
    ).replace(/\s/g, '');

    const columnPadding = 5;
    if (data) {
      rows = data.map((row) => {
        // FIXME: This is a Typescript Type bailout, and indicates that we're accessing the
        // properties of this object in a dynamic way, going against type safety.
        const trimmedRow: { [key: string]: any } = {};
        Object.entries(row).forEach((property) => {
          if (headers.includes(property[0])) {
            const propertyKey: string = property[0] as string;
            trimmedRow[propertyKey] = property[1];
          }
        });
        headers.forEach((prop) => {
          if (!Object.keys(trimmedRow).includes(prop)) {
            trimmedRow[prop] = '';
          }
        });
        return trimmedRow;
      });
    }

    const objectMaxLength: number[] = [];
    rows.forEach((value) => {
      for (let j = 0; j < headers.length; j++) {
        if (typeof value[headers[j]] == 'number') {
          objectMaxLength[j] = 10;
        } else {
          const l = value[headers[j]] ? value[headers[j]].length : 0;

          objectMaxLength[j] = objectMaxLength[j] >= l ? objectMaxLength[j] : l;
        }
      }

      const key = headerNames;
      for (let j = 0; j < key.length; j++) {
        objectMaxLength[j] =
          objectMaxLength[j] >= key[j].length
            ? objectMaxLength[j]
            : key[j].length;
      }
    });

    const columnWidths = objectMaxLength.map((w) => ({
      width: w + columnPadding,
    }));

    const worksheet = XLSX.utils.json_to_sheet(rows, { header: headers });
    const workbook = XLSX.utils.book_new();
    worksheet['!cols'] = columnWidths;

    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      `${tableName.substring(0, this.maxSheetLength)}`,
    );

    XLSX.utils.sheet_add_aoa(worksheet, [headerNames], { origin: 'A1' });

    XLSX.writeFile(workbook, `${tableName}.xlsx`);

    this.logDomainEvent(excelTableDefinition).pipe().subscribe();
  }

  logDomainEvent(excelTableDefinition: ExcelTableDefinition) {
    return this.http.post(`users/user`, {
      domain: excelTableDefinition.domainName,
      resource: excelTableDefinition.resourceName,
    });
  }
}
