import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { applySpec } from 'ramda';

export const getInputDate = applySpec({
  year: (date) => date.getFullYear(),
  month: (date) => date.getMonth() + 1,
  day: (date) => date.getDate(),
});

export const formatInputDate = (date: NgbDateStruct): string => {
  const formattedDate = new Date(0);
  if (date.year) {
    formattedDate.setFullYear(date.year);
  }
  if (date.month) {
    formattedDate.setMonth(date.month - 1);
  }
  if (date.day) {
    formattedDate.setDate(date.day);
  }
  formattedDate.setHours(0, 0, 0);
  return moment(formattedDate).format('YYYY-MM-DD');
};

export const formatInputDateForModel = (date: NgbDateStruct): string => {
  const formattedDate = new Date(0);
  if (date.year) {
    formattedDate.setUTCFullYear(date.year);
  }
  if (date.month) {
    formattedDate.setUTCMonth(date.month - 1);
  }
  if (date.day) {
    formattedDate.setUTCDate(date.day);
  }
  formattedDate.setUTCHours(0, 0, 0);
  return formattedDate.toISOString();
};

export const formatInputDateForForm = (inputDate: Date | string): string => {
  const date = new Date(inputDate);
  const formattedDate = new Date(0);
  if (date.getFullYear()) {
    formattedDate.setUTCFullYear(date.getFullYear());
  }
  if (date.getMonth()) {
    formattedDate.setUTCMonth(date.getMonth());
  }
  if (date.getDate()) {
    formattedDate.setUTCDate(date.getDate());
  }
  formattedDate.setUTCHours(0, 0, 0);
  return formattedDate.toISOString();
};

export const formatAsMidnightUtc = (inputDate: Date | string): string => {
  const date = new Date(inputDate);
  const formattedDate = new Date(0);
  if (date.getFullYear()) {
    formattedDate.setFullYear(date.getFullYear());
  }
  if (date.getMonth()) {
    formattedDate.setMonth(date.getMonth());
  }
  if (date.getDate()) {
    formattedDate.setDate(date.getDate());
  }
  formattedDate.setHours(0, 0, 0);
  return formattedDate.toISOString();
};

export const formatStandardDate = (date: Date): string =>
  moment(date).format('YYYY-MM-DD');

export const formatYearAndMonthDate = (date: Date): string =>
  moment(date).format('YYYYMM');

export const formatDateRangeAsMidnightUtcDate = (
  fromDate: Date | string,
  toDate: Date | string,
): string[] => {
  const fromDateInput = new Date(fromDate);
  fromDateInput.setHours(0, 0, 0);
  const toDateInput = new Date(toDate);
  toDateInput.setHours(23, 59, 0, 0);
  return [fromDateInput.toISOString(), toDateInput.toISOString()];
};

export const formatAsEndOfDayUtc = (inputDate: Date | string): string => {
  const date = new Date(inputDate);
  const formattedDate = new Date(0);
  if (date.getFullYear()) {
    formattedDate.setFullYear(date.getFullYear());
  }
  if (date.getMonth()) {
    formattedDate.setMonth(date.getMonth());
  }
  if (date.getDate()) {
    formattedDate.setDate(date.getDate());
  }
  formattedDate.setHours(23, 59, 0, 0);
  return formattedDate.toISOString();
};
