import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DateRangeFields } from '@app/shared/models/date-range-field';
import { distinctUntilChanged, takeWhile } from 'rxjs/operators';
import { DateRangeFormService } from './service/date-range-form.service';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  providers: [DateRangeFormService],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class DateRangePickerComponent implements OnInit, OnDestroy {
  dateRangeForm: UntypedFormGroup;
  alive = true;
  @Input()
  set dateFrom(dateFrom: Date) {
    this.dateRangeForm.get('dateFrom').setValue(dateFrom);
  }

  get dateFrom(): Date {
    return this.dateRangeForm.get('dateFrom').value;
  }

  @Input()
  set dateTo(dateTo: Date) {
    this.dateRangeForm.get('dateTo').setValue(dateTo);
  }

  get dateTo(): Date {
    return this.dateRangeForm.get('dateTo').value;
  }

  @Input()
  labelName: string;

  @Input()
  parentForm: UntypedFormGroup;

  @Input()
  inputName: string;

  @Input()
  minYear = new Date().getFullYear() - 80;

  @Input()
  maxYear = new Date().getFullYear() + 30;

  @Input()
  required = false;

  @Input()
  customValidators: ValidatorFn | ValidatorFn[];

  @Input()
  hideLabel = false;

  @Input()
  showResetButton = false;

  @Output()
  dateChanged = new EventEmitter<DateRangeFields>();

  @Output()
  resetClicked = new EventEmitter();

  constructor(private dateRangeFormService: DateRangeFormService) {
    this.dateRangeForm = this.dateRangeFormService.dateRangeForm;
    this.dateRangeFormService.dateRangeFields$
      .pipe(
        takeWhile(() => this.alive),
        distinctUntilChanged(),
      )
      .subscribe((value) => this.dateChanged.emit(value));
  }

  ngOnDestroy() {
    this.alive = false;
  }

  ngOnInit() {
    if (this.required) {
      this.dateRangeFormService.addToDateValidators(Validators.required);
      this.dateRangeFormService.addFromDateValidators(Validators.required);
    }
    if (this.customValidators) {
      this.dateRangeFormService.addDateRangeValidators(this.customValidators);
    }
    if (!!this.parentForm && !!this.inputName) {
      this.parentForm.addControl(this.inputName, this.dateRangeForm);
    }
  }

  reset() {
    this.dateRangeFormService.reset();
    this.resetClicked.emit();
  }
}
