import {
  Component,
  Input,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { GlPeriod } from './gl-period';

@Component({
  selector: 'app-gl-period-input',
  templateUrl: './gl-period-input.component.html',
  styleUrls: ['./gl-period-input.component.scss'],
  providers: [],
})
export class GlPeriodInputComponent implements OnInit, ControlValueAccessor {
  glPeriod: GlPeriod;
  displayValue: string;

  isYearModeActive = false;
  buttonIndex = 0;

  months: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];
  mask = 'yyyymm';

  @ViewChild(NgbDropdown)
  dropDownCalender: NgbDropdown;

  @Input()
  labelName: string;

  @Input()
  hideLabel = false;

  @Input()
  flagAsRequired = false;

  @Input()
  iconButton = 'calender';

  @Input() disabled = false;

  constructor(
    @Self()
    @Optional()
    public ngControl: NgControl,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {}

  handleUserInput(value: string) {
    const data = this.parsePeriod(value);
    this.onChange(data);
  }

  parsePeriod(value: string) {
    const items = value;

    if (items.length === 6) {
      const rawYear = items.substring(0, 4);
      const rawMonth = items.substring(4, 6);

      let month = this.months.indexOf(rawMonth);
      if (month < 0) {
        month = parseInt(rawMonth, 10);
      } else {
        month = month + 1;
      }

      let year = parseInt(rawYear, 10);

      if (year < 100) {
        year = year + 2000;
      }

      return {
        year,
        month,
      };
    }
    return null;
  }

  selectYearMonth(event: any, index: number) {
    if (this.isYearModeActive) {
      event.stopPropagation();
      this.glPeriod.year = index + this.buttonIndex;
      this.isYearModeActive = false;
      this.buttonIndex = this.getIndexYearValue(this.glPeriod.year);
    } else {
      this.glPeriod.month = index + 1;
      this.dropDownCalender.close();
    }
    this.onChange(this.glPeriod);

    this.updateDisplayValue();
  }

  showYear(event: any, show: boolean) {
    event.stopPropagation();
    this.isYearModeActive = !this.isYearModeActive;
  }

  addYear(event: any, buttonIndex: number) {
    event.stopPropagation();
    const year = this.isYearModeActive
      ? this.glPeriod.year + 10 * buttonIndex
      : this.glPeriod.year + buttonIndex;

    this.glPeriod.year = year;
    this.buttonIndex = this.getIndexYearValue(year);
    this.updateDisplayValue();
  }

  updateDisplayValue() {
    this.displayValue = this.formatData(this.glPeriod);
  }

  getIndexYearValue(year: number): number {
    return year - (year % 10) - 1;
  }

  formatData(data: GlPeriod): string {
    const parsedMonth = data.month.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    return data.year + '' + parsedMonth;
  }

  writeValue(data: GlPeriod): void {
    this.glPeriod = data;
    this.buttonIndex = this.getIndexYearValue(this.glPeriod.year);
    this.updateDisplayValue();
  }

  private onChange = (data: GlPeriod) => {};
  private onTouched = () => {};

  registerOnChange(fn: (data: GlPeriod) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
