import { Injectable, TemplateRef } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FormErrors } from '@app/core/models/form';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toasts: any[] = [];

  constructor() {}

  private show(
    textOrTpl: string | TemplateRef<any>,
    options: any = {},
    formErrors?: string[] | TemplateRef<any>,
  ): Observable<any[]> {
    this.clear();
    this.toasts.push({
      textOrTpl,
      ...options,
      formErrors,
    });

    return of(this.toasts);
  }

  showSuccess(textOrTpl: string | TemplateRef<any>) {
    this.show(textOrTpl, { className: 'bg-success text-light' });
  }

  showError(
    textOrTpl: string | TemplateRef<any>,
    formErrors?: FormErrors<any>,
  ) {
    let formattedErrors: string[] = [];
    if (formErrors) {
      formattedErrors = this.formatErrors(formErrors);
    }
    this.show(
      textOrTpl,
      { className: 'bg-danger text-light' },
      formattedErrors,
    );
  }

  showInfo(textOrTpl: string | TemplateRef<any>) {
    this.show(textOrTpl);
  }

  showWarning(textOrTpl: string | TemplateRef<any>) {
    this.show(textOrTpl, { className: 'bg-warning text-light' });
  }

  clear(): Observable<any[]> {
    if (this.toasts.length > 0) {
      this.toasts = [];
    }
    return of(this.toasts);
  }

  remove(toast: any): Observable<any[]> {
    this.toasts = this.toasts.filter((t) => t !== toast);
    return of(this.toasts);
  }

  formatErrors(formErrors: FormErrors<any>) {
    const errors = [];
    for (const [key, value] of Object.entries(formErrors)) {
      errors.push(value);
    }
    return errors;
  }
}
