import { Action, createReducer, on } from '@ngrx/store';

import { AuthenticationState, initialState } from './authentication.state';
import * as AuthenticationActions from './authentication.actions';

const authReducer = createReducer<AuthenticationState, Action>(
  initialState,
  on(
    AuthenticationActions.initializeAuthentication,
    AuthenticationActions.logout,
    (state) => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(AuthenticationActions.loginSuccess, (state, { payload }) => ({
    ...state,
    authentication: payload,
    isAuthenticated: false,
    isLoading: false,
  })),
  on(AuthenticationActions.loadCurrentUserDetails, (state) => ({
    ...state,
    isAuthenticated: false,
    isLoading: true,
  })),
  on(
    AuthenticationActions.loadCurrentUserDetailsSuccess,
    (state, { user }) => ({
      ...state,
      isLoading: false,
      isAuthenticated: true,
      userDetails: user,
    }),
  ),
  on(
    AuthenticationActions.loginError,
    AuthenticationActions.loadCurrentUserDetailsFailure,
    (state, { payload }) => ({
      ...state,
      error: payload,
      isLoading: false,
      isAuthenticated: false,
    }),
  ),
);

export const reducer = (state: AuthenticationState, action: Action) =>
  authReducer(state, action);
