<button
  (click)="buttonClicked(confirmationModal)"
  class="btn btn-primary pull-right me-1"
  [disabled]="isDisabled"
>
  <app-icon [name]="buttonIcon"></app-icon>
  {{ buttonText }}
</button>

<ng-template #confirmationModal let-modal>
  <div class="modal-body">
    <ng-content></ng-content>
  </div>

  <div class="mx-auto mb-4">
    <button
      type="button"
      class="btn btn-primary me-2"
      (click)="modal.close(true)"
    >
      Confirm
    </button>
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close(false)"
    >
      Cancel
    </button>
  </div>
</ng-template>
