import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nullableField',
})
export class NullableFieldPipe implements PipeTransform {
  public transform(value: any): any {
    const nullFieldText = '-';
    return value ? value : nullFieldText;
  }
}
