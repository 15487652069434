import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationFacade } from '@app/authentication/state/authentication.facade';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  public isLoading = false;
  @Input() message: string | undefined;

  constructor(private authFacade: AuthenticationFacade) {}

  ngOnInit() {
    this.authFacade.isLoading$.subscribe(
      (statusChange) => (this.isLoading = statusChange),
    );
  }
}
