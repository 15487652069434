import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent {
  @Input()
  panelId: string;

  @Input()
  headerTitle: string;

  @Input()
  open: boolean = false;
}
