<ng-container *ngIf="field" [ngSwitch]="field?.type">
  <app-text-display
    *ngSwitchCase="requestFieldType.Percentage"
    [labelName]="field.label"
    [value]="field.value + '%'"
  ></app-text-display>

  <div *ngSwitchCase="requestFieldType.Date">
    <label class="input-label" *ngIf="displayLabel">{{ field.label }}</label>
    <div>
      {{ field.value | standardDate }}
    </div>
  </div>

  <div *ngSwitchCase="requestFieldType.Currency">
    <label class="input-label" *ngIf="displayLabel">{{ field.label }}</label>
    <div>
      {{ field.value | displayAsCurrency }}
    </div>
  </div>

  <div *ngSwitchCase="requestFieldType.TextArray">
    <label class="input-label" *ngIf="displayLabel">{{ field.label }}</label>
    <div
      *ngFor="let item of field.value"
      [class.highlight-field]="hasStyling(field, fancyFieldStyles.Highlight)"
    >
      <app-text-display
        [value]="item"
        [hideLabel]="true"
        [applyFormStyling]="false"
      ></app-text-display>
    </div>
  </div>

  <div *ngSwitchCase="requestFieldType.Table">
    <label class="input-label" *ngIf="displayLabel">{{ field.label }}</label>
    <app-table
      [rowHeight]="50"
      [headerHeight]="35"
      [rows]="rows"
      [columns]="columns"
      [shrinkHeight]="true"
      [stretchColumns]="true"
      [totalPages]="1"
      [totalRows]="rowCount"
      [horizontalScrollbar]="true"
    >
    </app-table>
  </div>

  <ng-container *ngSwitchCase="requestFieldType.FancyTable">
    <app-fancy-table [field]="field"></app-fancy-table>
  </ng-container>

  <ng-container *ngSwitchCase="requestFieldType.LongText">
    <app-text-display
        [labelName]="field.label"
        [value]="field.value"
        [truncateText]="false"
    ></app-text-display>
  </ng-container>

  <app-text-display
    *ngSwitchDefault
    [labelName]="field.label"
    [value]="field.value"
    [applyFormStyling]="false"
  ></app-text-display>
</ng-container>
