import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-button',
  templateUrl: './confirmation-button.component.html',
  styleUrls: ['./confirmation-button.component.scss'],
})
export class ConfirmationButtonComponent {
  @Input()
  buttonIcon: string;
  @Input()
  buttonText: string;
  @Input()
  isDisabled = false;

  @Output()
  actionConfirmed = new EventEmitter();

  constructor(private modalService: NgbModal) {}

  close() {}

  async buttonClicked(modal: any) {
    const shouldContinue = await this.modalService.open(modal, {
      size: 'lg',
    }).result;
    if (shouldContinue) {
      this.actionConfirmed.emit();
    }
  }
}
