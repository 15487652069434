import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class QueryParameterSerializer {
  constructor() {}

  getKey(path: string, key: any) {
    return '' + path + (path.length > 0 ? '.' : '') + key;
  }

  flatten(object: any, target: any = {}, path?: string) {
    path = path || '';

    for (const [key, value] of Object.entries(object)) {
      if (value && typeof value === 'object') {
        if (value instanceof Date) {
          target[this.getKey(path, key)] = value.toISOString();
        } else {
          target = {
            ...target,
            ...this.flatten(value, target, this.getKey(path, key)),
          };
        }
      } else {
        target[this.getKey(path, key)] = value;
      }
    }

    return target;
  }

  getHttpParams(object: any): HttpParams {
    const parameters = this.flatten(object);

    let httpParams = new HttpParams();

    for (const [key, value] of Object.entries(parameters)) {
      httpParams = httpParams.append(key, value as any);
    }

    return httpParams;
  }
}
