export const formatClaimCode = (claimCode: string): string =>
  claimCode.replace(/[/]/g, '');

const emptyString = '';

export const claimCodeDisplayFormatter = (claimCode: string): string => {
  if (!claimCode) {
    return emptyString;
  }

  if (claimCode.split('').includes('/')) {
    return claimCode;
  }

  const regionStartingIndex = 0;
  const yearStartingIndex = 3;
  const regionLength = 3;
  const yearLength = 4;
  const claimNumberStartingIndex = yearStartingIndex + yearLength;
  const region = claimCode.substr(regionStartingIndex, regionLength);
  const year = claimCode.substr(yearStartingIndex, yearLength);
  const claimNumber = claimCode.substr(
    claimNumberStartingIndex,
    claimCode.length - claimNumberStartingIndex,
  );
  return `${region}/${year}/${claimNumber}`;
};
const claimCodeRegex = new RegExp(/([A-Z]{1})([0-9]{2})\/[0-9]{4}\/[0-9]{6}/);

export const isValidClaimCode = (testCode: string) =>
  claimCodeRegex.test(testCode);
