<div [class.form-group]="!hideLabel">
  <label *ngIf="!hideLabel" [for]="inputName" class="input-label"
    >{{ labelName }} <span *ngIf="flagAsRequired">*</span>
  </label>
  <div class="input-group mb-3">
    <input
      appIDNumber
      type="text"
      [disabled]="!editable"
      [formControlName]="inputName"
      class="form-control"
      (focus)="onFocus()"
      (blur)="onBlur()"
    />
    <div
      *ngIf="appendButton"
      class="input-group-append"
      (click)="onIconButtonClick()"
    >
      <span class="btn-icon" [class.inactive-icon]="!editable">
        <app-icon class="icon" [name]="iconButton"></app-icon>
      </span>
    </div>
  </div>
  <app-error-label
    *ngIf="value.invalid && value.dirty"
    [message]="'Not a valid South African ID number'"
  ></app-error-label>
  <app-info-label *ngIf="description" [message]="description"></app-info-label>
</div>
